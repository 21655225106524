import * as amplitude from "@amplitude/analytics-browser";
import ExploreNav from "components/ExploreNav";
import GoodsList from "components/GoodsList";
import { useEffect, useState } from "react";
import { useUser } from "../UserContext";

import Categories from "components/Categories";
import { getUserExplore } from "../services/api";

const Explore = ({ type }) => {
  const { user } = useUser();
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [removeMode, setRemoveMode] = useState(false);

  useEffect(() => {
    amplitude.track(`${type}_opened`);
  }, [type]);

  useEffect(() => {
    setSelectedCategory(0);
  }, [type]); // reset category when switching between tabs

  const fetchGoods = ({ pageParam = null }) =>
    getUserExplore(user?.id, selectedCategory, pageParam, type, removeMode);

  return (
    <>
      <div
        className="relative sm:flex items-center justify-between mt-8 sm:mb-10 sm:pt-3"
        style={{ paddingTop: "env(safe-area-inset-top)" }}
      >
        <h1 className="text-xl font-black text-primary hidden sm:block pl-3">
          Goodsend
        </h1>

        <ExploreNav
          currentUser={user}
          removeMode={removeMode}
          setRemoveMode={setRemoveMode}
        />
      </div>
      <Categories
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        expand={true}
        className={
          "block whitespace-nowrap overflow-x-auto mt-2 px-3 no-scrollbar"
        }
      />

      <div className="px-3">
        <GoodsList
          userId={user?.id}
          selectedCategory={selectedCategory}
          fetchGoods={fetchGoods}
          pageType={type}
          removeMode={removeMode}
        />
      </div>
    </>
  );
};

export default Explore;
