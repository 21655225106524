import * as amplitude from "@amplitude/analytics-browser";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "ToastContext";
import { useUser } from "UserContext";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { ReactComponent as LogoIcon } from "assets/icons/logo.svg";
import { ReactComponent as TelegramIcon } from "assets/icons/telegram.svg";
import { ReactComponent as TelegramLogoIcon } from "assets/icons/telegram_logo.svg";
import PrimaryButton from "components/PrimaryButton";
import TelegramButton from "components/TelegramButton";
import BottomModal from "components/modals/bottom_modal/BottomModal";
import { useTelegramConnectMutation } from "hooks/useTelegramConnectMutation";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { addGood } from "services/api";

const AddGoodModal = ({ isOpen, onClose }) => {
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const { user: currentUser } = useUser();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ formData }) => addGood(formData),
    onSettled: () => {
      queryClient.invalidateQueries(["userGoods", currentUser.id]);
      addToast("Good added successfully");
      onClose();
      setValue("url", "");
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("url", data.url);

    mutate({ formData });
  };
  const { mutate: handleConnectTelegram } = useTelegramConnectMutation();
  const urlValue = watch("url");

  const handleClick = async () => {
    try {
      const text = await navigator.clipboard.readText();
      if (isValidUrl(text)) {
        // alert(`Last URL from clipboard: ${text}`);
        setValue("url", text);
      } else {
        console.log("Clipboard does not contain a valid URL.");
      }
    } catch (err) {
      console.error("Failed to read clipboard contents: ", err);
    }
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  return (
    <>
      <BottomModal
        key="addToCollectionModal"
        isOpen={isOpen}
        onClose={onClose}
        zIndex={30}
        isNested={true}
        containerClassName={"max-w-[550px]"}
      >
        <div className="flex flex-col justify-between w-full">
          <div className="px-4 pb-6 text-black">
            <h3 className="text-xl font-bold mb-1 mt-4">
              Add a new item to Goodsend
            </h3>
            <p className="text-grey-2 mb-6">
              Paste a link to the item you want to save
            </p>
            <form onSubmit={handleSubmit(onSubmit)} className="mb-12">
              <div className="relative">
                <input
                  id="url"
                  className="bg-grey-4 text-lg p-4 ps-12 rounded-full w-full mb-1 pe-10 focus:outline focus:outline-1 outline-black"
                  {...register("url", {
                    required: true,
                    pattern: /https?:\/\/.+/,
                  })}
                  placeholder="Paste a link"
                  autoComplete="off"
                />
                <LinkIcon className="absolute top-[16px] left-4 text-grey-2 w-6 h-6" />
                {!urlValue && (
                  <button
                    className="absolute top-4 right-5 font-bold text-primary underline"
                    onClick={handleClick}
                    type="button"
                  >
                    Paste
                  </button>
                )}
                {errors.url && (
                  <p className="mb-2">
                    Url is required and must be a valid url.
                  </p>
                )}
              </div>
              <PrimaryButton
                className="w-full mt-4"
                type="submit"
                disabled={!urlValue || isPending}
              >
                Save
              </PrimaryButton>
            </form>
            <p className="text-grey-2 mb-2">More ways to save anything:</p>
            <div className="flex justify-between bg-grey-4 rounded-2xl p-4 mb-4">
              <div>
                <p className="text-lg mb-3">
                  Goodsend <br /> Telegram Bot
                </p>
                {currentUser.telegram_id ? (
                  <a
                    href="https://t.me/goodsend_bot?start=pbf68a9c0-fec5-11e8-8b50-0dde9ba5dff0"
                    target="_blank"
                    className="flex items-center bg-telegram rounded-full px-3 py-1 text-white text-base"
                    rel="noreferrer"
                  >
                    <TelegramIcon className="inline-block w-4 h-4 mr-1 text-grey-2 -mb-[1px]" />
                    Open Telegram
                  </a>
                ) : (
                  <TelegramButton
                    className="bg-telegram rounded-full px-3 py-1 text-white text-base"
                    handleAuth={handleConnectTelegram}
                    context="new_good_flow"
                  >
                    <TelegramIcon className="inline-block w-4 h-4 mr-1 text-grey-2 -mb-[1px]" />
                    Connect Telegram
                  </TelegramButton>
                )}
              </div>
              <TelegramLogoIcon className="w-16 h-16" />
            </div>
            <div className="flex justify-between bg-grey-4 rounded-2xl p-4">
              <div>
                <p className="text-lg mb-3">
                  Goodsend <br /> One-Tap Sharing
                </p>
                <Link
                  to="/intro/save-via-share"
                  className="block bg-primary rounded-full px-3 py-1 text-white text-center w-40"
                  onClick={() =>
                    amplitude.track("enable_one_tap", {
                      context: "new_good_flow",
                    })
                  }
                >
                  Enable
                </Link>
              </div>
              <LogoIcon className="w-16 h-16" />
            </div>
          </div>
        </div>
      </BottomModal>
    </>
  );
};

export default AddGoodModal;
