import * as amplitude from "@amplitude/analytics-browser";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { usePaywall } from "PaywallContext";
import { useToast } from "ToastContext";
import { useUser } from "UserContext";
import { ReactComponent as AppleIcon } from "assets/icons/apple.svg";
import { ReactComponent as ArchiveIcon } from "assets/icons/archive.svg";
import { ReactComponent as BagIcon } from "assets/icons/bag.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close_filled.svg";
import { ReactComponent as CollageIcon } from "assets/icons/collage.svg";
import { ReactComponent as CollectionIcon } from "assets/icons/collections.svg";
import { ReactComponent as LeftArrowIcon } from "assets/icons/left_arrow.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { ReactComponent as MoreIcon } from "assets/icons/more.svg";
import { ReactComponent as NoteIcon } from "assets/icons/note.svg";
import { ReactComponent as PlayIcon } from "assets/icons/play.svg";
import { ReactComponent as SpinnerIcon } from "assets/icons/spinner.svg";
import { ReactComponent as StopIcon } from "assets/icons/stop.svg";
import { useCallback, useEffect, useRef, useState } from "react";
import { getPricing, updateUserPrivacy } from "services/api";
import PrimaryButton from "./PrimaryButton";
import BottomModal from "./modals/bottom_modal/BottomModal";

const products = [
  {
    sku: "prem_3_month_17.99",
    period: "3 month",
    price: "$14.99",
    additional: "Save 25%",
    renew_text: "$14.99 every 3 month",
  },
  {
    sku: "prem_1_month_7.99",
    period: "1 month",
    price: "$5.99",
    renew_text: "$5.99 every month",
  },

  {
    sku: "prem_12_month_49.99",
    period: "1 year",
    price: "$34.99",
    additional: "Save 50%",
    renew_text: "$34.99 every 12 month",
  },
  {
    sku: "prem_1_month_3.99",
    period: "1 month",
    price: "$3.99",
    renew_text: "$3.99 every month",
  },
];

const paywalls = {
  keep_private: {
    title: (
      <>
        <LockIcon className="w-10 h-10" /> Private <br /> Account & Goods
      </>
    ),
    heading: "Do you want to keep your account private with Goodsend Premium?",
    description:
      "With this update, hiding your account is a Premium exclusive. The free version remains feature-rich, but your items will be visible for community sharing in the explore section.",
    primaryBtn: "Keep Private",
    secondaryBtn: "Switch to Public",
  },
  private: {
    title: (
      <>
        <LockIcon className="w-10 h-10" /> Private <br /> Account & Goods
      </>
    ),
    heading: "Go private with Goodsend Premium privacy features",
    description:
      "Hide your whole gallery, a gift collection for someone special or make your personal goods go private.",
    primaryBtn: "Continue",
  },
  notes: {
    title: (
      <>
        <NoteIcon className="w-10 h-10" /> Notes
      </>
    ),
    heading: "Add important details with Goodsend Premium",
    description:
      "Save your shoe size, the date of the next drop, or ideas on what to combine this good with.",
    primaryBtn: "Continue",
  },
  owned: {
    title: (
      <>
        <BagIcon className="w-10 h-10" /> Owned
      </>
    ),
    heading: "Get an Owned label for your goods with Goodsend Premium",
    description:
      "Keep track of the goods you got without deleting them from your gallery.",
    primaryBtn: "Continue",
  },
  goods_limit: {
    title: (
      <>
        <span className="font-bold text-[72px]/none">∞</span> Unlimited Goods
      </>
    ),
    heading: "Goods limit reached. Join Goodsend Premium to add more.",
    description:
      "As a free user you can umpload max 15 goods. Please upgrade to Goodsend Premium to upload inlimited number of goods. ",
    primaryBtn: "Remove Limit",
  },
  archive: {
    title: (
      <>
        <ArchiveIcon className="w-10 h-10" /> Goods
        <br />
        Archive
      </>
    ),
    heading: "See items you archived with Goodsend Premium",
    description:
      "All items you've ever added to Goodsend, accessible whenever you need them",
    primaryBtn: "Continue",
  },
  private_collection: {
    title: (
      <>
        <CollectionIcon className="w-10 h-10 mb-1" /> Private <br /> Collections
      </>
    ),
    heading: "Make collections private with Goodsend Premium",
    description:
      "Organize your goods in private collections, hidden from the public eye.",
    primaryBtn: "Continue",
  },
  collage_download: {
    title: (
      <>
        <CollageIcon className="w-10 h-10 mb-1" /> Download <br /> Collages
      </>
    ),
    heading: "Download Collages with Goodsend Premium",
    description:
      "Download high-resolution collages without watermarks using Goodsend Premium. Otherwise, use the shareable link provided.",
    primaryBtn: "Continue",
  },
};

const CountdownClock = () => {
  const [timeLeft, setTimeLeft] = useState({
    hours: "23",
    minutes: "59",
    seconds: "59",
  });
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    const offerStarted = localStorage.getItem("offerStarted");
    const intervalId = setInterval(() => {
      calculateTimeLeft(offerStarted);
    }, 1000);
    setIntervalId(intervalId);

    return () => clearInterval(intervalId);
  }, []);

  const calculateTimeLeft = (offerStarted) => {
    const endTime = new Date(offerStarted).getTime() + 24 * 60 * 60 * 1000;
    const now = new Date().getTime();
    const difference = endTime - now;

    // When countdown finishes, stop updating
    if (difference <= 0) {
      clearInterval(intervalId);
      setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
      return;
    }

    let hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((difference % (1000 * 60)) / 1000);

    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    setTimeLeft({ hours, minutes, seconds });
  };

  return (
    <div className="flex items-center bg-primary rounded-full p-2 text-grey-4 font-bold text-sm/none mb-4">
      <ClockIcon className="w-4 h-4 inline mr-1" />
      {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds} left
    </div>
  );
};

const Paywall = ({ isOpen, onClose, type, setType, context }) => {
  const { openPaywall } = usePaywall();
  const { isWebView } = useUser();
  const { addToast } = useToast();
  const [selectedSKU, setSelectedSKU] = useState(products[1].sku);
  const [loading, setLoading] = useState(false);
  const [isCloseable, setIsCloseable] = useState(true);
  const queryClient = useQueryClient();

  const { data: pricing } = useQuery({
    queryFn: getPricing,
    queryKey: ["pricing"],
    enabled: isOpen,
  });

  const [isAppRequiredOpen, setIsAppRequiredOpen] = useState(false);
  const [isUpdateAppOpen, setisUpdateAppOpen] = useState(false);

  const onCloseAppRequired = () => {
    setIsAppRequiredOpen(false);
    setisUpdateAppOpen(false);
  };

  // Callback to be called on successful subscription
  const handleSubscriptionSuccess = useCallback((message) => {
    setLoading(false);
    console.log("Subscription Success:", message);
    addToast(message);
    setType("success");
    amplitude.track("payment_succeeded", {
      sku: selectedSKU,
      context: context,
      sub_period: products.find((p) => p.sku === selectedSKU).period,
    });
    const previousData = queryClient.getQueryData(["currentUserInfo"]);
    // Check if the userGoods data exists
    if (previousData) {
      queryClient.setQueryData(["currentUserInfo"], (old) => {
        return {
          ...old,
          is_premium: 1,
        };
      });
    }
  }, []);

  // Callback for handling subscription errors
  const handleSubscriptionError = useCallback((message) => {
    setLoading(false);
    console.error("Subscription Error:", message);
    addToast(message);
    amplitude.track("payment_failed", {
      sku: selectedSKU,
      context: context,
      sub_period: products.find((p) => p.sku === selectedSKU).period,
      error: message,
    });
  }, []);

  // Effect hook to setup message handlers when component mounts
  useEffect(() => {
    window.handleSubscriptionSuccess = handleSubscriptionSuccess;
    window.handleSubscriptionError = handleSubscriptionError;

    // Cleanup function to remove the handlers
    return () => {
      delete window.handleSubscriptionSuccess;
      delete window.handleSubscriptionError;
    };
  }, [handleSubscriptionSuccess, handleSubscriptionError]);

  const onSubscribe = () => {
    amplitude.track("payment_cta_tapped", {
      sku: selectedSKU,
      context: context,
      sub_period: products.find((p) => p.sku === selectedSKU).period,
    });
    if (
      window.webkit === undefined ||
      window.webkit.messageHandlers === undefined ||
      window.webkit.messageHandlers.subscribeUser === undefined
    ) {
      if (isWebView) {
        setisUpdateAppOpen(true);
        amplitude.track("payment_flow_canceled", {
          context: context,
          sku: selectedSKU,
          sub_period: products.find((p) => p.sku === selectedSKU).period,
          reason: "app_old_version",
        });
      } else {
        setIsAppRequiredOpen(true);
        amplitude.track("payment_flow_canceled", {
          context: context,
          sku: selectedSKU,
          sub_period: products.find((p) => p.sku === selectedSKU).period,
          reason: "app_is_required",
        });
      }
      return;
    }

    window.webkit.messageHandlers.subscribeUser.postMessage({
      productId: selectedSKU,
      onSuccess: "handleSubscriptionSuccess",
      onError: "handleSubscriptionError",
    });
    setLoading(true);
  };

  const onRestore = () => {
    if (
      window.webkit === undefined ||
      window.webkit.messageHandlers === undefined ||
      window.webkit.messageHandlers.restorePurchases === undefined
    ) {
      if (isWebView) setisUpdateAppOpen(true);
      else setIsAppRequiredOpen(true);
      return;
    }

    window.webkit.messageHandlers.restorePurchases.postMessage({
      onSuccess: "handleSubscriptionSuccess",
      onError: "handleSubscriptionError",
    });
    setLoading(true);
  };

  const { mutate: updatePrivacyMutate } = useMutation({
    mutationFn: ({ formData }) => updateUserPrivacy(formData),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["currentUserInfo"] });
      queryClient.invalidateQueries({ queryKey: ["userInfo"] });
      addToast("Changes Saved");
    },
  });

  const onMakePublic = () => {
    updatePrivacyMutate({ formData: new FormData().append("privacy", 0) });
    onClose();
  };

  useEffect(() => {
    if (!isOpen) {
      videoRef.current?.pause();
      setIsPlaying(false);
      setIsCloseable(true);
    }
  }, [isOpen]);

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  videoRef.current?.addEventListener("ended", () => {
    setIsPlaying(false);
  });

  const onProductsClick = (type) => {
    if (type === "keep_private") {
      setIsCloseable(false);
    }
    openPaywall("products", context);
  };

  const onSKUClick = (sku) => {
    setSelectedSKU(sku);
    amplitude.track("payment_sku_tapped", {
      sku: sku,
      context: context,
      sub_period: products.find((p) => p.sku === sku).period,
    });
  };

  useEffect(() => {
    if (type === "products_offer") {
      setSelectedSKU("prem_1_month_3.99");
    }
  }, [type]);

  return (
    <>
      {type === "products" || type === "products_offer" ? (
        <BottomModal
          isOpen={isOpen}
          onClose={onClose}
          zIndex={30}
          containerClassName={"max-w-[550px]"}
          isFullHeight={true}
          className={"max-h-[100vh] h-full"}
          isNested={true}
          isNotCloseable={!isCloseable}
        >
          <div className="flex flex-col justify-between w-full pt-8 min-h-full">
            <div className="flex justify-between items-center mb-12 px-4">
              {isCloseable ? (
                <button onClick={onClose}>
                  <CloseIcon className="w-6 h-6 text-grey-2" />
                </button>
              ) : (
                <button onClick={() => setType("keep_private")}>
                  <LeftArrowIcon className="w-6 h-6 text-grey-2" />
                </button>
              )}
              <button
                className="text-primary underline font-bold text-sm"
                onClick={onRestore}
              >
                Restore
              </button>
            </div>
            <div className=" relative px-4 pb-6 text-black min-h-full">
              <div className="flex flex-col items-start px-2">
                {type === "products_offer" && <CountdownClock />}
                <h2 className="font-black text-2xl max-w-[320px]">
                  Join <span className="text-primary">Goodsend Premium</span>
                  {type === "products_offer" && (
                    <>
                      {" "}
                      at <span className="text-nowrap">
                        Not-to-Be-Missed
                      </span>{" "}
                      <span className="text-primary">-35% OFF!</span>
                    </>
                  )}
                </h2>
                {type === "products_offer" && (
                  <p className="mt-6 mb-2 text-sm text-grey-1">
                    Only <s>{pricing ? pricing["prem_1_month_7.99"] : "$"}</s>{" "}
                    {pricing ? pricing["prem_1_month_3.99"] : "$"}/month
                  </p>
                )}
                <div className="flex items-center mb-4  mt-8">
                  <NoteIcon className="w-6 h-6 text-grey-2 mr-2 " />
                  <p className=" max-w-[300px] leading-tight">
                    <b>Add Notes to your goods:</b> specify your size, preferred
                    color, or model
                  </p>
                </div>
                <div className="flex items-center mb-4">
                  <BagIcon className="w-6 h-6 text-grey-2 mr-2 " />
                  <p className=" max-w-[300px] leading-tight">
                    <b>Mark as Owned:</b> organize goods when they become yours
                  </p>
                </div>
                <div className="flex items-center mb-4">
                  <LockIcon className="w-6 h-6 text-grey-2 mr-2 " />
                  <p className=" max-w-[300px] leading-tight">
                    <b>Enjoy your Privacy:</b> hide your whole gallery or a
                    special collection
                  </p>
                </div>
                <div className="flex items-center mb-4">
                  <div className="flex items-center justify-center w-6 h-6 mr-2">
                    <CollectionIcon className="w-4 h-4 text-grey-2" />
                  </div>
                  <p className=" max-w-[300px] leading-tight">
                    <b>Feel no limits:</b> create unlimited Collections and
                    Wishlists
                  </p>
                </div>
                <div className="flex items-center mb-6">
                  <div className="flex items-center justify-center w-6 h-6 mr-2">
                    <MoreIcon className="w-4 h-4 text-grey-2 " />
                  </div>
                  <p className=" max-w-[400px] leading-tight">
                    Be the first to receive all the new features
                  </p>
                </div>
              </div>
              {type === "products" && (
                <div className="flex w-full">
                  {products.slice(0, 3).map((product) => (
                    <div
                      key={product.sku}
                      className={`flex-1 rounded-3xl bg-grey-3 p-1 mr-2 text-center text-grey-2 hover:bg-primary hover:text-white ${
                        product.additional ? "h-fit" : ""
                      } ${
                        product.sku === selectedSKU && "bg-primary text-white"
                      }`}
                      onClick={() => onSKUClick(product.sku)}
                    >
                      <div
                        className={`rounded-[20px] bg-grey-4 w-full h-full aspect-square flex flex-col justify-center items-center ${
                          product.additional ? "" : "pb-8"
                        }`}
                      >
                        <p className="text-lg mb-1 text-black">
                          {product.period}
                        </p>
                        <p className="text-grey-2">
                          {pricing ? pricing[product.sku] : "$"}
                        </p>
                      </div>
                      {product.additional && (
                        <p className="mt-2 mb-1 text-sm">
                          {product.additional}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              )}

              <PrimaryButton
                className={"w-full mt-8"}
                onClick={onSubscribe}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <SpinnerIcon className="animate-spin w-[18px] h-[18px] mr-2" />
                    Processing
                  </>
                ) : (
                  "Continue"
                )}
              </PrimaryButton>
              <p className="text-grey-2 text-xs mt-8">
                Your subscription will automatically renew at{" "}
                {products.find((p) => p.sku === selectedSKU).renew_text} until
                you cancel it at least 24 hours prior to the end of the current
                period. By tapping Continue, you agree to our{" "}
                <a
                  className="underline text-primary"
                  href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                >
                  Terms of Use
                </a>
                .
              </p>
            </div>
          </div>
          <BottomModal
            isOpen={isAppRequiredOpen || isUpdateAppOpen}
            onClose={onCloseAppRequired}
            zIndex={40}
            containerClassName={"max-w-[550px]"}
            isNested={true}
            isCloseIcon={false}
          >
            <div className="px-4 pb-6 h-full pt-8">
              <div className="flex flex-col items-center justify-center bg-grey-4 rounded-3xl w-full text-sm text-grey-1 text-center py-4">
                <div className="flex justify-center items-center bg-white rounded-3xl font-black text-6xl w-[100px] h-[100px] mb-2">
                  G
                </div>
                Goodsend
              </div>
              <div className="flex flex-col justify-center items-center text-center px-4">
                <h2 className="text-xl font-bold mt-8 mb-4 max-w-[300px]">
                  {isAppRequiredOpen
                    ? "Goodsend iOS App Required"
                    : "Please update Goodsend app to use new features"}
                </h2>
                <p className="text-lg text-grey-1 max-w-[350px]">
                  {isAppRequiredOpen
                    ? "Unlock full functionality by downloading the latest Goodsend app."
                    : "For new enhancements, update your Goodsend app via the App Store. Tap 'Update now' to proceed."}
                </p>
              </div>
              <a href="https://apps.apple.com/app/id6449746781">
                <PrimaryButton className={"w-full mt-8 flex items-center"}>
                  <AppleIcon className="w-6 h-6 mr-1" />
                  {isAppRequiredOpen ? "Download the app" : "Update now"}
                </PrimaryButton>
              </a>
            </div>
          </BottomModal>
        </BottomModal>
      ) : type === "success" ? (
        <BottomModal
          isOpen={isOpen}
          onClose={onClose}
          zIndex={30}
          containerClassName={"max-w-[550px]"}
          isFullHeight={true}
          className={"max-h-[100vh] h-full"}
          isNested={true}
        >
          <div className="relative px-6 pb-6 text-black w-full pt-8 min-h-full">
            <div className="flex justify-between items-center ">
              <button onClick={onClose}>
                <CloseIcon className="w-6 h-6 text-grey-2" />
              </button>
            </div>
            <div
              onClick={handlePlay}
              className="relative flex flex-col items-center pt-8 cursor-pointer"
            >
              <video
                ref={videoRef}
                playsInline
                webkit-playsinline="true"
                className="rounded-full border-4 border-primary w-80 h-80"
              >
                <source
                  src="https://goodsend.fra1.cdn.digitaloceanspaces.com/other/premium4.mp4#t=0.001"
                  type="video/mp4"
                />
              </video>
              <div className="flex items-center absolute bottom-[-20px] px-6 py-4 rounded-full bg-primary shadow-[0_1px_2px_1px_#0000001a] text-white text-lg ">
                {!isPlaying ? (
                  <>
                    <PlayIcon className="w-6 h-6 inline-block mr-2" />
                    Play
                  </>
                ) : (
                  <>
                    <StopIcon className="w-6 h-6 inline-block mr-2" />
                    Stop
                  </>
                )}
              </div>
            </div>
            <h2 className="text-2xl font-black leading-8 mt-12 mb-6 max-w-[320px]">
              Welcome to Goodsend Premium
            </h2>
            <p className="text-grey-2 text-lg max-w-[360px] mb-6 leading-tight">
              “Thank you for your support! Share your thoughts via Care tab,
              we're always happy to hear your feedback ”
            </p>
            <p className="text-grey-2 text-lg max-w-[285px] mb-24 leading-tight">
              Goodsend Team
            </p>
            <PrimaryButton
              className={
                "fixed bottom-4 w-[95%] mt-8 left-1/2 -translate-x-1/2"
              }
              onClick={() => onClose()}
            >
              Continue
            </PrimaryButton>
          </div>
        </BottomModal>
      ) : type === "keep_private" ? (
        <BottomModal
          isOpen={isOpen}
          onClose={onClose}
          zIndex={30}
          containerClassName={"max-w-[550px]"}
          isNotCloseable={true}
          isNested={true}
        >
          <div className="flex flex-col justify-between w-full pt-8  text-center">
            <div className="relative px-4 pb-6 text-black">
              <div className="grid grid-cols-3 gap-4 min-h-[160px] mb-5">
                <div className="flex flex-col items-center justify-center col-span-2 bg-primary rounded-3xl text-white">
                  {paywalls[type].title}
                </div>
                <div className="flex flex-col items-center justify-between text-black text-sm bg-grey-4 py-6 rounded-3xl">
                  <span>Also</span>{" "}
                  <span className="text-2xl font-bold">5+</span>{" "}
                  <span>Features</span>
                </div>
              </div>

              <h2 className="text-xl font-bold mb-4 px-1">
                {paywalls[type].heading}
              </h2>
              <p className="text-lg mb-10 px-1">{paywalls[type].description}</p>
              <p className="text-sm underline mb-4">{paywalls[type].why}</p>
              <PrimaryButton
                className={"w-full mt-8"}
                onClick={() => onProductsClick(type)}
              >
                {paywalls[type].primaryBtn}
              </PrimaryButton>
              {paywalls[type].secondaryBtn && (
                <PrimaryButton
                  className="bg-grey-4 text-black w-full mt-2"
                  onClick={onMakePublic}
                >
                  {paywalls[type].secondaryBtn}
                </PrimaryButton>
              )}
            </div>
          </div>
        </BottomModal>
      ) : (
        <BottomModal
          isOpen={isOpen}
          onClose={onClose}
          zIndex={31}
          containerClassName={"max-w-[550px]"}
          isNested={true}
        >
          <div className="flex flex-col justify-between w-full pt-10  text-center">
            <div className="relative px-4 pb-6 text-black">
              <div className="grid grid-cols-3 gap-4 min-h-[160px] mb-5">
                <div className="flex flex-col items-center justify-center col-span-2 bg-primary rounded-3xl text-white leading-tight">
                  {paywalls[type].title}
                </div>
                <div className="flex flex-col items-center justify-between text-black text-sm bg-grey-4 py-6 rounded-3xl">
                  <span>Also</span>
                  <span className="text-2xl font-bold">5+</span>
                  <span>Features</span>
                </div>
              </div>

              <h2 className="text-xl font-bold mb-4 px-1">
                {paywalls[type].heading}
              </h2>
              <p className="text-lg mb-10 px-1">{paywalls[type].description}</p>

              <PrimaryButton
                className={"w-full mt-8"}
                onClick={() => onProductsClick(type)}
              >
                {paywalls[type].primaryBtn}
              </PrimaryButton>
              {paywalls[type].secondaryBtn && (
                <PrimaryButton className="bg-grey-4 text-black w-full mt-2">
                  {paywalls[type].secondaryBtn}
                </PrimaryButton>
              )}
            </div>
          </div>
        </BottomModal>
      )}
    </>
  );
};

export default Paywall;
