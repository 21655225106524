import { CollageCreateModal } from "components/modals/collage_modal/CollageCreateModal";
import { createContext, useContext, useState } from "react";

const CollageEditContext = createContext();

const CollageEditProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [collageDetails, setCollageDetails] = useState(null);
  const openCollageEditModal = (details, context) => {
    setIsOpen(true);
    setCollageDetails(details);
    // amplitude.track("collage_opened", { collage_id: collageId, flow: context });
    // navigate(`${location.pathname}?collage_id=${collageId}`, {
    //   replace: true,
    // });
  };

  const closeCollageEditModal = () => {
    setIsOpen(false);
    // const params = new URLSearchParams(location.search);
    // params.delete("collage_id");
    // navigate(`${location.pathname}?${params.toString()}`, {
    //   replace: true,
    // });
  };

  return (
    <CollageEditContext.Provider
      value={{ openCollageEditModal, closeCollageEditModal }}
    >
      {children}

      <CollageCreateModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        collageDetails={collageDetails}
      />
    </CollageEditContext.Provider>
  );
};

const useCollageEditModal = () => useContext(CollageEditContext);

export { CollageEditProvider, useCollageEditModal };
