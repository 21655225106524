import { usePaywall } from "PaywallContext";
import { ReactComponent as CloseIcon } from "assets/icons/close_filled.svg";
import { useEffect, useState } from "react";

const displayCountName = "paywallBannerDisplayCount";
const lastDisplayDateName = "paywallBannedLastDisplayDate";
const maxDisplayCount = 3;

const PaywallBanner = () => {
  const { openPaywall } = usePaywall();
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const bannerDisplayCount = parseInt(
      localStorage.getItem(displayCountName) || "0",
      10
    );
    const lastDisplayDate = localStorage.getItem(lastDisplayDateName);
    const today = new Date().toISOString().slice(0, 10); // Format YYYY-MM-DD

    if (bannerDisplayCount < maxDisplayCount && lastDisplayDate !== today) {
      setShowBanner(true);
    }
  }, []);

  const handleCloseBanner = () => {
    const bannerDisplayCount = parseInt(
      localStorage.getItem(displayCountName) || "0",
      10
    );
    const today = new Date().toISOString().slice(0, 10); // Format YYYY-MM-DD

    // Update banner count and last display date only when closing the banner
    localStorage.setItem(displayCountName, String(bannerDisplayCount + 1));
    localStorage.setItem(lastDisplayDateName, today);
    setShowBanner(false); // Hide banner after updating count
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div
      onClick={() => {
        openPaywall("products", "profile_banner");
        handleCloseBanner();
      }}
      className="text-sm shadow flex justify-between items-center w-[95vw] max-w-[400px] fixed bottom-[100px] left-1/2 -translate-x-1/2 p-4  bg-primary rounded-2xl text-white z-10"
    >
      <p className="mr-4 leading-tight text-left max-w-[250px]">
        Join <b>Goodsend Premium</b> to unlock awesome premium features.
      </p>
      <button
        className="text-primary bg-white p-3 px-5 leading-none rounded-full hover:filter hover:brightness-95"
        onClick={() => {
          openPaywall("products", "profile_banner");
          handleCloseBanner();
        }}
      >
        Join
      </button>
      <CloseIcon
        className="absolute -top-3 right-0 cursor-pointer w-8 h-8 hover:filter hover:brightness-95"
        onClick={(e) => {
          e.stopPropagation();
          handleCloseBanner();
        }}
      />
    </div>
  );
};

export default PaywallBanner;
