import { motion } from "framer-motion";

export const GoodModalSkeleton = () => {
  return (
    <motion.div
      className="flex flex-col h-full w-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className="flex flex-col justify-between h-full w-full animate-pulse">
        <div className="bg-[#f7f7f7] relative block w-full h-[60vh] bg-cover bg-center rounded-t-3xl mb-4"></div>

        <div className="px-4 pb-6">
          <div className="h-2 w-48 bg-gray-200 rounded-full dark:bg-gray-300 mb-3"></div>
          <div className="text-xl mb-8">
            <div className="h-3 max-w-80 bg-gray-200 rounded-full dark:bg-gray-300 mb-2.5"></div>
            <div className="h-3 max-w-80 bg-gray-200 rounded-full dark:bg-gray-300 mb-2.5"></div>
          </div>
          <div className="flex justify-between mb-6">
            <div className="py-4 flex-grow mr-3 flex items-center justify-center text-primary text-lg font-bold bg-gray-200 rounded-full dark:bg-gray-300"></div>
            <div className="inline-block h-14 w-14 bg-gray-200 rounded-full dark:bg-gray-300"></div>
          </div>
          <div className="relative flex items-center mb-14">
            <div className="font-light">
              <div className="h-3 w-48 bg-gray-200 rounded-full dark:bg-gray-300"></div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
