import { useMutation, useQueryClient } from "@tanstack/react-query";
import { markAsOwned } from "services/api";

export const useOwnMutation = (userId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (goodId) => markAsOwned(goodId),
    onMutate: async (goodId) => {
      await queryClient.cancelQueries({
        queryKey: ["userGoods", userId],
      });

      const previousGoods = queryClient.getQueryData(["userGoods", userId, 0]);
      console.log("previousGoods", previousGoods);
      // Check if the userGoods data exists
      if (previousGoods) {
        queryClient.setQueryData(["userGoods", userId, 0], (old) => {
          return {
            pages: old?.pages.map((page) => ({
              ...page,
              goods: page.goods.filter((good) => good.id !== goodId),
            })),
            pageParams: old?.pageParams,
          };
        });
      }
      console.log("{ previousGoods, goodId }", { previousGoods, goodId });
      return { previousGoods, goodId };
    },
    onError: (err, variables, context) => {
      // Only reset if previousGoods is available
      if (context?.previousGoods) {
        queryClient.setQueryData(
          ["userGoods", userId, 0],
          context.previousGoods
        );
      }
    },
    onSettled: (data, error, context) => {
      queryClient.invalidateQueries({
        queryKey: ["userGoods", userId],
      });
      queryClient.invalidateQueries({
        queryKey: ["ownedGoods"],
      });
      // Invalidate "goodDetails" only if goodId is available
      if (context) {
        queryClient.invalidateQueries({
          queryKey: ["goodDetails", context],
        });
      }
    },
  });
};
