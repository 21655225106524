import * as amplitude from "@amplitude/analytics-browser";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { ReactComponent as CollageIcon } from "assets/icons/collage.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import { ReactComponent as GridIcon } from "assets/icons/goods.svg";
import { ReactComponent as InstaIcon } from "assets/icons/insta.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { ReactComponent as ShareIcon } from "assets/icons/share_icon.svg";
import { ReactComponent as Spinner } from "assets/icons/spinner.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { useCollageEditModal } from "CollageEditModalContext";
import GoodsGrid from "components/GoodsGrid";
import PrimaryButton from "components/PrimaryButton";
import * as fabric from "fabric";
import useShare from "hooks/useShare";
import { usePaywall } from "PaywallContext";
import { Suspense, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteCollage,
  getCollageDetails,
  getCollageGoods,
} from "services/api";
import { useToast } from "ToastContext";
import { useUser } from "UserContext";
import BottomModal from "../bottom_modal/BottomModal";

export function CollageModal({ isOpen, onClose, collageId, openGoodModal }) {
  const queryClient = useQueryClient();
  const { user: currentUser } = useUser();
  const [isShareOpen, setIsShareOpen] = useState(false);
  const { addToast } = useToast();
  const [loadingShare, setLoadingShare] = useState(false);
  const { openPaywall } = usePaywall();
  const { openCollageEditModal } = useCollageEditModal();

  const handleDownloadClick = async () => {
    if (currentUser?.is_premium) {
      const scaleFactor = 3;

      const dataURL = fabricCanvas.current.toDataURL({
        format: "png",
        quality: 1,
        multiplier: scaleFactor, // Use multiplier for higher resolution
      });

      const a = document.createElement("a");
      a.href = dataURL;
      // crete a filename form title, replace spaces with underscores and add .png
      const filename =
        collageDetails?.title.replace(/ /g, "_").toLowerCase() +
        "_collage" +
        ".png";
      a.download = filename;
      a.click();
      amplitude.track("collage_download_clicked", { id: collageId });
    } else {
      openPaywall("collage_download", "collage_modal");
    }
  };
  const onShare = useShare();
  const handleCopyClick = (url) => {
    amplitude.track("share_modal_link_copied");
    navigator.clipboard
      .writeText(url ? url : window.location.href)
      .then(() => addToast("Link copied to clipboard"))
      .catch((error) => console.error("Error copying content:", error));
  };
  const handleClick = async (event) => {
    event.preventDefault();
    setIsShareOpen(true);
    // handleShareClick();
  };

  const { mutate: deleteCollageMutation } = useMutation({
    mutationFn: (collageId) => deleteCollage(collageId),
    onSettled: () => {
      onCloseModal();
      queryClient.invalidateQueries(["collectionGoods"]);
    },
  });

  const handleDeleteClick = () => {
    deleteCollageMutation(collageId);
    amplitude.track("collage_delete_clicked", { id: collageId });
  };

  const canvasRef = useRef(null);
  const fabricCanvas = useRef(null);
  const {
    data: collageDetails,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["collageDetails", collageId],
    queryFn: () => getCollageDetails(collageId),
  });

  const {
    data: collageGoods,
    isPending: isPendingGoods,
    isFetching: isFetchingGoods,
    isLoading: isLoadingGoods,
    hasNextPage: hasNextPageGoods,
    isFetchingNextPage: isFetchingNextPageGoods,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ["collageGoods", collageId],
    queryFn: ({ pageParam = 0 }) => getCollageGoods(collageId, pageParam),
    getNextPageParam: (lastPage, pages) => lastPage?.offset || null,
    enabled: collageId != null && isOpen,
  });

  const { ref, inView: inViewGoods } = useInView({
    threshold: 0,
    rootMargin: "0px 0px 500px 0px",
  });

  if (inViewGoods && hasNextPageGoods && !isPendingGoods && !isFetchingGoods) {
    fetchNextPage();
  }

  useEffect(() => {
    const wrapper = document.getElementById("canvas-modal-wrapper");
    // Initialize Fabric.js canvas

    fabricCanvas.current = new fabric.Canvas(canvasRef.current, {
      width: wrapper.clientHeight * (9 / 16),
      height: wrapper.clientHeight,
      backgroundColor: "transparent",
      renderOnAddRemove: true,
      enablePointerEvents: true,
      selection: false,
      hoverCursor: "default",
      allowTouchScrolling: true,
    });

    const resizeCanvas = () => {
      let canvasWidth, canvasHeight;

      canvasWidth = wrapper.clientHeight * (9 / 16);
      canvasHeight = wrapper.clientHeight;

      if (canvasWidth > wrapper.clientWidth) {
        canvasWidth = wrapper.clientWidth;
        canvasHeight = wrapper.clientWidth * (16 / 9);
      }
      fabricCanvas.current.setDimensions({
        width: canvasWidth,
        height: canvasHeight,
      });
    };
    resizeCanvas();
    window.addEventListener("resize", resizeCanvas);
    fabricCanvas.current.preserveObjectStacking = true;

    // Clean up on unmount
    return () => {
      fabricCanvas.current.dispose();
      window.removeEventListener("resize", resizeCanvas);
    };
  }, [collageDetails]);

  useEffect(() => {
    if (!collageDetails || isLoading || isError) return;
    const canvasJSON = JSON.parse(collageDetails["json"]);
    const originalCanvasWidth = canvasJSON.width;
    const originalCanvasHeight = canvasJSON.height;

    const wrapper = document.getElementById("canvas-modal-wrapper");

    if (originalCanvasWidth > originalCanvasHeight) {
      fabricCanvas.current.setDimensions({
        width: wrapper.clientWidth,
        height:
          wrapper.clientWidth * (originalCanvasHeight / originalCanvasWidth),
      });
    }

    const newCanvasWidth = fabricCanvas.current.width;
    const newCanvasHeight = fabricCanvas.current.height;

    const widthScaleFactor = newCanvasWidth / originalCanvasWidth;
    const heightScaleFactor = newCanvasHeight / originalCanvasHeight;

    const scaleFactor = Math.min(widthScaleFactor, heightScaleFactor);

    fabricCanvas.current.loadFromJSON(collageDetails["json"]).then((e) => {
      fabricCanvas.current.getObjects().forEach((obj) => {
        obj.scaleX *= scaleFactor;
        obj.scaleY *= scaleFactor;
        if (obj.goodId) obj.hoverCursor = "pointer";

        // Adjust positions based on scaling factor
        obj.left *= scaleFactor;
        obj.top *= scaleFactor;
        obj.selectable = false; // Disable selection
        obj.evented = true; // Allow event listening (like clicks)
        obj.setCoords();
      });

      let isDragging = false;
      let lastPosX;
      let lastPosY;

      // Handle object clicks and drags
      fabricCanvas.current.on("mouse:down", function (options) {
        isDragging = false;
        let evt = options.e;
        lastPosX = evt.clientX;
        lastPosY = evt.clientY;
      });

      fabricCanvas.current.on("mouse:move", function (options) {
        let evt = options.e;
        let dx = evt.clientX - lastPosX;
        let dy = evt.clientY - lastPosY;
        if (dx * dx + dy * dy > 25) {
          // 5 pixels threshold squared
          isDragging = true;
        }
      });

      fabricCanvas.current.on("mouse:up", function (options) {
        if (!isDragging) {
          const clickedObject = options.target;
          if (clickedObject && clickedObject.goodId) {
            openGoodModal(clickedObject.goodId);
          }
        }
      });

      fabricCanvas.current.renderAll();
    });

    // Clean up event listeners on unmount
    return () => {
      fabricCanvas.current.off("mouse:down");
      fabricCanvas.current.off("mouse:move");
      fabricCanvas.current.off("mouse:up");
    };
  }, [collageDetails]);

  const navigate = useNavigate();
  const location = useLocation();
  const onCloseModal = () => {
    navigate(location.pathname, { replace: true });
    onClose();
  };

  const onInstaShare = async () => {
    amplitude.track("share_modal_insta_share_tapped");
    if (
      window.webkit === undefined ||
      window.webkit.messageHandlers === undefined ||
      window.webkit.messageHandlers.shareToInstagram === undefined
    ) {
      // if (isWebView) {
      //   amplitude.track("share_modal_insta_share_failed", {
      //     reason: "old_app_version",
      //   });
      //   setisUpdateAppOpen(true);
      // } else {
      //   amplitude.track("share_modal_insta_share_failed", {
      //     reason: "not_webview",
      //   });
      //   setIsAppRequiredOpen(true);
      // }
      return;
    }

    setLoadingShare(true);

    // const backgroundImageWStickerUrl = `https://goodsend.fra1.cdn.digitaloceanspaces.com/other/background_${imageId}_w_sticker.webp`;

    // try {
    //   const backgroundImageBase64 = await downloadImageAsBase64(
    //     backgroundImageWStickerUrl
    //   );

    //   window.webkit.messageHandlers.shareToInstagram.postMessage({
    //     backgroundImage: backgroundImageBase64,
    //     stickerImage: stickerBase64, // Assuming stickerBase64 is already set
    //   });
    // } catch (error) {
    //   addToast("Error sharing to Instagram :(");
    //   amplitude.track("share_modal_insta_share_failed", {
    //     reason: error.message,
    //   });
    //   //   console.error("Error sharing to Instagram:", error);
    // }
    amplitude.track("share_modal_insta_share_success");
    setLoadingShare(false);
  };

  return (
    <BottomModal
      className={"pt-0"}
      isOpen={isOpen}
      onClose={onCloseModal}
      zIndex={20}
      containerClassName={" max-w-[550px] h-full"}
    >
      <div
        className={`flex justify-center items-center relative w-full h-[70vh] bg-no-repeat rounded-t-3xl mb-4`}
        id="canvas-modal-wrapper"
        style={{
          backgroundColor:
            collageDetails?.bg_color !== "transparent"
              ? collageDetails?.bg_color
              : "#F7F7F7",
        }}
      >
        <canvas
          id="collage-modal-canvas"
          ref={canvasRef}
          className="w-full h-full"
        />
      </div>
      <div className="px-4 pb-[100px]">
        <div
          className={`${
            collageDetails?.owner_user_id !== currentUser?.id && "flex"
          } justify-between items-center `}
        >
          <p className="text-lg font-bold mb-4">{collageDetails?.title}</p>
          <div className="flex mb-4">
            {collageDetails?.owner_user_id === currentUser?.id ? (
              <>
                <PrimaryButton
                  onClick={() => {
                    onClose();
                    openCollageEditModal(collageDetails, "collage");
                  }}
                  className="flex-grow mr-2 bg-primary rounded-full flex items-center justify-center text-white text-lg px-2"
                >
                  <CollageIcon className="w-6 h-6 mr-2.5" />
                  <p className="max-[400px]:text-base">Edit Collage</p>
                </PrimaryButton>
                <button
                  onClick={handleClick}
                  className={`w-14 bg-[#F6F6F6] mr-2 rounded-full h-14 flex items-center justify-center text-grey-2 text-lg font-bold hover:filter hover:brightness-95`}
                >
                  <ShareIcon className="w-4 h-5" />
                </button>
                <button
                  onClick={handleDeleteClick}
                  className="rounded-full bg-grey-4 w-14 h-14 flex justify-center items-center hover:filter hover:brightness-95"
                >
                  <TrashIcon className="w-6 h-6 text-grey-2 " />
                </button>
              </>
            ) : (
              <button
                onClick={handleClick}
                className={`w-14 bg-[#F6F6F6] mr-2 rounded-full h-14 flex items-center justify-center text-grey-2 text-lg font-bold hover:filter hover:brightness-95`}
              >
                <ShareIcon className="w-6 h-6" />
              </button>
            )}
          </div>
        </div>
        <p className="mb-4 text-grey-1">
          <GridIcon className="inline w-5 h-5 mr-2 mb-1 text-grey-2" />
          Collage Goods
        </p>
        <BottomModal
          isOpen={isShareOpen}
          onClose={() => setIsShareOpen(false)}
          zIndex={30}
          isNested={true}
          containerClassName={"max-w-[550px]"}
          className={"rounded-t-3xl px-4"}
        >
          <h2 className="text-xl font-bold mb-6">Share</h2>
          <div className="flex justify-between max-w-[350px] mx-auto pb-12">
            <button
              className="flex flex-col items-center text-sm/tight hover:filter hover:brightness-95 active:scale-[0.9875] mr-6"
              onClick={onInstaShare}
            >
              <div className="flex items-center justify-center w-14 h-14 bg-grey-4 rounded-full mb-1">
                {loadingShare ? (
                  <Spinner className="w-6 h-6 text-primary animate-spin" />
                ) : (
                  <InstaIcon className="w-6 h-6 text-primary" />
                )}
              </div>
              Share to
              <br />
              Stories
            </button>

            <button
              className="flex flex-col items-center text-sm/tight hover:filter hover:brightness-95 active:scale-[0.9875] mr-6"
              onClick={() => handleCopyClick(window.location.href)}
            >
              <div className="flex items-center justify-center w-14 h-14 bg-grey-4 rounded-full mb-1">
                <LinkIcon className="w-8 h-8 text-primary" />
              </div>
              Copy
              <br />
              Link
            </button>

            <button
              className="flex flex-col items-center text-sm/tight hover:filter hover:brightness-95 active:scale-[0.9875] mr-6"
              onClick={handleDownloadClick}
            >
              <div className="flex items-center justify-center w-14 h-14 bg-grey-4 rounded-full mb-1">
                <DownloadIcon className="w-6 h-6 text-primary" />
              </div>
              Download
              <br />
              Image
            </button>

            <button
              className="flex flex-col items-center text-sm/tight hover:filter hover:brightness-95 active:scale-[0.9875]"
              onClick={() => {
                onShare();
                amplitude.track("collage_share_clicked", { id: collageId });
              }}
            >
              <div className="flex items-center justify-center w-14 h-14 bg-grey-4 rounded-full mb-1">
                <div className="flex space-x-1">
                  <span className="w-1.5 h-1.5 bg-primary rounded-full"></span>
                  <span className="w-1.5 h-1.5 bg-primary rounded-full"></span>
                  <span className="w-1.5 h-1.5 bg-primary rounded-full"></span>
                </div>
              </div>
              More
              <br />
              Options
            </button>
          </div>
        </BottomModal>
        <Suspense fallback={null}>
          <GoodsGrid
            showSkeleton={isLoadingGoods || !collageGoods}
            showOwner={false}
            ref={ref}
            isLoadingNextPage={isFetchingNextPageGoods || hasNextPageGoods}
            openGoodModal={openGoodModal}
            goods={collageGoods}
          />
        </Suspense>
        <div ref={ref} /> {/* Scroll detection element */}
      </div>
    </BottomModal>
  );
}
