import { ReactComponent as CloseIcon } from "assets/icons/close_filled.svg";
import { useEffect, useState } from "react";
import PersonalInfoModal from "./modals/settings_modal/PersonalInfoModal";

const UpdateInfoBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [isPersonalInfoOpen, setIsPersonalInfoOpen] = useState(false);

  useEffect(() => {
    const bannerDisplayCount = parseInt(
      localStorage.getItem("bannerDisplayCount") || "0",
      10
    );
    const lastDisplayDate = localStorage.getItem("lastDisplayDate");
    const today = new Date().toISOString().slice(0, 10); // Format YYYY-MM-DD

    // Only show banner if count < 3 and there's no record of being shown today
    if (bannerDisplayCount < 3 && lastDisplayDate !== today) {
      setShowBanner(true);
    }
  }, []);

  const handleCloseBanner = () => {
    const bannerDisplayCount = parseInt(
      localStorage.getItem("bannerDisplayCount") || "0",
      10
    );
    const today = new Date().toISOString().slice(0, 10); // Format YYYY-MM-DD

    // Update banner count and last display date only when closing the banner
    localStorage.setItem("bannerDisplayCount", String(bannerDisplayCount + 1));
    localStorage.setItem("lastDisplayDate", today);
    setShowBanner(false); // Hide banner after updating count
  };

  const onClick = (e) => {
    setIsPersonalInfoOpen(true);
    handleCloseBanner();
  };

  return (
    <>
      {showBanner && (
        <div
          onClick={onClick}
          className="shadow flex justify-between items-center w-[95vw] max-w-[400px] text-sm fixed bottom-[100px] left-1/2 -translate-x-1/2 p-4  bg-primary rounded-2xl text-white z-10"
        >
          <p className="mr-4 leading-tight">
            Make Goodsend yours – <b>add a photo and update personal details</b>
          </p>
          <button className="text-primary bg-white p-3 px-5 leading-none rounded-full hover:filter hover:brightness-95">
            Update
          </button>
          <CloseIcon
            className="absolute -top-3 right-0 cursor-pointer w-8 h-8 hover:filter hover:brightness-95"
            onClick={(e) => {
              e.stopPropagation();
              handleCloseBanner();
            }}
          />
        </div>
      )}
      <PersonalInfoModal
        isOpen={isPersonalInfoOpen}
        onClose={() => setIsPersonalInfoOpen(false)}
      />
    </>
  );
};

export default UpdateInfoBanner;
