import SearchFilters from "components/SearchFilters";

const Search = () => {
  return (
    <>
      <div
        className="xl:mb-10 px-3"
        style={{ paddingTop: "env(safe-area-inset-top)" }}
      >
        <SearchFilters />
      </div>
    </>
  );
};

export default Search;
