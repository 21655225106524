import * as amplitude from "@amplitude/analytics-browser";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import { ReactComponent as PlayIcon } from "assets/icons/play.svg";
import { ReactComponent as StopIcon } from "assets/icons/stop.svg";
import { ReactComponent as TelegramIcon } from "assets/icons/telegram_round.svg";
import { useEffect, useRef, useState } from "react";

const Care = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    amplitude.track(`care_opened`);
  }, []);

  const handlePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  videoRef.current?.addEventListener("ended", () => {
    setIsPlaying(false);
  });

  return (
    <div
      className="flex justify-center flex-col items-center pb-40 md:pt-6"
      style={{ paddingTop: "env(safe-area-inset-top)" }}
    >
      <div
        onClick={handlePlay}
        className="relative flex flex-col items-center pt-2 cursor-pointer"
      >
        <video
          ref={videoRef}
          playsInline
          webkit-playsinline="true"
          className="rounded-full border-4 border-primary w-80 h-80"
        >
          <source
            src="https://goodsend.fra1.cdn.digitaloceanspaces.com/other/care.mp4#t=0.001"
            type="video/mp4"
          />
        </video>
        <div className="flex items-center absolute bottom-[-20px] px-6 py-4 rounded-full bg-primary shadow-[0_1px_2px_1px_#0000001a] text-white text-lg ">
          {!isPlaying ? (
            <>
              <PlayIcon className="w-6 h-6 inline-block mr-2" />
              Play
            </>
          ) : (
            <>
              <StopIcon className="w-6 h-6 inline-block mr-2" />
              Stop
            </>
          )}
        </div>
      </div>
      <h2 className="text-center text-4xl font-black leading-9 mt-12 mb-3">
        See something?
        <br />
        Say something
      </h2>
      <p className="text-black text-lg max-w-[285px] mx-auto mb-6 text-center leading-tight">
        You love <b className="text-primary font-extrabold">Goodsend?</b> Hate
        it? Have a feedback or have an issue?
      </p>
      <div className="flex flex-col items-center w-[90%] text-center text-black">
        <a
          className="flex text-left items-center w-full max-w-96 bg-grey-4 px-4 py-3 mb-2 rounded-3xl text-lg leading-5 hover:filter hover:brightness-95"
          href="https://t.me/goodsendit"
        >
          <TelegramIcon className="w-12 h-12 mr-3" />
          <span>
            Let's chat
            <br />
            <b className="text-telegram font-semibold">via Telegram</b>
          </span>
        </a>
        <a
          className="flex text-left items-center w-full max-w-96 bg-grey-4 px-4 py-3 mb-2 rounded-3xl text-lg leading-5 hover:filter hover:brightness-95"
          href="mailto:team@goodsend.it?subject=Goodsend%20feedback"
        >
          <EmailIcon className="w-12 h-12 mr-3" />
          <span>
            Let's chat
            <br />
            <b className="text-primary font-bold">via Email</b>
          </span>
        </a>
      </div>
    </div>
  );
};

export default Care;
