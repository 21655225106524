import PrimaryButton from "components/PrimaryButton";
import UserAvatar from "components/UserAvatar";
import { Link } from "react-router-dom";
import BottomModal from "../bottom_modal/BottomModal";

const CircleModal = ({ isOpen, onClose, username, followees }) => {
  return (
    <>
      <BottomModal
        isOpen={isOpen}
        onClose={onClose}
        containerClassName={"max-w-[550px] "}
        className={"rounded-t-3xl overflow-x-hidden pb-10"}
        zIndex={20}
      >
        <div className="flex flex-col px-4 min-h-[400px] h-full max-h-[calc(80_*_var(--vh))]">
          {followees?.length > 0 ? (
            <>
              <h2 className="text-black text-xl font-bold mb-2">
                {username}' Circle
              </h2>
              <p className="text-sm text-grey-2">
                Circle - a group of people whose taste and preferences{" "}
                {username} likes.
              </p>
              <div className="grid gap-x-2 gap-y-1 grid-cols-[repeat(auto-fill,_minmax(80px,_1fr))] mt-6">
                {followees?.map((followee, i) => (
                  <Link
                    key={i}
                    to={`/${followee.link_tag}`}
                    onClick={onClose}
                    className="flex justify-center"
                  >
                    <UserAvatar
                      size="large"
                      image_url={followee.photo_url}
                      username={followee.name}
                      showUsername={true}
                      className={`border-[1px] border-white`}
                    />
                  </Link>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col justify-center items-center text-center mt-8">
                <h2 className="font-bold text-xl/tight mb-2">
                  Welcome to
                  <br /> Goodsend Circle
                </h2>
                <p className="text-lg max-w-[300px]">
                  Form your Circle of people whose style you want to follow.
                </p>
                <p className="text-lg mt-4 max-w-[320px]">
                  1. Find people you like.
                  <br />
                  2. Add them to your Circle.
                  <br />
                  3. Discover goods they collect.
                  <br />
                  4. Find new brands and inspirations.
                  <br />
                  5. Get updates when people from your Circle share new goods.
                </p>
              </div>
              <PrimaryButton onClick={onClose} className="mt-16">
                Continue
              </PrimaryButton>
            </>
          )}
        </div>
      </BottomModal>
    </>
  );
};

export default CircleModal;
