import GoodsList from "components/GoodsList";
import { useUser } from "../UserContext";
import { getBadGoods } from "../services/api";

const Admin = ({ type }) => {
  const { user } = useUser();

  const fetchGoods = ({ pageParam = null }) => getBadGoods(user?.id, pageParam);

  return (
    <>
      <div className="px-3">
        <GoodsList
          userId={user?.id}
          selectedCategory={null}
          fetchGoods={fetchGoods}
          pageType={type}
        />
      </div>
    </>
  );
};

export default Admin;
