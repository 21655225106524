import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { ReactComponent as OwnedIcon } from "assets/icons/bag.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as CollectionsIcon } from "assets/icons/collections.svg";
import { ReactComponent as GoodsIcon } from "assets/icons/goods.svg";
import { RoundedPlus as RoundedPlusIcon } from "assets/icons/RoundedPlus";
import Categories from "components/Categories";
import GoodsGrid from "components/GoodsGrid";
import PrimaryButton from "components/PrimaryButton";
import { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  getCollectionGoods,
  getOwnedGoods,
  getUserCollectionNames,
  getUserGoods,
} from "services/api";
import { useUser } from "UserContext";
import BottomModal from "../bottom_modal/BottomModal";

export const ColageGoodsModal = ({
  isOpen,
  onClose,
  addGood,
  selectedGoods,
}) => {
  const { user: currentUser } = useUser();
  const [selectedTab, setSelectedTab] = useState("goods");

  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedCollection, setSelectedCollection] = useState(0);

  const fetchGoods = useCallback(
    ({ pageParam = null }) => {
      // console.log("fetchGoods", userByLinkTag?.id, selectedCategory, pageParam);
      if (selectedTab === "goods")
        return getUserGoods(currentUser?.id, selectedCategory, pageParam);
      else if (selectedTab === "collections")
        return getCollectionGoods(selectedCollection, pageParam);
      else if (selectedTab === "owned")
        return getOwnedGoods(currentUser?.id, selectedCategory, pageParam);
    },
    [currentUser, selectedCategory, selectedTab, selectedCollection]
  );

  const { data: collections, isLoading: isLoadingCollections } = useQuery({
    queryFn: getUserCollectionNames,
    queryKey: ["userCollections"],
    enabled: isOpen,
  });

  useEffect(() => {
    console.log("collections", collections);
    if (collections && collections.length > 0) {
      setSelectedCollection(collections[0].id);
    }
  }, [collections]);

  const {
    data: goods,
    isPending,
    isFetching,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [
      "collageGoods" + selectedTab,
      currentUser?.id,
      selectedCategory,
      selectedCollection,
    ],
    queryFn: fetchGoods,
    getNextPageParam: (lastPage, pages) => lastPage?.offset || null,
    enabled: !!currentUser?.id && isOpen,
  });

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "0px 0px 500px 0px",
  });

  if (inView && hasNextPage && !isPending && !isFetching) {
    fetchNextPage();
  }

  return (
    <BottomModal
      containerClassName={" max-w-[550px]"}
      isOpen={isOpen}
      onClose={onClose}
      zIndex={21}
      bottomPadding={140}
      isNested={true}
    >
      <div>
        <div className="flex mb-4 mt-4 px-3">
          <button
            className={[
              "flex items-center p-4 px-3 mr-1 text-primary text-sm/none border border-grey-3 rounded-full transition-transform w-[90px]",
              "hover:border-grey-3-hover active:scale-[0.9875]",
              selectedTab === "goods"
                ? "text-white font-bold bg-primary border-primary hover:border-primary"
                : "",
            ].join(" ")}
            end
            onClick={() => setSelectedTab("goods")}
          >
            <GoodsIcon className="w-4 h-4 mr-1.5" />
            Goods
          </button>
          <button
            className={[
              "flex items-center p-4 px-3 mr-1 text-primary text-sm/none border border-grey-3 rounded-full transition-transform w-[125px]",
              "hover:border-grey-3-hover active:scale-[0.9875]",
              selectedTab === "collections" &&
                "text-white font-bold bg-primary border-primary hover:border-primary ",
            ].join(" ")}
            onClick={() => setSelectedTab("collections")}
          >
            <CollectionsIcon className="w-4 h-4 mr-1.5" />
            Collections
          </button>
          <button
            className={[
              "flex items-center p-4 px-3 mr-1 text-primary text-sm/none border border-grey-3 rounded-full transition-transform w-[94px]",
              "hover:border-grey-3-hover active:scale-[0.9875]",
              selectedTab === "owned" &&
                "text-white font-bold bg-primary border-primary hover:border-primary",
            ].join(" ")}
            onClick={() => setSelectedTab("owned")}
          >
            <OwnedIcon className="w-4 h-4 mr-1" />
            Owned
          </button>
        </div>
        {selectedTab === "collections" ? (
          <div className="items-center mx-auto text-center  max-w-full  mb-6 flex-nowrap flex justify-start overflow-x-scroll w-full pl-3">
            {collections.map((collection) => (
              <button
                key={collection.id}
                className={`inline-block text-nowrap px-4 py-2 mb-3 mr-2 text-sm/tight bg-grey-4 rounded-full hover:filter hover:brightness-95 active:scale-[0.9875] ${
                  selectedCollection === collection.id
                    ? "text-primary font-bold"
                    : "text-grey-2"
                }`}
                onClick={() => setSelectedCollection(collection.id)}
              >
                {collection.title}
              </button>
            ))}
          </div>
        ) : (
          <Categories
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            className={
              "mb-6 flex-nowrap flex justify-start overflow-x-scroll w-full pl-3"
            }
            expand={true}
          />
        )}
      </div>
      <div className="px-3">
        {!isLoading && goods?.pages[0].goods.length === 0 ? (
          <p className="flex items-center justify-center text-center h-[400px] pb-24">
            No goods :( <br />
            Please add some or collect them from explore{" "}
          </p>
        ) : (
          <div className="pb-[200px]">
            <GoodsGrid
              goods={goods}
              showOwner={false}
              showSkeleton={isLoading || !goods}
              isLoadingNextPage={isFetchingNextPage || hasNextPage}
              ref={ref}
              onClick={(good) => {
                addGood(good);
              }}
              addons={(good) =>
                selectedGoods.includes(good?.id) ? (
                  <div className="absolute flex top-4 left-4 z-10 rounded-full leading-none p-2 text-white bg-black-opacity backdrop-blur-md ">
                    <CheckIcon className="w-4 h-4 mr-1" /> Added
                  </div>
                ) : (
                  <div className="absolute flex top-4 left-4 z-10 text-white bg-primary rounded-full leading-none p-2">
                    <RoundedPlusIcon className="w-4 h-4 mr-1" /> Add
                  </div>
                )
              }
            />

            <div ref={ref} />
          </div>
        )}
      </div>
      <PrimaryButton
        className="shadow w-[95vw] max-w-[500px] fixed z-20 bottom-10 left-1/2 -translate-x-1/2 "
        onClick={onClose}
      >
        Continue
      </PrimaryButton>
    </BottomModal>
  );
};
