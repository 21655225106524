import { useEffect, useState } from "react";

const UserAvatar = ({
  size = "regular",
  image_url,
  username,
  className,
  showUsername = false,
}) => {
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    if (image_url?.includes("t.me")) {
      const image = new Image();
      image.src = image_url;
      image.onload = () => {
        if (image.height < 10) setImageSrc("");
        else setImageSrc(image_url);
      };

      image.onerror = () => setImageSrc("");
    } else if (image_url) {
      setImageSrc(
        `https://goodsend.fra1.cdn.digitaloceanspaces.com/user-avatars/images/${image_url}`
      );
    } else {
      setImageSrc("");
    }
  }, [image_url]);

  const avatarSize =
    size === "large"
      ? "72px"
      : size === "small"
      ? "40px"
      : size === "xs"
      ? "24px"
      : "56px";
  const fontSize =
    size === "large"
      ? "32px"
      : size === "small"
      ? "14px"
      : size === "xs"
      ? "12px"
      : "24px";

  return (
    <div className="flex flex-col items-center">
      <div
        className={`flex justify-center items-center rounded-full bg-grey-3 text-primary font-regular font-bold bg-cover bg-no-repeat bg-center ${className}`}
        style={{
          width: avatarSize,
          height: avatarSize,
          fontSize: fontSize,
          backgroundImage: `url(${imageSrc})`,
        }}
      >
        {imageSrc === "" && username[0]}
      </div>
      {showUsername && (
        <p
          className={`text-sm/none text-black-1 mt-2 mb-2 max-w-[88px] overflow-hidden text-ellipsis text-center`}
        >
          {username}
        </p>
      )}
    </div>
  );
};

export default UserAvatar;
