import { useMutation, useQueryClient } from "@tanstack/react-query";
import { collectGood } from "services/api";

export const useCollectMutation = (userId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (goodId) => collectGood(goodId),
    onMutate: async (goodId) => {
      return { goodId };
    },
    onSettled: ({ context }) => {
      queryClient.invalidateQueries({
        queryKey: ["userGoods", userId],
      });
      queryClient.invalidateQueries({
        queryKey: ["goodDetails"],
      });
    },
  });
};
