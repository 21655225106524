import { useMutation, useQueryClient } from "@tanstack/react-query";
import { authTelegram } from "services/api";

export const useTelegramAuthMutation = (telegramResponse) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (telegramResponse) => authTelegram(telegramResponse),
    onSuccess: (data) => {
      if (data.jwt) {
        console.log("redirecting to", data.jwt);
        window.location.href = "/last-step?jwt=" + data.jwt;
      }

      console.log("handling get current user");
      queryClient.invalidateQueries(["currentUserInfo"]);
    },
  });
};
