import { useQuery } from "@tanstack/react-query";
import { ReactComponent as RemoveIcon } from "assets/icons/remove_tiles.svg";
import UserAvatar from "components/UserAvatar";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { ReactComponent as CircleIcon } from "../assets/icons/circle.svg";
import { ReactComponent as StarIcon } from "../assets/icons/star.svg";
import { ReactComponent as UsersIcon } from "../assets/icons/users.svg";
import { getUserFollowees } from "../services/api";

const ExploreNav = ({ currentUser, removeMode, setRemoveMode }) => {
  const {
    data: currentUsersFollowees,
    isFetching: isCurrentUsersFolloweesPending,
  } = useQuery({
    queryKey: ["userFollowees", currentUser?.id],
    queryFn: () => getUserFollowees(currentUser?.id),
    enabled: !!currentUser?.id,
  });

  return (
    <>
      <nav className="flex whitespace-nowrap overflow-x-scroll pb-2 sm:pb-0 sm:absolute sm:left-1/2 sm:-translate-x-1/2 no-scrollbar px-3">
        <NavLink
          to="/explore/circle"
          className={({ isActive }) =>
            twMerge(
              `flex items-center w-[134px] ${
                currentUsersFollowees?.length > 0 ? "p-1" : "p-3"
              } mr-1 text-primary text-sm/none border border-grey-3 rounded-full hover:border-grey-3-hover active:scale-[0.9875] transition-transform`,
              isActive
                ? "text-white font-bold bg-primary border-primary hover:border-primary"
                : ""
            )
          }
        >
          <CircleIcon
            className={`w-4 h-4 ${
              currentUsersFollowees?.length > 0 ? "ml-2 mr-1" : " mr-1"
            }`}
          />
          {currentUsersFollowees?.length > 0 ? (
            <>
              {currentUsersFollowees?.slice(0, 2).map((followee, i) => (
                <UserAvatar
                  key={i}
                  size="small"
                  image_url={followee.photo_url}
                  username={followee.name}
                  className={`border-[1px] border-white ${
                    i === 0 ? "" : "-ml-3"
                  }`}
                />
              ))}
              {currentUsersFollowees.length - 2 > 0 && (
                <div
                  className={
                    "flex w-10 h-10 justify-center items-center rounded-full bg-grey-3 text-primary font-regular font-bold border-[1px] border-white -ml-3"
                  }
                >
                  +
                  {currentUsersFollowees.length - 2 > 99
                    ? "99"
                    : currentUsersFollowees.length - 2}
                </div>
              )}
            </>
          ) : (
            "Circle"
          )}
        </NavLink>
        <NavLink
          to="/explore/community"
          className={({ isActive }) =>
            twMerge(
              "flex items-center p-4 px-3 mr-1 text-primary text-sm/none border w-[122px] border-grey-3 rounded-full hover:border-grey-3-hover active:scale-[0.9875] transition-transform",
              isActive
                ? "text-white font-bold bg-primary border-primary hover:border-primary"
                : ""
            )
          }
        >
          <UsersIcon className="w-[14px] h-[14px] mr-1" />
          Community
        </NavLink>

        <NavLink
          to="/explore/picks"
          className={({ isActive }) =>
            twMerge(
              "flex items-center p-4 px-3 mr-1  text-primary text-sm/none border w-[133px] border-grey-3 rounded-full hover:border-grey-3-hover active:scale-[0.9875] transition-transform",
              isActive
                ? "text-white font-bold bg-primary border-primary hover:border-primary"
                : ""
            )
          }
        >
          <StarIcon className="w-4 h-4 mr-1" />
          Weekly Picks
        </NavLink>
        {currentUser && [5, 10, 12].indexOf(currentUser.id) !== -1 && (
          <div
            className={`sm:hidden cursor-pointer ml-2 p-4 rounded-full ${
              removeMode ? "bg-primary text-white" : "text-grey-2 bg-grey-4 "
            }`}
            onClick={() => setRemoveMode(!removeMode)}
          >
            <RemoveIcon className="w-6 h-6" />
          </div>
        )}
      </nav>
      {currentUser && [5, 10, 12].indexOf(currentUser.id) !== -1 && (
        <div
          className={`hidden sm:block absolute right-3 cursor-pointer ml-2 p-4 rounded-full ${
            removeMode ? "bg-primary text-white" : "text-grey-2 bg-grey-4 "
          }`}
          onClick={() => setRemoveMode(!removeMode)}
        >
          <RemoveIcon className="w-6 h-6" />
        </div>
      )}
    </>
  );
};

export default ExploreNav;
