import { useMutation, useQueryClient } from "@tanstack/react-query";
import { connectTelegram } from "services/api";

export const useTelegramConnectMutation = (telegramResponse) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: connectTelegram,
    onSuccess: () => {
      queryClient.invalidateQueries(["userSettings"]);
      queryClient.invalidateQueries(["currentUserInfo"]);
    },
  });
};
