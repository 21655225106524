import * as amplitude from "@amplitude/analytics-browser";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as CrossIcon } from "assets/icons/close.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { ReactComponent as SpinnerIcon } from "assets/icons/spinner.svg";
import PrimaryButton from "components/PrimaryButton";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getCurrentUserInfo, getUserInfo, updateUserInfo } from "services/api";

const LastStepTag = () => {
  useEffect(() => {
    amplitude.track(`onb_tag`);
  }, []);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    data: user,
    isLoading: isLoadingUserInfo,
    isError: isErrorUserInfo,
    isIdle: isIdleUserInfo,
  } = useQuery({
    queryKey: ["currentUserInfo"],
    queryFn: () => getCurrentUserInfo(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (user) {
      setValue("link_tag", user.link_tag);
    }
  }, [user, setValue]);

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: ({ formData }) => updateUserInfo(formData),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["currentUserInfo"] });
      queryClient.invalidateQueries({ queryKey: ["userInfo", user.link_tag] });
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("link_tag", data.link_tag);

    mutate({ formData });
    navigate("/last-step/photo");
  };

  const linkTag = watch("link_tag");
  const [debouncedTerm, setDebouncedTerm] = useState(linkTag);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(linkTag);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [linkTag]);

  const {
    data: linkTagResults,
    isLoading: isLinkTagPending,
    isError,
  } = useQuery({
    queryKey: ["userInfo", debouncedTerm],
    queryFn: () => getUserInfo(debouncedTerm),
    enabled:
      !!debouncedTerm &&
      debouncedTerm !== user.link_tag &&
      debouncedTerm.length > 2,
  });

  if (isErrorUserInfo) {
    return <div>Error loading user info</div>;
  }

  return (
    <div className="h-[calc(var(--vh)_*_100)] flex flex-col justify-between">
      <div className="flex flex-col items-center justify-center text-center h-full">
        <h3 className="font-bold text-sm text-grey-2 mb-2">How to Goodsend?</h3>
        <div className="font-bold text-xl text-center max-w-[290px] leading-7 mb-6">
          Pick your personal Goodsend handle
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full max-w-[500px]"
        >
          <div className="relative mb-6 mx-4">
            <input
              id="link_tag"
              type="text"
              className="bg-grey-4 text-lg p-4 rounded-full w-full ps-36 focus:outline focus:outline-1 outline-black"
              {...register("link_tag", {
                required: true,
                minLength: 3,
                maxLength: 36,
                pattern: /^[a-zA-Z0-9_]+$/,
              })}
            />
            <LinkIcon className="absolute top-[16px] left-4 text-grey-2 w-6 h-6" />
            <p className="absolute text-lg/none top-[19px] left-11 text-grey-2">
              goodsent.it/
            </p>
            {isLinkTagPending ? (
              <SpinnerIcon className="absolute top-[16px] right-4 text-primary w-6 h-6 animate-spin" />
            ) : (linkTagResults || errors.link_tag) &&
              linkTagResults?.link_tag !== user.link_tag ? (
              <CrossIcon className="absolute top-[16px] right-4 text-red-400 w-6 h-6" />
            ) : (
              <CheckIcon className="absolute top-[16px] right-4 text-primary w-6 h-6" />
            )}

            {errors.link_tag && (
              <p className="text-grey-2">
                Tag is required, must be between 3 and 36 characters long and
                can contain only letters, numbers and underscores.
              </p>
            )}
          </div>
          <PrimaryButton
            className="w-[95vw] max-w-[500px] fixed bottom-10 left-1/2 -translate-x-1/2 disabled:opacity-50 "
            type="submit"
            disabled={
              (linkTagResults && linkTagResults.link_tag !== user.link_tag) ||
              errors.link_tag
            }
          >
            Continue
          </PrimaryButton>
        </form>
      </div>
    </div>
  );
};

export default LastStepTag;
