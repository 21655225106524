import DOMPurify from "dompurify";
import parse, { domToReact } from "html-react-parser";
import { Link } from "react-router-dom";

const renderNode = (domNode) => {
  if (domNode.type === "tag" && domNode.name === "b") {
    return (
      <span className="font-bold text-primary">
        {domToReact(domNode.children, { replace: renderNode })}
      </span>
    );
  }
};

const renderNotification = (htmlContent) => {
  const sanitizedHtml = DOMPurify.sanitize(htmlContent);
  return parse(sanitizedHtml, { replace: renderNode });
};

const Notifcation = ({ notification, onClick }) => {
  return (
    <Link
      key={notification.id}
      to={notification.destination}
      className={`flex justify-between w-full py-3 border-b-[1px] border-grey-4 hover:filter hover:brightness-95 ${
        !notification.is_read ? "bg-grey-4" : ""
      }`}
      onClick={onClick}
    >
      <div className="flex flex-col justify-between max-w-[425px] text-left pr-16 pl-4">
        <p className="text-black text-lg mb-6">
          {renderNotification(notification.message)}
        </p>
        <span className="text-sm text-grey-2">{notification.created_at}</span>
      </div>
      <div
        style={{
          backgroundColor: `#${notification.good_image_bg_color}`,
          backgroundImage: notification.good_image_url
            ? `url(https://goodsend.fra1.cdn.digitaloceanspaces.com/main-space/images/${notification.good_image_url})`
            : "",
        }}
        className={`h-[100px] min-w-[80px] rounded-xl mr-4 bg-center bg-no-repeat ${
          notification.good_image_type === 2 ? "bg-cover" : "bg-contain"
        }`}
      ></div>
    </Link>
  );
};

export default Notifcation;
