import { ReactComponent as CropCorner } from "assets/icons/crop-corner.svg";
import { useCallback, useEffect, useRef, useState } from "react";

const ImageCropper = ({ imageSrc, onCrop, onImageChange }) => {
  const [cropBox, setCropBox] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const imgRef = useRef(null);
  const canvasRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const cropBoxRef = useRef(cropBox);

  const handleImageLoad = () => {
    const img = imgRef.current;
    if (img) {
      const initialCropBox = {
        x: 0,
        y: 0,
        width: img.width,
        height: img.height,
      };
      setCropBox(initialCropBox);
      cropBoxRef.current = initialCropBox;
      // updatePreview(initialCropBox);
    }
  };

  useEffect(() => {
    const img = imgRef.current;
    if (img && img.complete) {
      handleImageLoad();
    }
  }, []);

  const preventDefault = (e) => {
    e.preventDefault();
  };

  const handleStart = (e) => {
    e.preventDefault();
    const startX = e.clientX || e.touches[0].clientX;
    const startY = e.clientY || e.touches[0].clientY;
    const startBox = { ...cropBoxRef.current };
    const isResizing = e.target.dataset.resize;

    // Prevent default touch behavior
    document.body.style.overflow = "hidden";
    document.addEventListener("touchmove", preventDefault, { passive: false });

    const handleMove = (e) => {
      const clientX = e.clientX || e.touches[0].clientX;
      const clientY = e.clientY || e.touches[0].clientY;
      const deltaX = clientX - startX;
      const deltaY = clientY - startY;

      if (isResizing) {
        const newBox = { ...startBox };

        switch (isResizing) {
          case "top-left":
            newBox.width = Math.min(
              Math.max(50, startBox.width - deltaX),
              imgRef.current.width - startBox.x
            );
            newBox.height = Math.min(
              Math.max(50, startBox.height - deltaY),
              imgRef.current.height - startBox.y
            );
            newBox.x = Math.min(
              Math.max(0, startBox.x + deltaX),
              imgRef.current.width - newBox.width
            );
            newBox.y = Math.min(
              Math.max(0, startBox.y + deltaY),
              imgRef.current.height - newBox.height
            );
            break;
          case "top-right":
            newBox.y = Math.max(0, startBox.y + deltaY);
            newBox.width = Math.max(50, startBox.width + deltaX);
            newBox.height = Math.max(50, startBox.height - deltaY);
            break;
          case "bottom-left":
            newBox.x = Math.max(0, startBox.x + deltaX);
            newBox.width = Math.max(50, startBox.width - deltaX);
            newBox.height = Math.max(50, startBox.height + deltaY);
            break;
          case "bottom-right":
            newBox.width = Math.min(
              Math.max(50, startBox.width + deltaX),
              imgRef.current.width - startBox.x
            );
            newBox.height = Math.min(
              Math.max(50, startBox.height + deltaY),
              imgRef.current.height - startBox.y
            );
            break;
          default:
            break;
        }
        setCropBox(newBox);
        cropBoxRef.current = newBox;
        // updatePreview(newBox);
      } else {
        const newBox = {
          ...startBox,
          x: Math.max(
            0,
            Math.min(startBox.x + deltaX, imgRef.current.width - startBox.width)
          ),
          y: Math.max(
            0,
            Math.min(
              startBox.y + deltaY,
              imgRef.current.height - startBox.height
            )
          ),
        };
        setCropBox(newBox);
        cropBoxRef.current = newBox;
        // updatePreview(newBox);
      }
    };

    const handleEnd = () => {
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("mouseup", handleEnd);
      document.removeEventListener("touchmove", handleMove);
      document.removeEventListener("touchend", handleEnd);

      // Re-enable default touch behavior
      document.body.style.overflow = "";
      document.removeEventListener("touchmove", preventDefault);
      handleCrop();
    };

    document.addEventListener("mousemove", handleMove);
    document.addEventListener("mouseup", handleEnd);
    document.addEventListener("touchmove", handleMove);
    document.addEventListener("touchend", handleEnd);
  };

  const updatePreview = useCallback((cropBox) => {
    const canvas = previewCanvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = imgRef.current;
    const dpr = window.devicePixelRatio || 1;

    canvas.width = cropBox.width * dpr;
    canvas.height = cropBox.height * dpr;
    canvas.style.width = `${cropBox.width}px`;
    canvas.style.height = `${cropBox.height}px`;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.resetTransform();
    ctx.scale(dpr, dpr);

    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;

    ctx.drawImage(
      img,
      cropBox.x * scaleX,
      cropBox.y * scaleY,
      cropBox.width * scaleX,
      cropBox.height * scaleY,
      0,
      0,
      cropBox.width,
      cropBox.height
    );
  }, []);

  const handleCrop = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = imgRef.current;
    const dpr = window.devicePixelRatio || 1;

    canvas.width = cropBox.width * dpr;
    canvas.height = cropBox.height * dpr;
    canvas.style.width = `${cropBox.width}px`;
    canvas.style.height = `${cropBox.height}px`;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.resetTransform();
    ctx.scale(dpr, dpr);

    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;

    ctx.drawImage(
      img,
      cropBox.x * scaleX,
      cropBox.y * scaleY,
      cropBox.width * scaleX,
      cropBox.height * scaleY,
      0,
      0,
      cropBox.width,
      cropBox.height
    );

    canvas.toBlob((blob) => {
      const file = new File([blob], "cropped.webp", { type: "image/webp" });
      onCrop(file);
    }, "image/webp");
  }, [cropBox, onCrop]);

  return (
    <div className="relative flex flex-col items-center justify-center p-4 bg-grey-4 rounded-3xl">
      <div className="relative w-full max-w-xl">
        <img
          src={imageSrc}
          alt="To be cropped"
          ref={imgRef}
          onLoad={handleImageLoad}
          className="w-full"
        />
        <button
          className="absolute z-10 right-4 bottom-4 bg-primary rounded-full px-4 py-2 text-white text-sm hover:filter hover:brightness-95 active:scale-[0.9875]"
          onClick={onImageChange}
        >
          Change Image
        </button>
        <div
          className="absolute border-4 border-primary/50 rounded-3xl"
          style={{
            top: cropBox.y,
            left: cropBox.x,
            width: cropBox.width,
            height: cropBox.height,
            touchAction: "none", // Disable browser handling of all panning and zooming gestures
          }}
          onMouseDown={handleStart}
          onTouchStart={handleStart}
        >
          <CropCorner
            className="absolute -top-1.5 -left-1.5 w-8 h-8 text-primary cursor-nwse-resize"
            data-resize="top-left"
          />
          <CropCorner
            className="absolute -top-1.5 -right-1.5 w-8 h-8 rotate-90 text-primary cursor-nesw-resize"
            data-resize="top-right"
          />
          <CropCorner
            className="absolute -bottom-1.5 -left-1.5 w-8 h-8 -rotate-90 text-primary cursor-nesw-resize"
            data-resize="bottom-left"
          />
          <CropCorner
            className="absolute -bottom-1.5 -right-1.5 w-8 h-8 rotate-180 text-primary cursor-nesw-resize"
            data-resize="bottom-right"
          />
        </div>
      </div>

      <canvas ref={canvasRef} className="hidden"></canvas>
      {/* <div className="mt-4 hidden">
        <h2 className="text-xl">Crop Preview:</h2>
        <canvas ref={previewCanvasRef} className="border"></canvas>
      </div> */}
    </div>
  );
};

export default ImageCropper;
