import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as ShareIcon } from "assets/icons/share.svg";
import MultishareImage from "assets/images/intro/multishare.gif";
import ShareImage from "assets/images/intro/share.gif";
import { Link } from "react-router-dom";

export const SaveViaShareSecond = () => {
  return (
    <div className="h-[calc(var(--vh)_*_100)] flex flex-col justify-between">
      <div className="flex w-full h-full justify-center">
        <img
          src={MultishareImage}
          className="rounded-3xl max-h-[calc(50_*_var(--vh))] self-center"
          alt="how to goodsend"
        />
      </div>
      <div className="flex flex-col items-center justify-center text-center">
        <div className="font-bold text-xl text-center max-w-[270px]">
          Now save your goods from any sites or apps
        </div>
        <p className="text-lg max-w-[330px] text-grey-1 mt-4 leading-6">
          Find inspiring objects and goods you like, tap{" "}
          <span>
            <ShareIcon className="inline w-5 h-5 mb-1 mr-1" />
            share
          </span>{" "}
          and pick <span className="text-primary font-black">Goodsend</span>
        </p>
        <Link
          to="/"
          className="bg-primary self-center text-white rounded-full text-lg py-4 mb-12 mt-8 text-center w-full max-w-[350px]"
        >
          Done
        </Link>
      </div>
    </div>
  );
};

export const SaveViaShareFirst = () => {
  return (
    <div className="h-[calc(var(--vh)_*_100)] flex flex-col justify-between">
      <div className="flex w-full h-full justify-center">
        <img
          src={ShareImage}
          className="rounded-3xl max-h-[calc(50_*_var(--vh))] self-center"
          alt="how to goodsend"
        />
      </div>
      <div className="mt-14 flex flex-col items-center justify-center text-center">
        <div className="font-bold text-xl text-center max-w-[290px] leading-7">
          Enable <span className="text-primary">One Tap Save</span> via iOS
          share menu
        </div>
        <p className="text-lg max-w-[340px] text-grey-1 mt-4 leading-6">
          Open Safari > tap <ShareIcon className="inline -mt-1" /> share >
          “More” > tap Edit > tap <PlusIcon className="inline -mt-1" /> for{" "}
          <span className="text-primary font-black">Goodsend</span> > tap Done
        </p>
        <Link
          to="/intro/save-via-share/second"
          className="bg-primary self-center text-white rounded-full text-lg py-4 mb-12 mt-8 text-center w-full max-w-[350px]"
        >
          Continue
        </Link>
      </div>
    </div>
  );
};
