import { useInfiniteQuery } from "@tanstack/react-query";
export const useFetchGoods = (
  userId,
  selectedCategory,
  fetchGoods,
  queryName,
  enabled,
  removeMode = false
) => {
  return useInfiniteQuery({
    queryKey: [queryName, userId, selectedCategory, removeMode],
    queryFn: fetchGoods,
    getNextPageParam: (lastPage, pages) => lastPage?.offset || null,
    enabled,
  });
};
