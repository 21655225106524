import * as amplitude from "@amplitude/analytics-browser";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { ReactComponent as LeftArrow } from "assets/icons/left_arrow.svg";
import UserAvatar from "components/UserAvatar";
import { useGoodModal } from "GoodModalContext";
import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Link, useLocation } from "react-router-dom";
import {
  fetchGoodsSearchResults,
  fetchImageSearchResults,
  fetchSearchResults,
  getTrendingPeople,
} from "services/api";
import { twMerge } from "tailwind-merge";
import { useUser } from "UserContext";
import { ReactComponent as CameraIcon } from "../assets/icons/camera.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/close_filled.svg";
import { ReactComponent as WarningIcon } from "../assets/icons/round_warning.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/search.svg";
import GoodsGrid from "./GoodsGrid";
import ImageCropper from "./ImageCropper";
import ScrollToTopButton from "./ScrollToTopButton";

function containsCyrillic(text) {
  const cyrillicPattern = /[\u0400-\u04FF]/;
  return cyrillicPattern.test(text);
}

const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

const SearchFilters = ({}) => {
  const [focused, setFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchImage, setSearchImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageName, setImageName] = useState(null);
  const { openGoodModal } = useGoodModal();
  const [onlyGoods, setOnlyGoods] = useState(false);
  const { user: currentUser } = useUser();

  const query = useQueryParams();

  useEffect(() => {
    const goodId = query.get("good_id");

    if (goodId && currentUser?.id) {
      openGoodModal(goodId, null, currentUser?.id);
    }
  }, [currentUser?.id]);

  // Debouncing logic to reduce the number of requests
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);
  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const {
    data: resultsPeople,
    isLoading: isLoadingPeople,
    isError,
  } = useQuery({
    queryKey: ["searchResults", debouncedTerm],
    queryFn: () => fetchSearchResults(debouncedTerm),
    enabled: !!debouncedTerm,
  });

  const {
    data: resultsImage,
    isLoading: isLoadingImage,
    isPending: isPendingImage,
    isFetching: isFetchingImage,
    hasNextPage: hasNextPageImage,
    isFetchingNextPage: isFetchingNextPageImage,
    fetchNextPage: fetchNextPageImage,
  } = useInfiniteQuery({
    queryKey: ["searchResultsImage", imageName],
    queryFn: ({ pageParam = { offset: 0, imageEmbedding: null } }) =>
      fetchImageSearchResults(
        searchImage,
        pageParam.offset,
        pageParam.imageEmbedding
      ),
    getNextPageParam: (lastPage, pages) => {
      return {
        offset: lastPage?.offset || null,
        imageEmbedding: lastPage?.embedding || pages[0]?.embedding || null,
      };
    },
    enabled: !!searchImage,
  });

  const {
    data: resultsGoods,
    isPending: isPendingGoods,
    isFetching: isFetchingGoods,
    isLoading: isLoadingGoods,
    hasNextPage: hasNextPageGoods,
    isFetchingNextPage: isFetchingNextPageGoods,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ["goodsSearchResults", debouncedTerm],
    queryFn: ({ pageParam = { offset: 0, queryEmbedding: null } }) =>
      fetchGoodsSearchResults(
        debouncedTerm,
        pageParam.offset,
        pageParam.queryEmbedding
      ),
    getNextPageParam: (lastPage, pages) => {
      return {
        offset: lastPage?.offset || null,
        queryEmbedding: lastPage?.embedding || pages[0]?.embedding || null,
      };
    },
    enabled: !!debouncedTerm,
  });

  const { data: trendingPeople, isLoading: isLoadingTrending } = useQuery({
    queryKey: ["trendingPeople"],
    queryFn: getTrendingPeople,
  });

  const imageInputRef = React.useRef(null);

  const onFileChange = (file) => {
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      setSearchImage(file);
      setImageName(file.name);
    }
  };

  const onCrop = (croppedImage) => {
    setImageName(URL.createObjectURL(croppedImage));
    setSearchImage(croppedImage);
  };

  const ogTrendingSearches = [
    {
      name: (
        <>
          Cargo
          <br />
          Pants
        </>
      ),
      query: "cargo pants",
      photo_url:
        "https://goodsend.fra1.cdn.digitaloceanspaces.com/search-images/cargo_pants.png",
    },
    {
      name: "Hoodies",
      query: "hoodies",
      photo_url:
        "https://goodsend.fra1.cdn.digitaloceanspaces.com/search-images/hoodie.png",
    },
    {
      name: (
        <>
          T-Shirts <br /> with Prints
        </>
      ),
      query: "t-shirts with prints",
      photo_url:
        "https://goodsend.fra1.cdn.digitaloceanspaces.com/search-images/t-shirt.png",
    },
    {
      name: (
        <>
          Skin Care
          <br />
          Products
        </>
      ),
      query: "skin care products",
      photo_url:
        "https://goodsend.fra1.cdn.digitaloceanspaces.com/search-images/skin_care1.png",
    },
    {
      name: (
        <>
          Workout
          <br />
          Clothes
        </>
      ),
      query: "workout clothes",
      photo_url:
        "https://goodsend.fra1.cdn.digitaloceanspaces.com/search-images/workout.png",
    },
    {
      name: <>Tote Bags</>,
      query: "tote bags",
      photo_url:
        "https://goodsend.fra1.cdn.digitaloceanspaces.com/search-images/tote_bags.png",
    },
    {
      name: (
        <>
          Interior
          <br />
          Objects
        </>
      ),
      query: "interior objects",
      photo_url:
        "https://goodsend.fra1.cdn.digitaloceanspaces.com/search-images/interior.png",
    },
    {
      name: "Lingerie",
      query: "lingerie",
      photo_url:
        "https://goodsend.fra1.cdn.digitaloceanspaces.com/search-images/lingerie.png",
    },
    {
      name: (
        <>
          Aroma
          <br />
          Candles
        </>
      ),
      query: "aroma candles",
      photo_url:
        "https://goodsend.fra1.cdn.digitaloceanspaces.com/search-images/aroma.png",
    },
    {
      name: "Sneakers",
      query: "sneakers",
      photo_url:
        "https://goodsend.fra1.cdn.digitaloceanspaces.com/search-images/sneakers1.png",
    },
    {
      name: (
        <>
          Dining &<br />
          Kitchen
        </>
      ),
      query: "dining & kitchen",
      photo_url:
        "https://goodsend.fra1.cdn.digitaloceanspaces.com/search-images/dining1.png",
    },
    {
      name: (
        <>
          Wrist
          <br />
          Watches
        </>
      ),
      query: "wrist watches",
      photo_url:
        "https://goodsend.fra1.cdn.digitaloceanspaces.com/search-images/watches.png",
    },
  ];
  const [trendingSearches, setTrendingSearches] = useState([]);
  useEffect(() => {
    setTrendingSearches(ogTrendingSearches.sort(() => Math.random() - 0.5));
  }, []);

  const { ref, inView: inViewSimilar } = useInView({
    threshold: 0,
    rootMargin: "0px 0px 500px 0px",
  });

  if (
    inViewSimilar &&
    hasNextPageGoods &&
    !isPendingGoods &&
    !isFetchingGoods
  ) {
    fetchNextPage();
  }
  if (
    inViewSimilar &&
    hasNextPageImage &&
    !isPendingImage &&
    !isFetchingImage
  ) {
    fetchNextPageImage();
  }
  const Scrollable = useRef(null);
  const SearchForm = useRef(null);
  const SearchInput = useRef(null);

  useEffect(() => {
    document.scrollingElement.scrollTop = 0;
    Scrollable.current.scrollTo(0, 0);
    if (window.innerHeight !== window.visualViewport.height) {
      SearchForm.current.style.position = "fixed";
      SearchForm.current.style.bottom = "auto";
      SearchForm.current.style.transform = "none";

      const fixedElement = SearchForm.current;
      const fixedElementHeight = fixedElement.offsetHeight;
      const docHeight =
        document.documentElement.scrollHeight + fixedElementHeight; // Total height of the document (padding to be added manually)
      let fixedElementBottom =
        document.documentElement.scrollTop + window.visualViewport.height;

      // Limit the position to the end of the document, otherwise Safari lets the user scroll without end
      if (fixedElementBottom > docHeight) {
        fixedElementBottom = docHeight;
      }
      SearchForm.current.style.top =
        fixedElementBottom - fixedElementHeight + 10 + "px";
    }
  }, [searchTerm, Scrollable, SearchForm]);

  useEffect(() => {
    const handleScroll = () => {
      SearchInput.current.blur();
    };

    Scrollable.current.addEventListener("touchstart", handleScroll);
    return () => {
      if (Scrollable.current)
        Scrollable.current.removeEventListener("touchstart", handleScroll);
    };
  }, [SearchInput, Scrollable]);

  const handleBlur = () => {
    SearchForm.current.style.position = "fixed";
    SearchForm.current.style.bottom = "revert-layer";
    SearchForm.current.style.transform = "none";
    SearchForm.current.style.top = "auto";
  };

  const onTrendingSearchClick = (query) => {
    document.scrollingElement.scrollTop = 0;
    setSearchTerm(query);
    setOnlyGoods(true);
    setFocused(true);
    amplitude.track("search_trending_clicked", { search_query: query });
  };

  return (
    <>
      <form
        className={`fixed left-0 px-4 z-10 bottom-24 sm:bottom-28 flex justify-center w-full
            ${!imageName ? "visible" : "hidden"}
            `}
        style={{ paddingBottom: "env(safe-area-inset-bottom)" }}
        onSubmit={(e) => e.preventDefault()}
        ref={SearchForm}
      >
        <div className="relative flex-grow max-w-[480px]">
          <div className="absolute z-[5] inset-y-0 start-0 flex items-center ps-4 pointer-events-none xl:w-14 xl:p-0 xl:flex xl:justify-center">
            <SearchIcon className="w-6 h-6" />
          </div>
          {focused && searchTerm !== "" && (
            <button
              className=" absolute z-[5] right-0 flex justify-center items-center w-14 h-14"
              onClick={() => {
                console.log("clicked");
                setFocused(false);
                setSearchTerm("");
                console.log("clicked");
                setImagePreview(null);
                setSearchImage(null);
                setImageName(null);
                setOnlyGoods(false);
                handleBlur();
                if (imageInputRef.current) imageInputRef.current.value = "";
              }}
            >
              <CloseIcon className="text-grey-2 w-6 h-6" />
            </button>
          )}
          <input
            autoComplete="off"
            type="search"
            ref={SearchInput}
            className={twMerge(
              "shadow-[0_1px_12px_rgba(0,_0,_0,_0.35)] w-full block h-14 p-4 ps-12 text-lg/none text-gray-900 rounded-full bg-white focus:outline focus:outline-1 outline-offset-0 outline-primary ",
              focused && "xl:w-full xl:cursor-text"
            )}
            placeholder="Search people, goods, brands..."
            required
            onFocus={() => {
              setFocused(true);
              amplitude.track("search_clicked");
            }}
            value={searchTerm}
            onBlur={() => {
              handleBlur();
              if (searchTerm !== "")
                amplitude.track("search_blurred", { search_query: searchTerm });
            }}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Prevent Enter key from clearing the input
              }
            }}
          />
        </div>

        <label
          htmlFor="image"
          className={`flex-shrink-0 shadow-[0_1px_12px_0px_rgba(0,_0,_0,_0.35)] cursor-pointer
              flex items-center justify-center w-14 h-14 rounded-full overflow-hidden ml-2 bg-primary
              ${!(focused && searchTerm !== "") ? "visible" : "hidden"}
              hover:filter hover:brightness-95 active:scale-[0.9875]`}
          onClick={() => {
            amplitude.track("search_by_image_clicked");
          }}
        >
          <CameraIcon className="text-white w-5 h-5" />
        </label>

        <input
          id="image"
          type="file"
          ref={imageInputRef}
          // {...register("image")}
          onChange={(e) => onFileChange(e.target.files[0])}
          className="hidden"
        />
      </form>

      <div className="pb-24 h-[100vh] overflow-y-scroll" ref={Scrollable}>
        {imagePreview && (
          <>
            <div className="flex justify-between align-center mb-4 p-2 mt-2">
              <LeftArrow
                onClick={() => {
                  setImagePreview(null);
                  setSearchImage(null);
                  setImageName(null);
                  if (imageInputRef.current) imageInputRef.current.value = "";
                }}
                className="absolute text-black flex-grow-0"
              />
              <h3 className="text-lg font-bold flex-grow text-center">
                Image Search
              </h3>
            </div>
            <ImageCropper
              imageSrc={imagePreview}
              onCrop={onCrop}
              onImageChange={() => {
                imageInputRef.current.click();
              }}
            />
            <div className="flex items-center justify-center text-grey-2 text-sm mt-2 pb-2 border-b border-b-grey-3">
              <WarningIcon className="mr-1.5" /> Highlight the area of the item
              you want to find
            </div>
          </>
        )}
        {searchTerm === "" && !imagePreview ? (
          <div className="text-center">
            <p className="px-3 mt-5 text-primary font-bold text-sm">
              Trending People
            </p>
            <div className="grid gap-x-2 gap-y-1 grid-cols-[repeat(auto-fit,_minmax(80px,_max-content))] justify-center py-4">
              {trendingPeople
                ? trendingPeople.map((trendingPerson) => (
                    <Link
                      to={`/${trendingPerson.link_tag}`}
                      key={trendingPerson.id}
                    >
                      <UserAvatar
                        size="large"
                        image_url={trendingPerson.photo_url}
                        username={trendingPerson.name}
                        showUsername={true}
                        className={"m-2"}
                      />
                    </Link>
                  ))
                : Array(8)
                    .fill(0)
                    .map((_, i) => (
                      <UserAvatar
                        size="large"
                        key={i}
                        image_url={""}
                        username={""}
                        showUsername={false}
                        className={"m-2"}
                      />
                    ))}
            </div>
            <p className="px-3 mt-2 text-primary font-bold text-sm">
              Trending Searches
            </p>
            <div className="flex flex-wrap py-4 justify-center pb-20 max-w-[920px] mx-auto">
              {trendingSearches.map((search) => (
                <button
                  key={search.query}
                  onClick={(e) => {
                    onTrendingSearchClick(search.query);
                  }}
                  className="flex relative text-left p-3 rounded-lg bg-grey-4 mr-2 mb-2 w-full h-[100px] overflow-hidden hover:filter hover:brightness-95 active:scale-[0.9875]"
                  style={{
                    flex: "1 1 160px",
                    maxWidth: "180px",
                  }}
                >
                  <p className="relative z-[5]">{search.name}</p>
                  <div
                    className="absolute w-full h-full -bottom-0 right-0 bg-contain bg-no-repeat bg-right-bottom z-[4]"
                    style={{ backgroundImage: `url(${search.photo_url})` }}
                  ></div>
                </button>
              ))}
            </div>
          </div>
        ) : (
          <>
            {searchTerm ? (
              <>
                {!onlyGoods &&
                  (isLoadingPeople || resultsPeople?.length > 0) && (
                    <>
                      <p className="flex justify-center mt-5 text-primary font-bold text-sm">
                        People
                      </p>
                      {isLoadingPeople || !resultsPeople ? (
                        <div className="flex justify-center py-4">
                          {Array(5)
                            .fill(0)
                            .map((_, i) => (
                              <UserAvatar
                                size="large"
                                key={i}
                                image_url={""}
                                username={""}
                                showUsername={false}
                                className={"m-2"}
                              />
                            ))}
                        </div>
                      ) : resultsPeople?.length > 0 ? (
                        <>
                          <div className="grid gap-x-2 gap-y-1 grid-cols-[repeat(auto-fit,_minmax(80px,_max-content))] justify-center py-4">
                            {resultsPeople.map((result) => (
                              <Link to={`/${result.link_tag}`}>
                                <UserAvatar
                                  size="large"
                                  key={result.id}
                                  image_url={result.photo_url}
                                  username={result.name}
                                  showUsername={true}
                                  className={"m-2"}
                                />
                              </Link>
                            ))}
                          </div>
                        </>
                      ) : (
                        <p className="text-center px-3 mt-2 text-grey-2">
                          No people found
                        </p>
                      )}
                    </>
                  )}
                <p className="flex justify-center text-primary font-bold text-sm mt-4">
                  Goods
                </p>
                {containsCyrillic(searchTerm) && (
                  <p className="text-sm/tight text-grey-2 text-center mt-1 mb-6">
                    Search in English for best results.
                    <br />
                    Example: 'graphic t-shirt' or 'brown bag'
                  </p>
                )}
                <div className="mt-4">
                  <GoodsGrid
                    showSkeleton={isLoadingGoods || !resultsGoods}
                    showOwner={false}
                    ref={ref}
                    isLoadingNextPage={
                      isFetchingNextPageGoods || hasNextPageGoods
                    }
                    openGoodModal={(goodId) => {
                      openGoodModal(goodId, null, null, "search_by_text");
                    }}
                    // openCollectionModal={(collectionId) => {
                    //   openCollectionModal(collectionId, true);
                    //   navigate(`?collection_id=${collectionId}`, {
                    //     replace: true,
                    //   });
                    // }}
                    goods={resultsGoods}
                  />
                  <div ref={ref}></div>
                </div>
              </>
            ) : searchImage ? (
              <>
                <div className="p-4">
                  <GoodsGrid
                    showSkeleton={isLoadingImage || !resultsImage}
                    showOwner={false}
                    ref={ref}
                    isLoadingNextPage={
                      isFetchingNextPageImage || hasNextPageImage
                    }
                    openGoodModal={(goodId) => {
                      openGoodModal(goodId, null, null, "search_by_image");
                    }}
                    // openCollectionModal={(collectionId) => {
                    //   openCollectionModal(collectionId, true);
                    //   navigate(`?collection_id=${collectionId}`, {
                    //     replace: true,
                    //   });
                    // }}
                    goods={resultsImage}
                  />
                  <div ref={ref}></div>
                </div>
              </>
            ) : (
              <p className="px-3 mt-5 text-grey-1">No goods found</p>
            )}
          </>
        )}

        <ScrollToTopButton scrollRef={Scrollable} baseBottom={180} />
      </div>
    </>
  );
};

export default SearchFilters;
