import { ReactComponent as CloseFilledIcon } from "assets/icons/close_filled.svg";
import { ReactComponent as ColorPickerIcon } from "assets/icons/color_picker.svg";
import { ReactComponent as EraserIcon } from "assets/icons/eraser.svg";
import { ReactComponent as PencilIcon } from "assets/icons/pencil.svg";
import { RoundedPlus as RoundedPlusIcon } from "assets/icons/RoundedPlus";
import { ReactComponent as TextIcon } from "assets/icons/text.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as UndoIcon } from "assets/icons/undo.svg";
import { ReactComponent as UploadImageIcon } from "assets/icons/upload_image.svg";
import BottomModal from "components/modals/bottom_modal/BottomModal";
import { ColageGoodsModal } from "components/modals/collage_modal/CollageGoodsModal";
import * as fabric from "fabric";
import { useEffect, useRef, useState } from "react";
import { removeImageBackground } from "services/api";
import { CollagePostModal } from "./CollagePostModal";

function getContrastingColor(hex) {
  console.log("getContrastingColor", hex);
  if (hex === "transparent") return "#000000";
  // Remove the hash symbol if present
  hex = hex.replace("#", "");

  // Convert shorthand hex (e.g., "03F") to full form ("0033FF")
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("");
  }

  // Parse the red, green, and blue components
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate the relative luminance using the WCAG formula
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  console.log("getContrastingColor", luminance);
  return luminance > 0.5 ? "#000000cc" : "#FFFFFFcc";
}

export const CollageCreateModal = ({ isOpen, onClose, collageDetails }) => {
  const canvasRef = useRef(null); // Reference to the canvas DOM element
  const fabricCanvas = useRef(null); // Reference to the Fabric.js canvas instance
  const [selectedGoods, setSelectedGoods] = useState([]);
  const [isCollageGoodsOpen, setIsCollageGoodsOpen] = useState(false);
  const [selectedTextObject, setSelectedTextObject] = useState(null); // State for selected text object
  const [selectedImageObject, setSelectedImageObject] = useState(null); // State for selected image object
  const [textWeight, setTextWeight] = useState(400); // State for text weight
  const [backgroundColor, setBackgroundColor] = useState("transparent"); // State for canvas background color
  const [isColorPickerMode, setIsColorPickerMode] = useState(false); // State for color picker mode
  const [lastUsedColors, setLastUsedColors] = useState([
    "#E5E5E5",
    "#868686",
    "#303030",
  ]); // State for last used colors
  const [lastUsedColor, setLastUsedColor] = useState(""); // State for last used color
  const [isClosePopupOpen, setIsClosePopupOpen] = useState(false); // State for close popup
  const [isPostCollageOpen, setIsPostCollageOpen] = useState(false); // State for post collage modal
  const [collagePreview, setCollagePreview] = useState(null); // State for collage preview
  const canvasStates = useRef([]); // Array to store canvas states for undo/redo
  let isRestoring = useRef(false); // Flag to prevent saving state when restoring

  // Refs for guide lines
  const verticalGuideLine = useRef(null);
  const horizontalGuideLine = useRef(null);

  const textWeights = [400, 500, 800, 900];

  const [textColor, setTextColor] = useState("#000000");
  const [aspectRatio, setAspectRatio] = useState(9 / 16);

  useEffect(() => {
    const wrapper = document.getElementById("canvas-wrapper");

    // if (!fabricCanvas.current)
    fabricCanvas.current = new fabric.Canvas(canvasRef.current, {
      width: wrapper.clientHeight * aspectRatio,
      height: wrapper.clientHeight,
      backgroundColor: backgroundColor,
      renderOnAddRemove: true,
      enablePointerEvents: true,
    });

    const resizeCanvas = () => {
      let canvasWidth, canvasHeight;

      canvasWidth = wrapper.clientHeight * aspectRatio;
      canvasHeight = wrapper.clientHeight;

      if (canvasWidth > wrapper.clientWidth) {
        canvasWidth = wrapper.clientWidth;
        canvasHeight = (wrapper.clientWidth * 1) / aspectRatio;
      }
      console.log("resizeCanvas");
      console.log(canvasWidth, canvasHeight);
      fabricCanvas.current.setDimensions({
        width: canvasWidth,
        height: canvasHeight,
      });
    };
    resizeCanvas();
    fabricCanvas.current.renderAll();
    window.addEventListener("resize", resizeCanvas);
    fabricCanvas.current.preserveObjectStacking = true;

    if (collageDetails) {
      const canvasJSON = JSON.parse(collageDetails["json"]);
      const originalCanvasWidth = canvasJSON.width; // Assuming width is stored in the JSON
      const originalCanvasHeight = canvasJSON.height; // Assuming height is stored in the JSON

      const newCanvasWidth = fabricCanvas.current.width;
      const newCanvasHeight = fabricCanvas.current.height;

      const widthScaleFactor = newCanvasWidth / originalCanvasWidth;
      const heightScaleFactor = newCanvasHeight / originalCanvasHeight;

      // Use the smaller of the two to maintain the aspect ratio
      const scaleFactor = Math.min(widthScaleFactor, heightScaleFactor);
      delete canvasJSON.width;
      delete canvasJSON.height;
      canvasJSON.background = collageDetails["bg_color"];
      fabricCanvas.current.loadFromJSON(canvasJSON).then((e) => {
        fabricCanvas.current.getObjects().forEach((obj) => {
          obj.scaleX *= scaleFactor;
          obj.scaleY *= scaleFactor;
          console.log(
            `Scaling from scaleX: ${obj.scaleX} to ${obj.scaleX * scaleFactor}`
          );
          console.log(
            `Scaling from scaleY: ${obj.scaleY} to ${obj.scaleY * scaleFactor}`
          );

          // Adjust positions based on scaling factor
          obj.left *= scaleFactor;
          obj.top *= scaleFactor;
          console.log(
            `Scaling from left: ${obj.left} to ${obj.left * scaleFactor}`
          );
          console.log(
            `Scaling from top: ${obj.top} to ${obj.top * scaleFactor}`
          );

          obj.setCoords();
        });
        fabricCanvas.current.renderAll();
        console.log("collageDetails");
        console.log(collageDetails);
        console.log(JSON.parse(collageDetails["json"]));
        fabricCanvas.current.backgroundColor = collageDetails["bg_color"];
        setBackgroundColor(collageDetails["bg_color"]);
        resizeCanvas();
        fabricCanvas.current.renderAll();
        console.log(fabricCanvas.current);
        saveCanvasState("initialization from details", true);
      });
    } else saveCanvasState("initialization", true);

    fabricCanvas.current.on("selection:updated", onObjectSelected);
    fabricCanvas.current.on("selection:created", onObjectSelected);
    fabricCanvas.current.on("selection:cleared", () => {
      setSelectedTextObject(null); // Reset state if another object is selected
      setSelectedImageObject(null);
      setIsColorPickerMode(false);
    });
    fabricCanvas.current.on("object:moving", onObjectMoving);
    fabricCanvas.current.on("object:modified", onObjectModified);
    fabricCanvas.current.on("mouse:down", onMouseDown);
    fabricCanvas.current.on("mouse:up", onMouseUp); // Add mouse:up event

    // Event listeners for undo functionality
    fabricCanvas.current.on("object:added", onObjectAdded);
    fabricCanvas.current.on("object:removed", onObjectRemoved);

    // Add touch event listeners for pinch-to-zoom
    const canvasElement = canvasRef.current;
    let isScaling = false;
    let initialDistance = 0;
    let initialScaleX = 1;
    let initialScaleY = 1;
    let initialAngle = 0;
    let initialRotation = 0;
    let initialCenter = null;

    const handleTouchStart = (e) => {
      if (e.touches.length === 2) {
        e.preventDefault();
        isScaling = true;

        const [touch1, touch2] = e.touches;
        const dx = touch1.clientX - touch2.clientX;
        const dy = touch1.clientY - touch2.clientY;
        initialDistance = Math.sqrt(dx * dx + dy * dy);
        initialAngle = Math.atan2(dy, dx);

        const activeObject = fabricCanvas.current.getActiveObject();
        if (activeObject) {
          initialScaleX = activeObject.scaleX || 1;
          initialScaleY = activeObject.scaleY || 1;
          initialRotation = activeObject.angle || 0;
          const rect = canvasElement.getBoundingClientRect();
          initialCenter = {
            x:
              ((touch1.clientX + touch2.clientX) / 2 - rect.left) /
              fabricCanvas.current.getZoom(),
            y:
              ((touch1.clientY + touch2.clientY) / 2 - rect.top) /
              fabricCanvas.current.getZoom(),
          };
        }
      }
    };

    const handleTouchMove = (e) => {
      if (isScaling && e.touches.length === 2) {
        e.preventDefault();
        const [touch1, touch2] = e.touches;
        const dx = touch1.clientX - touch2.clientX;
        const dy = touch1.clientY - touch2.clientY;
        const newDistance = Math.sqrt(dx * dx + dy * dy);
        const newAngle = Math.atan2(dy, dx);

        const scale = newDistance / initialDistance;
        const rotation = ((newAngle - initialAngle) * 180) / Math.PI;

        const activeObject = fabricCanvas.current.getActiveObject();
        if (activeObject) {
          activeObject.scaleX = initialScaleX * scale;
          activeObject.scaleY = initialScaleY * scale;
          activeObject.angle = initialRotation + rotation;
          activeObject.setCoords();
          fabricCanvas.current.renderAll();
        }
      }
    };

    const handleTouchEnd = (e) => {
      if (e.touches.length < 2) {
        isScaling = false;
        saveCanvasState("object scaled via touch");
      }
    };

    canvasElement.addEventListener("touchstart", handleTouchStart, {
      passive: false,
    });
    canvasElement.addEventListener("touchmove", handleTouchMove, {
      passive: false,
    });
    canvasElement.addEventListener("touchend", handleTouchEnd);

    // Clean up on unmount
    return () => {
      window.removeEventListener("resize", resizeCanvas);
      fabricCanvas.current.off("selection:cleared", () => {
        setSelectedTextObject(null); // Reset state if another object is selected
        setSelectedImageObject(null);
        setIsColorPickerMode(false);
      });
      fabricCanvas.current.off("mouse:down", onMouseDown);
      fabricCanvas.current.off("mouse:up", onMouseUp);
      fabricCanvas.current.off("selection:updated", onObjectSelected);
      fabricCanvas.current.off("selection:created", onObjectSelected);
      fabricCanvas.current.off("object:moving", onObjectMoving);
      fabricCanvas.current.off("object:modified", onObjectModified);
      fabricCanvas.current.off("object:added", onObjectAdded);
      fabricCanvas.current.off("object:removed", onObjectRemoved);
      fabricCanvas.current.dispose();
      setSelectedTextObject(null);
      setSelectedImageObject(null);
      setSelectedGoods([]);
      setBackgroundColor("transparent");

      // Remove touch event listeners
      canvasElement.removeEventListener("touchstart", handleTouchStart);
      canvasElement.removeEventListener("touchmove", handleTouchMove);
      canvasElement.removeEventListener("touchend", handleTouchEnd);
    };
  }, [aspectRatio, collageDetails]);

  // Function to save the current state of the canvas
  const saveCanvasState = (triggredBy = null, rewrite = false) => {
    if (!isRestoring.current) {
      const canvasJson = fabricCanvas.current.toDatalessJSON([
        "goodId",
        "backgroundRemoved",
        "isGuideLine", // Exclude guide lines
      ]);
      console.log("canvasJson");
      console.log(canvasJson);
      if (rewrite) {
        canvasStates.current = [canvasJson];
      } else canvasStates.current.push(canvasJson);
      console.log("saved state, triggered by ", triggredBy);
      console.log(canvasStates);
    }
  };
  // Event handler for object added
  const onObjectAdded = (e) => {
    if (e.target.isGuideLine) return; // Ignore guide lines
    console.log(`object added`);
    console.log(e);
    console.log(e.target.goodId);
    if (e.target.goodId) {
      setSelectedGoods((prev) => [...prev, e.target.goodId]);
    }
    if (e.target.isShimmer) return;
    saveCanvasState("object added");
  };

  // Event handler for object removed
  const onObjectRemoved = (e) => {
    if (e.target.isGuideLine) return; // Ignore guide lines
    console.log(`object removed`);
    console.log(e);
    const goodId = e.target.goodId;
    if (goodId) {
      setSelectedGoods((prev) => prev.filter((id) => id !== goodId));
    }

    saveCanvasState("object removed");
  };

  // Function to undo the last action
  const undo = () => {
    if (canvasStates.current.length > 1) {
      isRestoring.current = true;
      // Remove the current state
      console.log("undo");
      console.log(canvasStates);
      canvasStates.current.pop();
      console.log(canvasStates);
      // Get the previous state
      const previousState =
        canvasStates.current[canvasStates.current.length - 1];

      fabricCanvas.current.loadFromJSON(previousState).then((e) => {
        console.log("finished restoring");
        isRestoring.current = false;
        console.log(e);
        fabricCanvas.current.renderAll();
      });
    }
  };

  const onMouseDown = (e) => {
    setIsColorPickerMode(false);
  };

  // New function to handle mouse up event
  const onMouseUp = (e) => {
    removeGuideLines();
  };

  // Function to add an image to the canvas
  const addImageToCanvas = (url, goodId, backgroundRemoved) => {
    if (!fabricCanvas.current) {
      console.error("Canvas is not initialized yet");
      return;
    }
    return new Promise((resolve, reject) => {
      fabric.FabricImage.fromURL(
        url,
        {
          crossOrigin: "anonymous",
        },
        { goodId: goodId, backgroundRemoved: backgroundRemoved }
      )
        .then((img) => {
          if (fabricCanvas.current.width / 2 < fabricCanvas.current.height / 2)
            img.scaleToWidth(fabricCanvas.current.width / 2);
          else img.scaleToHeight(fabricCanvas.current.height / 2);

          img.set({
            left: fabricCanvas.current.width / 2 - (img.width * img.scaleX) / 2,
            top:
              fabricCanvas.current.height / 2 - (img.height * img.scaleY) / 2,
          });

          fabricCanvas.current.add(img);
          fabricCanvas.current.setActiveObject(img);

          resolve(img);
        })
        .catch((err) => {
          console.error("Error adding image to canvas:", err);
          reject(err);
        });
    });
  };

  // Function to add text to the canvas
  const addTextToCanvas = () => {
    if (!fabricCanvas.current) {
      console.error("Canvas is not initialized yet");
      return;
    }
    const text = new fabric.Textbox("Text", {
      left: fabricCanvas.current.width / 2 - 48,
      top: fabricCanvas.current.height / 2 - 48,
      fill: textColor,
      fontSize: 48,
      selectable: true,
      editable: true,
      fontFamily: "GT Flexa, system-ui, sans-serif",
      fontWeight: textWeight,
    });
    fabricCanvas.current.add(text);
    fabricCanvas.current.setActiveObject(text);
  };

  useEffect(() => {
    if (!isColorPickerMode) {
      if (
        !lastUsedColors.includes(lastUsedColor) &&
        lastUsedColor !== "transparent" &&
        lastUsedColor !== ""
      ) {
        setLastUsedColors((prev) => {
          return [...prev, lastUsedColor].slice(-3);
        });
      }
    }
  }, [isColorPickerMode]);

  // Function to update the color of the selected text object
  const updateTextColor = (color) => {
    setTextColor(color);
    if (!fabricCanvas.current) {
      console.error("Canvas is not initialized yet");
      return;
    }
    selectedTextObject.set("fill", color);
    fabricCanvas.current.renderAll();
    setLastUsedColor(color);
  };

  const updateTextWeight = (weight) => {
    const index = textWeights.indexOf(weight);
    setTextWeight(textWeights[(index + 1) % textWeights.length]);

    selectedTextObject.set(
      "fontWeight",
      textWeights[(index + 1) % textWeights.length]
    );
    fabricCanvas.current.renderAll();
  };

  const handleSaveCanvas = () => {
    if (!fabricCanvas.current) {
      console.error("Canvas is not initialized yet");
      return;
    }

    canvasStates.current[canvasStates.current.length - 1].width =
      fabricCanvas.current.width;
    canvasStates.current[canvasStates.current.length - 1].height =
      fabricCanvas.current.height;

    const scaleFactor = 3;

    const dataURL = fabricCanvas.current.toDataURL({
      format: "webp",
      quality: 1,
      multiplier: scaleFactor, // Use multiplier for higher resolution
    });

    const dataURLToBlob = (dataURL) => {
      const arr = dataURL.split(",");
      const mimeMatch = arr[0].match(/:(.*?);/);
      const mime = mimeMatch ? mimeMatch[1] : "image/png";
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new Blob([u8arr], { type: mime });
    };

    const blob = dataURLToBlob(dataURL);
    const file = new File([blob], "high-quality-collage.webp", {
      type: "image/webp",
    });

    setCollagePreview(file);
    setIsPostCollageOpen(true);
  };

  const onObjectMoving = (e) => {
    const object = e.target;

    const objectCenter = object.getCenterPoint();
    const canvasCenter = new fabric.Point(
      fabricCanvas.current.width / 2,
      fabricCanvas.current.height / 2
    );

    // Threshold distance for snapping
    const threshold = 5;

    // Snap for vertical alignment
    if (Math.abs(objectCenter.x - canvasCenter.x) < threshold) {
      // Show vertical guide line at canvas center x
      if (!verticalGuideLine.current) {
        verticalGuideLine.current = new fabric.Line(
          [canvasCenter.x, 0, canvasCenter.x, fabricCanvas.current.height],
          {
            stroke: getContrastingColor(fabricCanvas.current.backgroundColor),
            strokeWidth: 1,
            selectable: false,
            evented: false,
            excludeFromExport: true,
            isGuideLine: true, // Custom property to identify guide lines
          }
        );
        fabricCanvas.current.add(verticalGuideLine.current);
        fabricCanvas.current.bringObjectToFront(verticalGuideLine.current);
      }

      // Snap object's center x to canvas center x
      object.left += canvasCenter.x - objectCenter.x;
      object.setCoords();
    } else {
      // Remove vertical guide line if exists
      if (verticalGuideLine.current) {
        fabricCanvas.current.remove(verticalGuideLine.current);
        verticalGuideLine.current = null;
      }
    }

    // Snap for horizontal alignment
    if (Math.abs(objectCenter.y - canvasCenter.y) < threshold) {
      // Show horizontal guide line at canvas center y
      if (!horizontalGuideLine.current) {
        horizontalGuideLine.current = new fabric.Line(
          [0, canvasCenter.y, fabricCanvas.current.width, canvasCenter.y],
          {
            stroke: getContrastingColor(fabricCanvas.current.backgroundColor),
            strokeWidth: 1,
            selectable: false,
            evented: false,
            excludeFromExport: true,
            isGuideLine: true, // Custom property to identify guide lines
          }
        );
        fabricCanvas.current.add(horizontalGuideLine.current);
        fabricCanvas.current.bringObjectToFront(horizontalGuideLine.current);
      }

      // Snap object's center y to canvas center y
      object.top += canvasCenter.y - objectCenter.y;
      object.setCoords();
    } else {
      // Remove horizontal guide line if exists
      if (horizontalGuideLine.current) {
        fabricCanvas.current.remove(horizontalGuideLine.current);
        horizontalGuideLine.current = null;
      }
    }
  };

  // Function to remove guide lines
  const removeGuideLines = () => {
    if (verticalGuideLine.current) {
      fabricCanvas.current.remove(verticalGuideLine.current);
      verticalGuideLine.current = null;
    }
    if (horizontalGuideLine.current) {
      fabricCanvas.current.remove(horizontalGuideLine.current);
      horizontalGuideLine.current = null;
    }
  };

  const onObjectModified = (e) => {
    if (!e.e) return;
    removeGuideLines(); // Remove guide lines when movement stops
    saveCanvasState(`object modified by ${e.e}`);
  };

  const removeGoodFromCanvas = (good) => {
    setSelectedGoods((prev) => prev.filter((id) => id !== good.id));
    fabricCanvas.current.getObjects().forEach((obj) => {
      if (obj.goodId === good.id) {
        fabricCanvas.current.remove(obj);
      }
    });
  };

  const addOrRemoveGoodToCanvas = (good) => {
    if (selectedGoods.includes(good.id)) {
      removeGoodFromCanvas(good);
    } else {
      setSelectedGoods((prev) => [...prev, good.id]);
      console.log(good.image_type);
      addImageToCanvas(
        `https://goodsend.fra1.cdn.digitaloceanspaces.com/main-space/images/${good.image_url}`,
        good.id,
        good.image_type === "1"
      );
    }
  };

  const onObjectSelected = (e) => {
    const object = e.selected[0];
    if (object) {
      fabricCanvas.current.bringObjectToFront(object);
      saveCanvasState("object selected");
      object.set({
        borderColor: "#4a5b39", // Change the border color of the selection
        cornerColor: "#4a5b39", // Change the color of the corner resize controls
        cornerStrokeColor: "#4a5b39", // Change the border color of the corner controls
        cornerSize: 10, // Change the size of the corner squares (optional)
        cornerStyle: "none", // Make the corners appear as circles (optional)
      });
    }

    if (object) {
      if (object.type === "textbox")
        setSelectedTextObject(object); // Update state with selected text
      else if (object.type === "image") setSelectedImageObject(object);
    } else {
      setSelectedTextObject(null); // Reset state if another object is selected
      setSelectedImageObject(null);
      setIsColorPickerMode(false);
    }
  };

  const extractImageFileFromFabricImage = (fabricImage) => {
    return new Promise((resolve, reject) => {
      fetch(fabricImage.getSrc())
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "image.png", {
            type: "image/png",
          });
          resolve(file);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const updateFabricImageSrc = (fabricImage, newUrl) => {
    // Get the underlying image element
    const imgElement = fabricImage.getElement();

    // Set the new image source
    imgElement.src = newUrl;

    // Listen for the image load event to ensure the image is fully loaded before updating the canvas
    imgElement.onload = () => {
      // After the image is loaded, you might want to reset the size or scale
      fabricImage.set({
        width: imgElement.width,
        height: imgElement.height,
        scaleX: fabricImage.scaleX || 1,
        scaleY: fabricImage.scaleY || 1,
      });

      // Update the image's coordinates and re-render the canvas
      fabricImage.setCoords();
      fabricImage.canvas.renderAll();
    };

    // Handle errors, in case the image fails to load
    imgElement.onerror = () => {
      console.error("Failed to load new image source.");
    };
  };

  const addShimmerEffect = (fabricCanvas, fabricImage) => {
    // Create a shimmer overlay rectangle

    const shimmer = new fabric.Rect({
      left: fabricImage.left,
      top: fabricImage.top,
      width: fabricImage.width * fabricImage.scaleX * 0.25,
      height: fabricImage.height * fabricImage.scaleY,
      selectable: false,
      evented: false,
    });

    // Apply a linear gradient for the shimmer effect
    const gradient = new fabric.Gradient({
      type: "linear",
      gradientUnits: "percentage",
      coords: { x1: 0, y1: 0, x2: 1, y2: 0 },
      colorStops: [
        { offset: 0, color: "rgba(255,255,255,0)", opacity: 0 },
        { offset: 0.5, color: "rgba(255,255,255,0.05)", opacity: 0.1 },
        { offset: 1, color: "rgba(255,255,255,0)", opacity: 0 },
      ],
    });

    // Set the gradient as the fill of the shimmer
    shimmer.set("fill", gradient);
    shimmer.isShimmer = true;
    fabricImage.set("lockMovementX", true);
    fabricImage.set("lockMovementY", true);

    // // Add the shimmer to the canvas
    fabricCanvas.add(shimmer);
    fabricCanvas.bringObjectToFront(shimmer);
    fabricCanvas.renderAll();
    shimmer.set({
      left: fabricImage.left,
    });

    const animateShimmerFront = () => {
      shimmer.animate(
        {
          left:
            fabricImage.left +
            fabricImage.width * fabricImage.scaleX -
            fabricImage.width * fabricImage.scaleX * 0.25,
        },
        {
          duration: 1000, // 1 second shimmer effect
          onChange: () => fabricCanvas.renderAll(),
          easing: fabric.util.ease.easeInOutQuad,
          onComplete: AnimateShimmerBack, // Loop the shimmer animation
        }
      );
    };
    const AnimateShimmerBack = () => {
      shimmer.animate(
        {
          left: fabricImage.left,
        },
        {
          duration: 1000, // 1 second shimmer effect
          onChange: () => fabricCanvas.renderAll(),
          easing: fabric.util.ease.easeInOutQuad,
          onComplete: animateShimmerFront, // Loop the shimmer animation
        }
      );
    };

    // Start the shimmer animation
    animateShimmerFront();

    return shimmer; // Return shimmer object to remove later
  };

  const removeSelectedImageBackground = () => {
    if (!selectedImageObject) return;
    const image = selectedImageObject;
    const canvas = fabricCanvas.current;

    const fabricImage = image;
    const file = extractImageFileFromFabricImage(fabricImage);
    let shimmer = addShimmerEffect(canvas, fabricImage);
    file.then((imageFile) => {
      removeImageBackground(imageFile)
        .then((response) => {
          fabricCanvas.current.remove(shimmer);
          fabricImage.set("lockMovementX", false);
          fabricImage.set("lockMovementY", false);
          updateFabricImageSrc(
            fabricImage,
            `https://goodsend.fra1.cdn.digitaloceanspaces.com/main-space/images/${response.filename}`
          );
          fabricImage.set("backgroundRemoved", true);
        })
        .catch((error) => {
          console.error("Error removing background:", error);
        });
    });
  };
  const deleteSelectedObject = () => {
    fabricCanvas.current.remove(fabricCanvas.current.getActiveObject());
  };

  const updateBackgroundColor = (color) => {
    setBackgroundColor(color);
    fabricCanvas.current.backgroundColor = color;
    fabricCanvas.current.renderAll();
    saveCanvasState("background color changed");
    setLastUsedColor(color);
  };

  const onPopupClose = () => {
    if (canvasStates.current.length > 1) {
      setIsClosePopupOpen(true);
    } else {
      onClose();
    }
  };

  const cleanCanvas = () => {
    fabricCanvas.current.clear();
    canvasStates.current = canvasStates.current.slice(0, 1);
    setBackgroundColor("transparent");
    fabricCanvas.current.backgroundColor = "transparent";
    setSelectedTextObject(null);
    setSelectedImageObject(null);
    setSelectedGoods([]);
  };

  return (
    <BottomModal
      onClose={onPopupClose}
      zIndex={11}
      containerClassName={`max-w-[550px] rounded-none sm:w-fit sm:rounded-t-[28px]  md:rounded-t-[28px] md:max-w-[1400px] md:h-full md:w-auto md:aspect-[4_/_3] ${
        aspectRatio === 4 / 3 ? "" : "md:pt-10 md:pb-10"
      }`}
      isFullHeight={true}
      //   noTopPadding={true}
      className={
        "max-h-[100vh] h-full rounded-none overflow-hidden bg-black sm:rounded-t-[28px] pt-0"
      }
      isNested={true}
      isOpen={isOpen}
      isNotSwipable={true}
      isCloseIcon={true}
    >
      <div className="flex flex-col h-full bg-black md:bg-white box-border">
        <div
          className=" flex justify-center w-full h-full flex-grow overflow-hidden select-none"
          id="canvas-wrapper"
        >
          <div
            className={`relative md:static rounded-[28px] max-w-fit h-fit ${
              aspectRatio === 4 / 3 ? "" : "md:border border-grey-3"
            }  `}
            style={{
              backgroundColor: "white",
              backgroundImage: "radial-gradient(gray 5%, transparent 8%)",
              backgroundSize: "20px 20px",
            }}
          >
            <div className="flex justify-between items-center absolute left-0 top-5 w-full z-[21]">
              <div className="flex items-center">
                <CloseFilledIcon
                  className={`drop-shadow block ml-4 mr-2 w-10 h-10 text-grey-2 cursor-pointer hover:filter hover:brightness-95 active:scale-[0.985]`}
                  onClick={onPopupClose}
                  onTouchStart={(e) => e.stopPropagation()}
                />

                <UndoIcon
                  className="drop-shadow w-[33px] h-[33px] text-white hover:filter hover:brightness-95 active:scale-[0.985]"
                  onClick={undo}
                />
              </div>
              <div className="items-center hidden md:flex">
                <button
                  onClick={() => setAspectRatio(4 / 3)}
                  className={`block mr-4 p-2 px-6  cursor-pointer rounded-full text-sm/tight shadow hover:filter hover:brightness-95 active:scale-[0.985]
                    ${
                      aspectRatio === 4 / 3
                        ? "bg-grey-1 text-white"
                        : "text-black bg-grey-3"
                    }`}
                >
                  Horizontal(4:3)
                </button>
                <button
                  onClick={() => setAspectRatio(9 / 16)}
                  className={`block mr-4 p-2 px-6 cursor-pointer rounded-full text-sm/tight shadow hover:filter hover:brightness-95 active:scale-[0.985]
                    ${
                      aspectRatio === 9 / 16
                        ? "bg-grey-1 text-white"
                        : "text-black bg-grey-3"
                    }`}
                >
                  Vertical(9:16)
                </button>
              </div>
              <button
                className="block mr-4 p-2 px-6 text-white cursor-pointer bg-primary rounded-full text-sm/tight shadow hover:filter hover:brightness-95 active:scale-[0.985]"
                onClick={handleSaveCanvas}
              >
                Save
              </button>
            </div>
            <canvas
              id="collage-canvas"
              ref={canvasRef}
              className=" rounded-3xl"
            />
          </div>
        </div>

        <div
          className={`relative md:absolute bottom-0 flex justify-center items-center w-full pt-4 flex-shrink-0 box-border ${
            isColorPickerMode ? "space-x-4" : "space-x-8"
          }`}
          style={{ paddingBottom: "max(env(safe-area-inset-bottom), 12px)" }}
        >
          {isColorPickerMode ? (
            <div className="relative drop-shadow flex justify-center items-center w-full pb-4 space-x-4">
              <CloseFilledIcon
                className={`block w-10 h-10 text-grey-2 cursor-pointer hover:filter hover:brightness-95 active:scale-[0.985]`}
                onClick={() => setIsColorPickerMode(false)}
                onTouchStart={(e) => e.stopPropagation()}
              />
              {!selectedTextObject && (
                <button
                  className={`w-12 h-12 rounded-xl bg-black drop-shadow
                    ${
                      backgroundColor === "transparent" &&
                      "border-[3px] border-white"
                    }`}
                  style={{
                    backgroundColor: "white",
                    backgroundImage: `
                      linear-gradient(45deg, #eee 25%, transparent 25%), 
                      linear-gradient(135deg, #eee 25%, transparent 25%), 
                      linear-gradient(45deg, transparent 75%, #eee 75%), 
                      linear-gradient(135deg, transparent 75%, #eee 75%)
                    `,
                    backgroundSize: "12px 12px",
                    backgroundPosition: "0 0, 6px 0, 6px -6px, 0px 6px",
                  }}
                  onClick={() => updateBackgroundColor("transparent")}
                ></button>
              )}
              {lastUsedColors.map((color, index) => (
                <button
                  key={index}
                  className={`w-12 h-12 rounded-xl bg-black drop-shadow
                    ${
                      ((selectedTextObject &&
                        selectedTextObject.fill === color) ||
                        (!selectedTextObject && backgroundColor === color)) &&
                      "border-[3px] border-white"
                    }`}
                  style={{ backgroundColor: color }}
                  onClick={() =>
                    selectedTextObject
                      ? updateTextColor(color)
                      : updateBackgroundColor(color)
                  }
                ></button>
              ))}

              <div className="relative drop-shadow hover:filter hover:brightness-95 active:scale-[0.985] cursor-pointer">
                <button className="flex flex-col items-center text-xs/tight text-white ">
                  <div className="flex items-center justify-center w-12 h-12 rounded-xl bg-grey-1">
                    <ColorPickerIcon className="w-6 h-6 text-black" />
                  </div>
                  {/* Change <br /> Color */}
                </button>
                <input
                  id="text-color-input"
                  className="absolute w-full h-full inset-0 opacity-0 cursor-pointer"
                  type="color"
                  value={textColor}
                  onChange={(e) =>
                    selectedTextObject
                      ? updateTextColor(e.target.value)
                      : updateBackgroundColor(e.target.value)
                  }
                />
              </div>
            </div>
          ) : selectedTextObject ? (
            <>
              <button
                className="text-white text-xs/tight drop-shadow hover:filter hover:brightness-95 active:scale-[0.985]"
                onClick={() => {
                  selectedTextObject.enterEditing();
                  selectedTextObject.hiddenTextarea.focus();
                }}
              >
                <div className="flex items-center justify-center bg-white w-14 h-14 rounded-full mb-2">
                  <PencilIcon className="h-6" />
                </div>
                {/* Edit <br /> Text */}
              </button>
              <button
                className="text-xs/tight text-white drop-shadow hover:filter hover:brightness-95 active:scale-[0.985]"
                onClick={(e) => updateTextWeight(selectedTextObject.fontWeight)}
              >
                <div
                  className="text-xl text-black flex items-center justify-center bg-white w-14 h-14 rounded-full mb-2"
                  style={{ fontWeight: selectedTextObject.fontWeight }}
                >
                  A
                </div>
                {/* Change <br /> Weight */}
              </button>

              <button
                className="flex flex-col items-center text-xs/tight text-white drop-shadow hover:filter hover:brightness-95 active:scale-[0.985] cursor-pointer"
                onClick={() => setIsColorPickerMode(true)}
              >
                <div className="flex items-center justify-center w-14 h-14 rounded-full bg-white mb-2">
                  <div
                    className="w-7 h-7 rounded-full border-2 border-black"
                    style={{ backgroundColor: selectedTextObject.fill }}
                  ></div>
                </div>
                {/* Change <br /> Color */}
              </button>

              <button
                className="text-white text-xs/tight drop-shadow hover:filter hover:brightness-95 active:scale-[0.985]"
                onClick={deleteSelectedObject}
              >
                <div className="flex items-center justify-center bg-white w-14 h-14 rounded-full mb-2">
                  <TrashIcon className="w-8 h-8 text-black" />
                </div>
                {/* Delete <br /> Text */}
              </button>
            </>
          ) : selectedImageObject ? (
            <>
              {!selectedImageObject.backgroundRemoved && (
                <button
                  className="flex flex-col items-center justify-center text-white text-xs/tight drop-shadow hover:filter hover:brightness-95 active:scale-[0.985]"
                  onClick={removeSelectedImageBackground}
                >
                  <div
                    className="relative text-black flex items-center justify-center w-14 h-14 rounded-full mb-2"
                    style={{
                      backgroundColor: "white",
                      backgroundImage: `
                      linear-gradient(45deg, #eee 25%, transparent 25%), 
                      linear-gradient(135deg, #eee 25%, transparent 25%), 
                      linear-gradient(45deg, transparent 75%, #eee 75%), 
                      linear-gradient(135deg, transparent 75%, #eee 75%)
                    `,
                      backgroundSize: "12px 12px",
                      backgroundPosition: "0 0, 6px 0, 6px -6px, 0px 6px",
                    }}
                  >
                    <span className="absolute top-[-8px] left-[16px] text-white bg-primary py-0.5 px-1.5 rounded-full">
                      AI
                    </span>
                    <EraserIcon className="w-6 mb-1" />
                  </div>
                  {/* Remove <br /> Background */}
                </button>
              )}
              <button
                className="text-white text-xs/tight drop-shadow hover:filter hover:brightness-95 active:scale-[0.985]"
                onClick={deleteSelectedObject}
              >
                <div className="flex items-center justify-center bg-white w-14 h-14 rounded-full mb-2">
                  <TrashIcon className="w-8 h-8 text-black" />
                </div>
                {/* Delete <br /> Good */}
              </button>
            </>
          ) : (
            <>
              <button
                className=" text-white text-xs/tight drop-shadow hover:filter hover:brightness-95 active:scale-[0.985]"
                onClick={() => setIsCollageGoodsOpen(true)}
              >
                <div className="flex justify-center items-center bg-primary w-14 h-14 rounded-full mb-2">
                  <RoundedPlusIcon className="w-5 h-5 text-white" />
                </div>
                {/* Add <br /> Goods */}
              </button>
              <button
                className="text-white text-xs/tight drop-shadow hover:filter hover:brightness-95 active:scale-[0.985]"
                onClick={addTextToCanvas}
              >
                <div className="flex items-center justify-center bg-white w-14 h-14 rounded-full mb-2">
                  <TextIcon className="h-4" />
                </div>
                {/* Add <br /> Text */}
              </button>
              <button
                className="flex flex-col items-center text-xs/tight text-white drop-shadow hover:filter hover:brightness-95 active:scale-[0.985] cursor-pointer"
                onClick={() => setIsColorPickerMode(true)}
              >
                <div className="flex items-center justify-center w-14 h-14 rounded-full bg-white mb-2">
                  <div
                    className="w-7 h-7 rounded-full border-2 border-black"
                    style={{ backgroundColor: backgroundColor }}
                  ></div>
                </div>
                {/* Change <br /> Color */}
              </button>

              <button
                className="relative text-white text-xs/tight drop-shadow hover:filter hover:brightness-95 active:scale-[0.985]"
                // onClick={addTextToCanvas}
              >
                <span className="absolute top-[-6px] left-[5px]  bg-primary py-0.5 px-1.5 rounded-full">
                  SOON
                </span>
                <div className="flex items-center justify-center bg-white w-14 h-14 rounded-full mb-2">
                  <UploadImageIcon className="w-5" />
                </div>
                {/* Upload <br /> Image */}
              </button>
            </>
          )}
        </div>
      </div>

      <ColageGoodsModal
        isOpen={isCollageGoodsOpen}
        onClose={() => setIsCollageGoodsOpen(false)}
        addGood={addOrRemoveGoodToCanvas}
        selectedGoods={selectedGoods}
      />
      <CollagePostModal
        isOpen={isPostCollageOpen}
        onClose={() => {
          setIsPostCollageOpen(false);
        }}
        onMainPopupClose={() => {
          cleanCanvas();
          onClose();
        }}
        collageJSON={canvasStates.current[canvasStates.current.length - 1]}
        bgColor={backgroundColor}
        collagePreview={collagePreview}
        collageDetails={collageDetails}
      />
      <BottomModal
        isOpen={isClosePopupOpen}
        onClose={() => setIsClosePopupOpen(false)}
        zIndex={21}
        isNested={true}
        containerClassName="max-w-[450px]"
        // isFullHeight={false}
        // className="rounded-t-[28px] bg-black"
      >
        <div className="flex flex-col w-full h-full px-4">
          <h1 className="text-left text-xl font-bold mt-4 mb-1">
            Close collage editing?
          </h1>
          <p className="text-left text-grey-2 mb-4">
            If you go back now you will lose any changes you’ve made
          </p>
          <div className="mb-8 w-full">
            <button
              className="flex justify-center items-center w-full h-12 rounded-3xl bg-grey-4 p-4 text-[#F44C4C] text-lg mb-2"
              onClick={() => {
                onClose();
                cleanCanvas();
                setIsClosePopupOpen(false);
              }}
            >
              Close
            </button>
            <button
              className="flex justify-center items-center w-full h-12 mr-6 rounded-3xl bg-grey-4 p-4  text-lg"
              onClick={() => setIsClosePopupOpen(false)}
            >
              Keep Editing
            </button>
          </div>
        </div>
      </BottomModal>
    </BottomModal>
  );
};
