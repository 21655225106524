import * as amplitude from "@amplitude/analytics-browser";
import * as Sentry from "@sentry/react";
import { useUser } from "UserContext";
import { ReactComponent as AppleIcon } from "assets/icons/apple.svg";
import { ReactComponent as TelegramIcon } from "assets/icons/telegram.svg";
import PrimaryButton from "components/PrimaryButton";
import TelegramButton from "components/TelegramButton";
import { useAppleAuthMutation } from "hooks/useAppleAuthMutation";
import { useTelegramAuthMutation } from "hooks/useTelegramAuthMutation";
import { useEffect } from "react";
import AppleSignin from "react-apple-signin-auth";
import { Link, Navigate } from "react-router-dom";

const OnboardingStepFour = () => {
  useEffect(() => {
    amplitude.track(`onb_intro_4`);
  }, []);

  const {
    mutate: telegramAuth,
    isError: isErrorTelegram,
    error: errorTelegram,
    isSuccess: isSuccessTelegram,
  } = useTelegramAuthMutation();

  const {
    mutate: handleAppleAuth,
    isError: isErrorApple,
    error: errorApple,
    isSuccess: isSuccessApple,
  } = useAppleAuthMutation();

  const { user, isWebView } = useUser();

  const handleWebViewAppleAuth = () => {
    window.webkit.messageHandlers.test.postMessage("hello!");
  };
  const handleTelegramAuth = (response) => {
    response.is_webview = isWebView;
    telegramAuth(response);
  };

  useEffect(() => {
    if (isErrorTelegram) {
      Sentry.captureException(
        new Error(`Telegram login failed: ${errorTelegram.message}`)
      );
    }
    if (isErrorApple) {
      Sentry.captureException(
        new Error(`Apple login failed: ${errorApple.message}`)
      );
    }
  }, [isErrorTelegram, isErrorApple]);

  if ((isSuccessTelegram || isSuccessApple) && !isWebView) {
    return <Navigate to="/last-step" />;
  }

  return (
    <div className="h-[calc(var(--vh)_*_100)] flex flex-col justify-between">
      <div className="bg-[url('assets/images/intro/image-4.webp')] bg-contain bg-no-repeat bg-center h-full mt-6"></div>
      <div className="mt-14 flex flex-col items-center justify-center text-center">
        <h3 className="font-bold text-sm text-grey-2 mb-2">How to Goodsend?</h3>
        <div className="font-regular font-bold text-xl text-center max-w-[325px] leading-7">
          Explore others’ galleries for inspiration
        </div>
        <p className="font-regular text-lg max-w-[290px] text-grey-1 mt-4 mb-8 leading-6">
          Discover your next favorite object, brand or people to admire
        </p>
        {!user ? (
          <>
            <TelegramButton
              className="mb-1 w-full max-w-[290px]"
              handleAuth={handleTelegramAuth}
            >
              <TelegramIcon className="inline-block w-6 h-6 mr-1 text-grey-2" />
              Login with Telegram
            </TelegramButton>
            {isWebView ? (
              <PrimaryButton
                onClick={handleWebViewAppleAuth}
                className="px-4 bg-black mb-6 w-full max-w-[290px]"
              >
                <AppleIcon className="mr-0.5" /> Continue with Apple
              </PrimaryButton>
            ) : (
              <AppleSignin
                /** Auth options passed to AppleID.auth.init() */
                authOptions={{
                  /** Client ID - eg: 'com.example.com' */
                  clientId: "com.goodsend.web",
                  /** Requested scopes, seperated by spaces - eg: 'email name' */
                  scope: "email name",
                  /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                  redirectURI: "https://goodsend.it/auth/apple/callback",
                  /** State string that is returned with the apple response */
                  state: "state",
                  /** Nonce */
                  nonce: "nonce",
                  /** Uses popup auth instead of redirection */
                  usePopup: true,
                }} // REQUIRED
                /** General props */
                uiType="dark"
                /** className */
                className="apple-auth-btn"
                /** Removes default style tag */
                noDefaultStyle={false}
                /** Allows to change the button's children, eg: for changing the button text */
                buttonExtraChildren="Continue with Apple"
                /** Extra controlling props */
                /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
                onSuccess={(response) => handleAppleAuth(response)} // default = undefined
                /** Called upon signin error */
                onError={(error) => console.error(error)} // default = undefined
                /** Skips loading the apple script if true */
                skipScript={false} // default = undefined
                /** Apple image props */
                iconProp={{ style: { marginTop: "10px" } }} // default = undefined
                /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
                render={(props) => (
                  <PrimaryButton
                    className="px-4 bg-black mb-12 w-full max-w-[290px] "
                    {...props}
                  >
                    <AppleIcon className="mr-0.5" /> Continue with Apple
                  </PrimaryButton>
                )}
              />
            )}
            {isErrorTelegram && (
              <p>Error fetching JWT: {errorTelegram.message}</p>
            )}
            {isErrorApple && <p>Error fetching JWT: {errorApple.message}</p>}
          </>
        ) : (
          <Link
            to="/last-step"
            className="bg-primary self-center text-white rounded-full text-lg py-4 mb-12 mt-8 text-center w-full max-w-[350px]"
          >
            Continue
          </Link>
        )}
      </div>
    </div>
  );
};

export default OnboardingStepFour;
