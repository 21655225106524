import * as amplitude from "@amplitude/analytics-browser";
import { ReactComponent as ShareIcon } from "assets/icons/link.svg";
import PrimaryButton from "components/PrimaryButton";
import useShare from "hooks/useShare";
const ShareBtn = ({ isSmall = false, goodId, className }) => {
  const handleShareClick = useShare();
  const handleClick = async (event) => {
    event.preventDefault();
    handleShareClick();
    amplitude.track("good_share_clicked", { id: goodId });
  };

  if (isSmall) {
    return (
      <button
        onClick={handleClick}
        className={`w-14 bg-[#F6F6F6] rounded-full h-14 flex items-center justify-center text-grey-2 text-lg font-bold hover:filter hover:brightness-95 ${className}`}
      >
        <ShareIcon className="w-6 h-6" />
      </button>
    );
  } else {
    return (
      <PrimaryButton
        onClick={handleClick}
        className="flex-grow mr-3 bg-grey-4 rounded-full flex items-center justify-center text-primary text-lg font-bold"
      >
        <ShareIcon className="w-6 h-6 mr-2" />
        Share good
      </PrimaryButton>
    );
  }
};

export default ShareBtn;
