const UserInfoSkeleton = () => {
  return (
    <div className="relative mt-8 mb-20">
      <div className="flex justify-center items-center flex-col text-center after:content-[''] after:absolute after:z-[-1] after:bottom-[-40px] after:block after:w-[760px] after:h-[760px] after:bg-grey-4 after:rounded-full">
        <div className="animate-pulse flex justify-center items-center rounded-full bg-grey-3 text-primary font-regular font-extrabold text-3xl w-[72px] h-[72px] mb-4"></div>
        <div className="font-black mb-3 leading-8">
          <div className="animate-pulse h-8 w-36 bg-grey-3 rounded-full dark:bg-grey-4"></div>
        </div>
        <div className="animate-pulse mb-3">
          <div className="h-4 w-36 bg-grey-3 rounded-full dark:bg-grey-4"></div>
        </div>
        <div className="animate-pulse mb-4">
          <div className="h-[50px] w-32 bg-grey-3 rounded-full dark:bg-grey-4"></div>
        </div>
      </div>
    </div>
  );
};
export default UserInfoSkeleton;
