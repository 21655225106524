// import amplitude from "amplitude-js"; // Assuming amplitude is imported
import { ReactComponent as HeartIcon } from "assets/icons/heart.svg";
import PrimaryButton from "components/PrimaryButton";

const CollectBtn = ({ onClick, disabled }) => {
  return (
    <PrimaryButton
      onClick={onClick}
      className="flex-grow mr-3 bg-primary rounded-full flex items-center justify-center text-white text-lg font-bold"
      disabled={disabled}
    >
      {disabled ? (
        <>
          <HeartIcon className="mr-2" />
          Collecting...
        </>
      ) : (
        <>
          <HeartIcon className="mr-2" />
          Collect
        </>
      )}
    </PrimaryButton>
  );
};

export default CollectBtn;
