import Categories from "components/Categories";
import BottomModal from "../bottom_modal/BottomModal";

const FilterModal = ({
  isOpen,
  onClose,
  selectedCategory,
  setSelectedCategory,
}) => {
  return (
    <>
      <BottomModal
        isOpen={isOpen}
        onClose={onClose}
        containerClassName={"max-w-[550px] "}
        className={"rounded-t-3xl overflow-hidden"}
        zIndex={20}
      >
        <div className="flex flex-col px-4 min-h-[400px] h-full max-h-[calc(80_*_var(--vh))]">
          <h2 className="text-black text-xl font-bold mb-8">Filters</h2>
          <p className="mb-4">Category</p>
          <Categories
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            className={"justify-start"}
            expand={true}
          />
        </div>
      </BottomModal>
    </>
  );
};

export default FilterModal;
