import * as amplitude from "@amplitude/analytics-browser";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ReactComponent as UsersIcon } from "assets/icons/users.svg";
import PrimaryButton from "components/PrimaryButton";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getCurrentUserInfo, updateUserInfo } from "services/api";

const LastStepName = () => {
  useEffect(() => {
    amplitude.track(`onb_name`);
  }, []);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    data: user,
    isLoading: isLoadingUserInfo,
    isError: isErrorUserInfo,
    isIdle: isIdleUserInfo,
  } = useQuery({
    queryKey: ["currentUserInfo"],
    queryFn: () => getCurrentUserInfo(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (user) {
      setValue("name", user.name);
    }
  }, [user, setValue]);

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: ({ formData }) => updateUserInfo(formData),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["currentUserInfo"] });
      queryClient.invalidateQueries({ queryKey: ["userInfo", user.link_tag] });
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("name", data.name);

    mutate({ formData });
    navigate("/last-step/tag");
  };

  if (isErrorUserInfo) {
    return <div>Error loading user info</div>;
  }

  return (
    <div className="h-[calc(var(--vh)_*_100)] flex flex-col justify-between">
      <div className="flex flex-col items-center justify-center text-center h-full">
        <h3 className="font-bold text-sm text-grey-2 mb-2">How to Goodsend?</h3>
        <div className="font-bold text-xl text-center max-w-[290px] leading-7 mb-6">
          What's your name?
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full max-w-[500px]"
        >
          <div className="relative mb-4 mx-4">
            <input
              id="name"
              type="text"
              className="bg-grey-4 text-lg p-4 rounded-full w-full ps-12 focus:outline focus:outline-1 outline-black"
              placeholder="Name"
              {...register("name", { required: true, minLength: 3 })}
            />

            <UsersIcon className="absolute top-[18px] left-5 text-grey-2" />

            {errors.name && (
              <p className="text-grey-2">
                Name is required and must be longer than 3 symbols.
              </p>
            )}
          </div>
          <PrimaryButton
            className="w-[95vw] max-w-[500px] fixed bottom-10 left-1/2 -translate-x-1/2 disabled:opacity-50 "
            type="submit"
            disabled={errors.name}
          >
            Continue
          </PrimaryButton>
        </form>
      </div>
    </div>
  );
};

export default LastStepName;
