import React from "react";

const GoodSkeleton = React.forwardRef(({ goods, showOwner = true }, ref) => {
  return Array(goods)
    .fill(0)
    .map((_, i) => (
      <div
        ref={ref}
        key={i}
        className={`bg-[#F7F7F7] ${
          showOwner && "mt-4"
        } flex flex-col relative justify-end rounded-3xl aspect-[9_/_13] object-contain cursor-pointer`}
      >
        {showOwner && (
          <div className="animate-pulse  absolute -top-4 left-2 flex items-center bg-[#0000004d] backdrop-blur-md rounded-full px-3 py-2 w-24 h-8 dark:bg-[#0000005d] mb-2.5"></div>
        )}
        <div className="animate-pulse h-[60px] flex justify-center flex-col rounded-b-3xl px-4 pb-4">
          <div className="h-2 bg-gray-300 rounded-full dark:bg-gray-400 mb-2.5"></div>
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-300 mb-2.5"></div>
        </div>
      </div>
    ));
});

export default GoodSkeleton;
