import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";

const OnboardingStepOne = () => {
  useEffect(() => {
    amplitude.track(`onb_intro_1`);
  }, []);

  const [textCount, setTextCount] = useState(1);
  const greenDivRef = useRef(null);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (greenDivRef.current) {
        const categories = greenDivRef.current.querySelectorAll("div > div");

        const translateY = -(textCount * 40);
        greenDivRef.current.firstChild.style.transform = `translateY(${translateY}px)`;

        const newWidth = categories[textCount + 1]
          ? categories[textCount + 1].offsetWidth
          : 0;
        greenDivRef.current.style.width = `${newWidth}px`;

        setTextCount((prevCount) => (prevCount > 8 ? 0 : prevCount + 1));
      }
    }, 2000);

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [textCount]);

  return (
    <div className="h-[calc(var(--vh)_*_100)] flex flex-col justify-between">
      <div className="bg-[url('assets/images/intro/image-1.webp')] bg-contain bg-no-repeat bg-center h-full mt-6"></div>
      <div className="mt-14 flex flex-col items-center justify-center text-center">
        <div className="font-regular font-extrabold text-2xl text-center tracking-tight max-w-[350px]">
          Save &amp; Explore Your Favorite
          <div
            ref={greenDivRef}
            className="text-primary overflow-hidden inline-block h-10 w-[93px] text-left mb-[-9px] ml-2"
          >
            <div className="will-change-transform transition-transform">
              <div className="w-fit whitespace-nowrap">Goods</div>
              <div className="w-fit whitespace-nowrap">Clothing</div>
              <div className="w-fit whitespace-nowrap">Shoes</div>
              <div className="w-fit whitespace-nowrap">Electronics</div>
              <div className="w-fit whitespace-nowrap">Jewelry</div>
              <div className="w-fit whitespace-nowrap">Bags</div>
              <div className="w-fit whitespace-nowrap">Watches</div>
              <div className="w-fit whitespace-nowrap">Accessories</div>
              <div className="w-fit whitespace-nowrap">Homeware</div>
              <div className="w-fit whitespace-nowrap">Art &amp; Craft</div>
            </div>
          </div>
        </div>
        <Link
          to="/intro/2"
          className="bg-primary self-center text-white rounded-full text-lg py-4 mb-12 mt-16 text-center w-full max-w-[350px]"
        >
          Continue
        </Link>
      </div>
    </div>
  );
};

export default OnboardingStepOne;
