import * as amplitude from "@amplitude/analytics-browser";
import { useQuery } from "@tanstack/react-query";
import { ReactComponent as TelegramIcon } from "assets/icons/telegram.svg";
import TelegramButton from "components/TelegramButton";
import { useTelegramConnectMutation } from "hooks/useTelegramConnectMutation";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getCurrentUserInfo } from "services/api";

const OnboardingConnectTelegram = () => {
  const { mutate: handleConnectTelegram } = useTelegramConnectMutation();
  const navigate = useNavigate();

  const onClick = (telegramResponse) => {
    handleConnectTelegram(telegramResponse);
    // navigate("/" + currentUser?.link_tag);
  };
  useEffect(() => {
    amplitude.track(`onb_add_telegram`);
  }, []);
  const {
    data: currentUser,
    isLoading: isLoadingUserInfo,
    isError: isErrorUserInfo,
    isIdle: isIdleUserInfo,
  } = useQuery({
    queryKey: ["currentUserInfo"],
    queryFn: () => getCurrentUserInfo(),
  });
  console.log("currentUser", currentUser);

  if (isErrorUserInfo) {
    return <div>Error loading user info</div>;
  }

  return (
    <div className="h-[calc(var(--vh)_*_100)] flex flex-col justify-between">
      <div className="flex flex-col justify-center self-center items-center h-full w-full max-h-[500px] max-w-[500px] px-5 pt-4">
        <div className="bg-grey-4 rounded-3xl flex flex-col font-regular justify-center items-center h-full w-full ">
          <div className="bg-telegram w-28 h-28 mb-2 rounded-full flex justify-center items-center">
            <TelegramIcon className="w-20 h-20 -ml-1.5" />
          </div>
          <h2 className="text-xl font-bold text-center">
            Goodsend <br /> Bot
          </h2>
        </div>
      </div>
      <div className="mt-14 flex flex-col items-center justify-center text-center">
        <h3 className="font-bold text-sm text-grey-2 mb-2">How to Goodsend?</h3>
        <div className="font-bold text-xl text-center max-w-[290px] leading-7">
          Connect Goodsend with Telegram Bot
        </div>
        <p className="text-lg max-w-[340px] text-grey-1 mt-4 leading-6">
          Send your fav links to{" "}
          <span className="text-telegram font-bold">
            Goodsend Telegram bot{" "}
          </span>{" "}
          and get them added to your curated gallery
        </p>

        {currentUser?.telegram_id ? (
          <Link
            to={"/" + currentUser?.link_tag}
            className="self-center bg-primary text-white rounded-full text-lg py-4 mb-12 mt-8 text-center w-full max-w-[350px]"
          >
            Continue
          </Link>
        ) : (
          <>
            <TelegramButton
              className="px-6 py-4 self-center text-white rounded-full text-lg mb-4 mt-8 text-center w-full max-w-[350px]"
              handleAuth={onClick}
              context="onboarding"
            >
              <TelegramIcon className="inline-block w-6 h-6 mr-1 text-grey-2" />
              Connect Telegram
            </TelegramButton>
            <Link
              to={"/" + currentUser?.link_tag}
              className="self-center text-sm rounded-full mb-8 text-center w-full text-primary underline font-bold"
            >
              Skip
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default OnboardingConnectTelegram;
