import { Link } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import { useEffect } from "react";

const OnboardingStepThree = ({ onClick }) => {
  useEffect(() => {
    amplitude.track(`onb_intro_3`);
  }, []);

  return (
    <div className="h-[calc(var(--vh)_*_100)] flex flex-col justify-between">
      <div className="bg-[url('assets/images/intro/image-3.webp')] bg-contain bg-no-repeat bg-center h-full mt-6"></div>
      <div className="mt-14 flex flex-col items-center justify-center text-center">
        <h3 className="font-bold text-sm text-grey-2 mb-2">How to Goodsend?</h3>
        <div className="font-bold text-xl text-center max-w-[345px] leading-7">
          Goodsend gathers all details and organize your goods
        </div>
        <p className="font-regular text-lg max-w-[290px] text-grey-1 mt-4">
          You send the link - we get all the details you may need. A good photo,
          price, brand etc.
        </p>
        <Link
          to="/intro/4"
          className="bg-primary self-center text-white rounded-full text-lg py-4 mb-12 mt-8 text-center w-full max-w-[350px]"
        >
          Continue
        </Link>
      </div>
    </div>
  );
};

export default OnboardingStepThree;
