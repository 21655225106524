import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ReactComponent as CameraIcon } from "assets/icons/camera.svg";
import PrimaryButton from "components/PrimaryButton";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { getCurrentUserInfo, updateUserInfo } from "services/api";
import * as amplitude from "@amplitude/analytics-browser";


const LastStepPhoto = () => {
  useEffect(() => {
    amplitude.track(`onb_photo`);
  }, []);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);

  const {
    data: user,
    isLoading: isLoadingUserInfo,
    isError: isErrorUserInfo,
    isIdle: isIdleUserInfo,
  } = useQuery({
    queryKey: ["currentUserInfo"],
    queryFn: () => getCurrentUserInfo(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (user) {
      setValue("name", user.name);
    }
  }, [user, setValue]);

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: ({ formData }) => updateUserInfo(formData),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["currentUserInfo"] });
      queryClient.invalidateQueries({ queryKey: ["userInfo", user.link_tag] });
      navigate("/last-step/final");
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    if (data.image) {
      if (data.image instanceof File) {
        formData.append("image", data.image);
      } else formData.append("image", imagePreview);
    }

    mutate({ formData });
  };

  const onFileChange = (file) => {
    if (file) {
      setImagePreview(URL.createObjectURL(file)); // Create and set the image preview URL
    }
    setValue("image", file);
  };

  useEffect(() => {
    // Clean up the image preview URL to avoid memory leaks
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);

  useEffect(() => {
    if (user) {
      if (user.photo_url)
        if (user.photo_url.includes("t.me")) {
          const image = new Image();
          image.src = user.photo_url;
          image.onload = () => {
            if (image.height < 10) setImagePreview("");
            else setImagePreview(user.photo_url);
          };
          image.onerror = () => setImagePreview("");
        } else
          setImagePreview(
            `https://goodsend.fra1.cdn.digitaloceanspaces.com/user-avatars/images/${user.photo_url}`
          );
    }
  }, [user, setValue]);

  // Drag and drop handlers
  const handleDragOver = useCallback((event) => {
    event.preventDefault(); // Prevent default behavior to enable drop
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files && files[0]) {
      onFileChange(files[0]); // Handle the first file
    }
  }, []);

  if (isErrorUserInfo) {
    return <div>Error loading user info</div>;
  }

  return (
    <div className="h-[calc(var(--vh)_*_100)] flex flex-col justify-between">
      <div className="flex flex-col items-center justify-center text-center h-full">
        <h3 className="font-bold text-sm text-grey-2 mb-2">How to Goodsend?</h3>
        <div className="font-bold text-xl text-center max-w-[350px] leading-7 mb-6">
          Add a photo to personalize your Goodsend
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full max-w-[500px] pb-32"
        >
          <div className="mb-8 mx-4">
            <div>
              <label
                htmlFor="image"
                className={`bg-grey-4 w-full height-100 rounded-3xl
            flex flex-col items-center ${
              imagePreview ? "justify-end" : "justify-center"
            } mb-4 aspect-square
            bg-cover bg-center bg-no-repeat`}
                style={{ backgroundImage: `url(${imagePreview})` }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                {imagePreview ? (
                  <div className="mb-8">
                    <label
                      htmlFor="image"
                      className="bg-primary p-2 rounded-full text-white mr-8 leading-none"
                    >
                      Change Image
                    </label>
                    <button
                      className="text-primary font-bold underline leading-none shadow "
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setImagePreview(null);
                      }}
                    >
                      Remove Image
                    </button>
                  </div>
                ) : (
                  <>
                    <CameraIcon className="mb-4" />
                    <label
                      htmlFor="image"
                      className="bg-primary p-2 rounded-full text-white leading-none"
                    >
                      Upload Image
                    </label>
                  </>
                )}
              </label>
              <input
                id="image"
                type="file"
                {...register("image")}
                onChange={(e) => onFileChange(e.target.files[0])}
                className="hidden"
              />
            </div>
          </div>
          <PrimaryButton
            className="w-[95vw] max-w-[500px] fixed bottom-20 left-1/2 -translate-x-1/2 disabled:opacity-50 "
            type="submit"
            disabled={isPending}
          >
            {isPending ? "Loading..." : isSuccess ? "Continue" : "Continue"}
          </PrimaryButton>
          <Link
            to="/last-step/final"
            className="fixed bottom-10 left-1/2 -translate-x-1/2 text-primary underline font-bold"
          >
            Skip
          </Link>
        </form>
      </div>
    </div>
  );
};

export default LastStepPhoto;
