import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const location = useLocation();
  const prevLocation = useRef(location);

  useEffect(() => {
    // alert(location.pathname.split('/')[2])
    // Check if the pathname has changed (ignoring query parameter changes)
    if (prevLocation.current.pathname !== location.pathname && !['owned', 'collections'].includes(location.pathname.split('/')[2]) && !['owned', 'collections'].includes(prevLocation.current.pathname.split('/')[2])) {
      window.scrollTo(0, 0);
    }

    // Update the previous location for the next render
    prevLocation.current = location;
  }, [location]); // Depend on `location` to re-run this effect

  return null;
}

export default ScrollToTop;
