import { ReactComponent as ShareIcon } from "assets/icons/share.svg";
import { ReactComponent as TelegramIcon } from "assets/icons/telegram_round.svg";
import MultishareImage from "assets/images/intro/multishare.gif";
import { Link } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import { useEffect } from "react";

const OnboardingStepTwo = () => {
  useEffect(() => {
    amplitude.track(`onb_intro_2`);
  }, []);

  return (
    <div className="h-[calc(var(--vh)_*_100)] flex flex-col justify-between">
      <div className="flex w-full h-full justify-center">
        <img
          src={MultishareImage}
          className="rounded-3xl max-h-[calc(50_*_var(--vh))] self-center"
          alt="how to goodsend"
        />
      </div>
      <div className="flex flex-col items-center justify-center text-center">
        <h3 className="font-bold text-sm text-grey-2 mb-2">How to Goodsend?</h3>
        <div className="font-bold text-xl text-center max-w-[270px]">
          Save goods from sites&nbsp;or apps with 1 tap
        </div>
        <p className="text-lg max-w-[330px] text-grey-1 mt-4 leading-6">
          Find inspiring objects and goods you like, tap{" "}
          <span>
            <ShareIcon className="inline w-5 h-5 mb-1 mr-1" />
            share
          </span>{" "}
          and pick <span className="text-primary ">Goodsend</span> or{" "}
          <span className="text-[#2b9eda] ">
            <TelegramIcon className="inline w-5 h-5 mb-1 mr-1" />
            Telegram Bot
          </span>
        </p>
        <Link
          to="/intro/3"
          className="bg-primary self-center text-white rounded-full text-lg py-4 mb-12 mt-8 text-center w-full max-w-[350px]"
        >
          Continue
        </Link>
      </div>
    </div>
  );
};

export default OnboardingStepTwo;
