import * as amplitude from "@amplitude/analytics-browser";
import { useQuery } from "@tanstack/react-query";
import UserInfo from "components/UserInfo";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getCurrentUserInfo } from "services/api";

const OnboardingStepFive = () => {
  useEffect(() => {
    amplitude.track(`onb_intro_5`);
  }, []);

  const {
    data: userInfo,
    isLoading: isLoadingUserInfo,
    isError: isErrorUserInfo,
    isIdle: isIdleUserInfo,
  } = useQuery({
    queryKey: ["currentUserInfo"],
    queryFn: () => getCurrentUserInfo(),
  });

  if (isErrorUserInfo) {
    return <div>Error loading user info</div>;
  }

  return (
    <div className="h-[calc(var(--vh)_*_100)] flex flex-col justify-between">
      <div className="flex flex-col justify-center self-center items-center h-full w-full max-h-[500px] max-w-[500px] px-5 pt-4">
        <div className="flex flex-col font-regular justify-center items-center h-full w-full ">
          <UserInfo
            className="mt-8 mb-10"
            user={userInfo}
            isMyPage={false}
            isOnboarding={true}
          />
        </div>
      </div>
      <div className="mt-14 flex flex-col items-center justify-center text-center">
        <h3 className="font-bold text-sm text-grey-2 mb-2">How to Goodsend?</h3>
        <div className="font-bold text-xl text-center max-w-[290px] leading-7">
          Inspire others or keep it personal
        </div>
        <p className="text-lg max-w-[270px] text-grey-1 mt-4 leading-6">
          Items you add are shared with the community. You can disable it in the
          settings
        </p>
        <Link
          // to={sessionStorage.getItem("redirect") || "/" + userInfo?.link_tag}
          to="/intro/one-tap-save"
          className="bg-primary self-center text-white rounded-full text-lg py-4 mb-12 mt-8 text-center w-full max-w-[350px]"
        >
          Continue
        </Link>
      </div>
    </div>
  );
};

export default OnboardingStepFive;
