import { useMutation, useQueryClient } from "@tanstack/react-query";
import { authApple } from "services/api";

export const useAppleAuthMutation = (appleResponse) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (appleResponse) => authApple(appleResponse),
    onSettled: () => {
      queryClient.invalidateQueries(["currentUserInfo"]);
    },
  });
};
