import * as amplitude from "@amplitude/analytics-browser";
import { useUser } from "UserContext";
import Paywall from "components/Paywall"; // Importing the Paywall component
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

const PaywallContext = createContext();

export const usePaywall = () => useContext(PaywallContext);

export const PaywallProvider = ({ children }) => {
  const location = useLocation();
  const { user: currentUser } = useUser();
  const [isPaywallOpen, setPaywallOpen] = useState(false);
  const [paywallType, setPaywallType] = useState("products");
  const [paywallContext, setPaywallContext] = useState(null);

  useEffect(() => {
    if (
      !currentUser ||
      location.pathname !== "/" + currentUser.link_tag ||
      currentUser.is_premium
    )
      return;

    const lastShown = localStorage.getItem("lastAutoPaywallShown");
    const now = new Date();
    if (!lastShown) {
      localStorage.setItem(
        "lastAutoPaywallShown",
        new Date(now - 86400000).toISOString()
      );
    }

    if (lastShown && now - new Date(lastShown) > 259200000) {
      // 259200000 ms = 3 days
      openPaywall("products", "session_start");
      localStorage.setItem("lastAutoPaywallShown", now.toISOString());
    }
  }, [currentUser, location.pathname]);

  const openPaywall = useCallback((type, context) => {
    setPaywallContext(context);
    setPaywallOpen(true);
    const offerStarted = localStorage.getItem("offerStarted");
    if (
      type === "products" &&
      offerStarted &&
      new Date() - new Date(offerStarted) < 86400000
    ) {
      setPaywallType("products_offer");
    } else setPaywallType(type);

    if (type === "products")
      amplitude.track("payment_screen_opened", { context });
    else amplitude.track("feature_info_screen_opened", { context });
  }, []);

  const closePaywall = useCallback(() => {
    setPaywallOpen(false);
    if (paywallType === "products") {
      const paywallClosed = parseInt(
        localStorage.getItem("productsPaywallClosed") || "0",
        10
      );
      if (paywallClosed + 1 === 2) {
        localStorage.setItem("offerStarted", new Date().toISOString());
      }
      localStorage.setItem("productsPaywallClosed", paywallClosed + 1);
    }
  }, [paywallType]);

  return (
    <PaywallContext.Provider
      value={{ isPaywallOpen, openPaywall, closePaywall }}
    >
      {children}
      <Paywall
        isOpen={isPaywallOpen}
        onClose={closePaywall}
        type={paywallType}
        setType={setPaywallType}
        context={paywallContext}
      />
    </PaywallContext.Provider>
  );
};
