import PrimaryButton from "components/PrimaryButton";
import { useState } from "react";
import BottomModal from "../bottom_modal/BottomModal";

const DeactivateModal = ({ isOpen, onClose, onDeactivate }) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const isButtonDisabled = inputValue.toLowerCase() !== "delete";

  const onDeactivateClick = () => {
    if (!isButtonDisabled) {
      onDeactivate();
    }
  };
  return (
    <BottomModal
      isOpen={isOpen}
      onClose={onClose}
      className={"w-full max-w-[550px]"}
      containerClassName={"flex items-center justify-end flex-col"}
      zIndex={20}
    >
      <div className="flex flex-col justify-between px-4 min-h-[400px] overflow-auto h-full max-h-[calc(80_*_var(--vh))]">
        <div className="flex flex-col">
          <h2 className="text-black text-xl font-bold mb-6">
            Account Deactivation
          </h2>
          <p className="text-grey-2 text-sm mb-7">
            Deactivating your account will remove all you data and goods from
            Goodsend. Are you sure?
          </p>
          <input
            className="bg-grey-4 rounded-full p-4 text-grey-2 text-lg focus:outline focus:outline-1 outline-black"
            type="text"
            placeholder="TYPE DELETE"
            value={inputValue}
            onChange={handleInputChange}
          ></input>
        </div>
        <div className="flex flex-col">
          <PrimaryButton onClick={onClose} className="mb-6">
            Cancel
          </PrimaryButton>
          <button
            disabled={isButtonDisabled}
            onClick={onDeactivateClick}
            className="text-primary text-sm font-bold underline underline-offset-2 mb-6 disabled:opacity-50"
          >
            Deactivate Account
          </button>
        </div>
      </div>
    </BottomModal>
  );
};

export default DeactivateModal;
