import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usePaywall } from "PaywallContext";
import { useToast } from "ToastContext";
import { useUser } from "UserContext";
import { ReactComponent as LeftArrow } from "assets/icons/left_arrow.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock-closed.svg";
import { ReactComponent as CheckIcon } from "assets/icons/rounded_check.svg";
import { ReactComponent as UsersIcon } from "assets/icons/users.svg";
import BottomModal from "components/modals/bottom_modal/BottomModal";
import { useEffect, useState } from "react";
import { updateUserPrivacy } from "services/api";

const privacyTypes = { public: 0, link: 1, private: 2 };

function PrivacySettingsModal({ isOpen, onClose }) {
  const { openPaywall } = usePaywall();
  const { addToast } = useToast();
  const { user } = useUser();
  const queryClient = useQueryClient();
  const [selectedPrivacy, setSelectedPrivacy] = useState(user?.privacy);
  // useEffect(() => {
  //   amplitude.track(`personalinfo_opened`);
  // }, []);

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: ({ formData }) => updateUserPrivacy(formData),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["currentUserInfo"] });
      queryClient.invalidateQueries({ queryKey: ["userInfo", user.link_tag] });
      addToast("Changes Saved");
    },
  });

  useEffect(() => {
    if (user) {
      setSelectedPrivacy(user.privacy);
    }
  }, [user]);

  const onChange = (e) => {
    if (!user?.is_premium) {
      openPaywall("private", "privacy_settings");
      return;
    }
    setSelectedPrivacy(e.target.value);
    const formData = new FormData();
    formData.append("privacy", e.target.value);
    mutate({ formData });
  };

  return (
    <BottomModal
      isOpen={isOpen}
      onClose={onClose}
      zIndex={21}
      containerClassName={"max-w-[550px]"}
      isFullHeight={true}
      className={"max-h-[100vh] h-full"}
      isNested={true}
    >
      <div className="p-3 pb-32 pt-4">
        <div className="flex justify-between align-center mb-8 p-2">
          <LeftArrow
            onClick={onClose}
            className="absolute text-black flex-grow-0"
          />
          <h3 className="text-lg font-bold flex-grow text-center">
            Privacy Settings
          </h3>
        </div>
        <form>
          <label
            htmlFor="privacy-public"
            className="flex justify-between items-center bg-grey-4 rounded-3xl p-4 mb-4"
          >
            <input
              type="radio"
              id="privacy-public"
              value={privacyTypes.public}
              onClick={onChange}
              className="hidden"
            />
            <div className="flex items-center">
              <UsersIcon className="flex-shrink-0 text-grey-2 w-6 h-6 mr-2" />
              <div className="mr-4">
                <h3 className="text-lg mb-1">Public Access</h3>
                <p className="text-grey-2 text-sm max-w-[260px]">
                  Your account, goods, and collections are visible to everyone.
                </p>
              </div>
            </div>
            {selectedPrivacy == privacyTypes.public ? (
              <CheckIcon className="flex-shrink-0 w-6 h-6 text-grey-2" />
            ) : (
              <div className="w-6 h-6 " />
            )}
          </label>

          <label
            htmlFor="privacy-link"
            className="flex justify-between items-center bg-grey-4 rounded-3xl p-4 mb-4"
          >
            <input
              type="radio"
              id="privacy-link"
              value={privacyTypes.link}
              onClick={onChange}
              className="hidden"
            />
            <div className="flex items-center">
              <LinkIcon className="flex-shrink-0 text-grey-2 w-6 h-6 mr-2" />
              <div className="mr-4">
                <h3 className="text-lg mb-1">Link Access</h3>
                <p className="text-grey-2 text-sm max-w-[260px]">
                  Access is granted only to those with whom you share a specific
                  link.
                </p>
              </div>
            </div>
            {selectedPrivacy == privacyTypes.link ? (
              <CheckIcon className="flex-shrink-0 w-6 h-6 text-grey-2" />
            ) : (
              <div className="w-6 h-6 " />
            )}
          </label>

          <label
            htmlFor="privacy-private"
            className="flex justify-between items-center bg-grey-4 rounded-3xl p-4"
          >
            <input
              type="radio"
              id="privacy-private"
              value={privacyTypes.private}
              onClick={onChange}
              className="hidden"
            />
            <div className="flex items-center">
              <LockIcon className="flex-shrink-0 text-grey-2 w-6 h-6 mr-2" />
              <div className="mr-4">
                <h3 className="text-lg mb-1">Private</h3>
                <p className="text-grey-2 text-sm max-w-[260px]">
                  Your account, goods, and collections are only visible to you.
                </p>
              </div>
            </div>
            {selectedPrivacy == privacyTypes.private ? (
              <CheckIcon className="flex-shrink-0 w-6 h-6 text-grey-2" />
            ) : (
              <div className="w-6 h-6 " />
            )}
          </label>
        </form>
      </div>
    </BottomModal>
  );
}

export default PrivacySettingsModal;
