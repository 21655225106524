import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ReactComponent as SpinnerIcon } from "assets/icons/spinner.svg";
import { AnimatePresence, motion } from "framer-motion";
import { createContext, useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { authTest, getCurrentUserInfo, signOut } from "services/api";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // const { addToast } = useToast();
  const location = useLocation();
  const queryClient = useQueryClient();

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isWebView = userAgent.includes("WebView");
  const [appVersion, setAppVersion] = useState(null);

  useEffect(() => {
    if (location.pathname.includes("/intro")) return;
    if (isWebView) {
      window.handleAppVersion = (version) => {
        setAppVersion(version);
      };
      if (window.webkit && window.webkit.messageHandlers.getAppVersion) {
        window.webkit.messageHandlers.getAppVersion.postMessage({
          callback: "window.handleAppVersion",
        });
      }
    }

    const authUser = async () => {
      try {
        if (
          window.location.hostname === "localhost" ||
          window.location.hostname.includes("192.168") ||
          window.location.hostname.includes("172.20")
        ) {
          // await authTest(3054);
          await authTest(10);
          // console.log("authTest");
        }
      } catch (err) {
        console.log("authTest err", err);
      }
    };
    authUser();

    return () => {
      delete window.handleAppVersion;
    };
  }, []);

  const { data: user, isLoading } = useQuery({
    queryFn: () => getCurrentUserInfo(),
    queryKey: ["currentUserInfo"],
    enabled: !location.pathname.includes("/intro"),
    retry: false,
  });

  const { mutate: handleSignOut } = useMutation({
    mutationFn: () => signOut(),
    mutationKey: ["signOut"],
    onSettled: () => {
      queryClient.clear();
    },
  });

  useEffect(() => {
    if (isWebView && user && window.webkit.messageHandlers.requestReview) {
      const date_added = new Date(user.date_added);
      const now = new Date();
      if (now - date_added > 1000 * 60 * 60 * 3) {
        // 3 hours
        window.webkit.messageHandlers.requestReview.postMessage({});
      }
    }
  }, [user]);

  return (
    <AnimatePresence mode="wait">
      {!isLoading &&
        !user &&
        !location.pathname.includes("/intro") &&
        location.pathname !== "/" && (
          <div className="flex justify-between items-center bg-primary text-white px-8 py-6">
            <div>
              <h1 className="text-xl">
                Join <b>Goodsend</b>
              </h1>
              <p className="text-lg">Save & Explore Your Favourite Goods</p>
            </div>
            <Link
              to="intro"
              className="px-8 py-4 bg-black rounded-full text-lg"
            >
              Join
            </Link>
          </div>
        )}
      {isLoading && !location.pathname.includes("/intro") ? (
        <motion.div
          key="spinner"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="flex justify-center items-center w-full h-[calc(var(--vh)_*_100)]"
        >
          <SpinnerIcon className="animate-spin text-primary w-14 h-14"></SpinnerIcon>
        </motion.div>
      ) : (
        <motion.div
          key="userContext"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <UserContext.Provider
            value={{
              user,
              handleSignOut,
              isWebView,
              isLoading,
              appVersion,
            }}
          >
            {children}
          </UserContext.Provider>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const useUser = () => useContext(UserContext);
