import * as amplitude from "@amplitude/analytics-browser";
import { useQuery } from "@tanstack/react-query";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as ShareIcon } from "assets/icons/share.svg";
import ShareImage from "assets/images/intro/share.gif";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getCurrentUserInfo } from "services/api";

const OnboardingOneTapSave = () => {
  useEffect(() => {
    amplitude.track(`onb_one_tap`);
  }, []);
  const {
    data: currentUser,
    isLoading: isLoadingUserInfo,
    isError: isErrorUserInfo,
    isIdle: isIdleUserInfo,
  } = useQuery({
    queryKey: ["currentUserInfo"],
    queryFn: () => getCurrentUserInfo(),
  });
  console.log("currentUser", currentUser);

  if (isErrorUserInfo) {
    return <div>Error loading user info</div>;
  }

  return (
    <div className="h-[calc(var(--vh)_*_100)] flex flex-col justify-between">
      <div className="flex w-full h-full justify-center">
        <img
          src={ShareImage}
          className="rounded-3xl max-h-[calc(50_*_var(--vh))] self-center"
          alt="how to goodsend"
        />
      </div>
      <div className="mt-14 flex flex-col items-center justify-center text-center">
        <h3 className="font-bold text-sm text-grey-2 mb-2">How to Goodsend?</h3>
        <div className="font-bold text-xl text-center max-w-[290px] leading-7">
          Enable <span className="text-primary">One Tap Save</span> via iOS
          share menu
        </div>
        <p className="text-lg max-w-[340px] text-grey-1 mt-4 leading-6">
          Open Safari > tap <ShareIcon className="inline -mt-1" /> share >
          “More” > tap Edit > tap <PlusIcon className="inline -mt-1" /> for{" "}
          <span className="text-primary font-black">Goodsend</span> > tap Done
        </p>
        {currentUser?.telegram_id ? (
          <Link
            to={
              sessionStorage.getItem("redirect") || "/" + currentUser?.link_tag
            }
            className="bg-primary self-center text-white rounded-full text-lg py-4 mb-12 mt-8 text-center w-full max-w-[350px]"
          >
            Continue
          </Link>
        ) : (
          <Link
            to="/intro/connect-telegram"
            className="bg-primary self-center text-white rounded-full text-lg py-4 mb-12 mt-8 text-center w-full max-w-[350px]"
          >
            Continue
          </Link>
        )}
      </div>
    </div>
  );
};

export default OnboardingOneTapSave;
