import * as amplitude from "@amplitude/analytics-browser";
import { useEffect } from "react";
import { twMerge } from "tailwind-merge";
import PrimaryButton from "./PrimaryButton";

const TelegramButton = ({
  className,
  children,
  handleAuth,
  context = null,
}) => {
  useEffect(() => {
    // Load the Telegram widget script
    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-widget.js";
    script.async = true;
    document.body.appendChild(script);
    // Event handler for when the script has loaded
    script.onload = () => {
      if (window.Telegram && window.Telegram.Login) {
        window.Telegram.Login.init({}, handleAuth);
      } else {
        console.error("Telegram Login is not available");
      }
    };

    // Event handler for script load failure
    script.onerror = () => {
      console.error("Failed to load the Telegram widget script");
    };

    return () => {
      // Cleanup the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  const handleLogin = () => {
    if (context) amplitude.track("add_telegram", { context });

    if (window.location.hostname.includes("staging")) {
      window.Telegram.Login.auth(
        { bot_id: 5640615025, request_access: true },
        handleAuth
      );
    } else {
      window.Telegram.Login.auth(
        { bot_id: 6093169619, request_access: true },
        handleAuth
      );
    }
  };

  return (
    <PrimaryButton
      onClick={handleLogin}
      className={twMerge(
        "flex items-center bg-telegram text-white px-4",
        className
      )}
    >
      {children}
    </PrimaryButton>
  );
};

export default TelegramButton;
