import { useEffect, useRef, useState } from "react";

const Test = () => {
  const [info, setInfo] = useState("");

  const inputRef = useRef(null);

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const scrollTimer = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollTimer.current) {
        clearTimeout(scrollTimer.current);
      }

      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        document.querySelector(
          "#search-input"
        ).style = `position: fixed; bottom: 0; transform: none;top: auto;`;

        setInfo("DOWN");
      } else if (scrollTop < lastScrollTop) {
        scrollTimer.current = setTimeout(() => {
          document.querySelector("#search-input").style.position = "absolute";
          document.querySelector("#search-input").style.bottom = "auto";
          document.querySelector("#search-input").style.transform = "none";

          const fixedElement = document.querySelector("#search-input");
          const fixedElementHeight = fixedElement.offsetHeight;
          const docHeight =
            document.documentElement.scrollHeight + fixedElementHeight; // Total height of the document (padding to be added manually)
          let fixedElementBottom =
            document.documentElement.scrollTop + window.visualViewport.height;

          // Limit the position to the end of the document, otherwise Safari lets the user scroll without end
          if (fixedElementBottom > docHeight) {
            fixedElementBottom = docHeight;
          }
          let newTop = fixedElementBottom - fixedElementHeight;
          let curTop = parseInt(fixedElement.style.top)
            ? parseInt(fixedElement.style.top)
            : 0;
          if (Math.abs(newTop - curTop) > 1) {
            document.querySelector("#search-input").style.top =
              fixedElementBottom - fixedElementHeight + "px";
          }
          setInfo(
            `UP fixedElementBottom: ${fixedElementBottom}, ${Math.abs(
              Math.abs(newTop - curTop)
            )}`
          );
        }, 100);
      }

      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop); // For Mobile or negative scrolling
    };

    const debouncedInputBlur = handleScroll;

    window.addEventListener("scroll", debouncedInputBlur);

    return () => {
      window.removeEventListener("scroll", debouncedInputBlur);
    };
  }, [lastScrollTop]);

  return (
    <div
      style={{
        height: "100%",
        overflow: "auto",
        WebkitOverflowScrolling: "touch",
        position: "relative",
      }}
    >
      <div className="fixed top-1/2 left-0 right-0 bg-white p-2 z-50">
        {info}
      </div>

      <div>
        {/* Scrollable content */}
        {[...Array(50)].map((_, index) => (
          <div
            key={index}
            style={{ padding: "20px", borderBottom: "1px solid #ccc" }}
          >
            Scrollable content {index + 1}
          </div>
        ))}
      </div>
      <div
        id="search-input"
        ref={inputRef}
        className="fixed bottom-0 left-0 right-0 z-50 w-full transition-all"
      >
        <input
          type="text"
          placeholder="Type here..."
          style={{
            width: "100%",
            padding: "10px",
            borderTop: "1px solid #ccc",
            boxSizing: "border-box",
            fontSize: "16px",
          }}
        />
      </div>
    </div>
  );
};

export default Test;
