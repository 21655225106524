import { AnimatePresence, motion } from "framer-motion";
import { createContext, useContext, useState } from "react";

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = (message) => {
    const id = Math.random().toString(36).substr(2, 9); // Generate a unique ID
    // console.log("adding toast", message, id);
    setToasts((currentToasts) => {
      const newToasts = [...currentToasts, { id, message }];
      // If the new length exceeds maxToasts, remove the oldest one
      if (newToasts.length > 3) {
        return newToasts.slice(1);
      }
      return newToasts;
    });

    setTimeout(() => {
      setToasts((currentToasts) =>
        currentToasts.filter((toast) => toast.id !== id)
      );
    }, 3000); // Remove toast after 5 seconds
  };

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <div className="z-50 fixed bottom-28 left-1/2 -translate-x-1/2 text-sm">
        <AnimatePresence>
          {toasts.map((toast) => (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              key={toast.id}
              className="text-center shadow-md text-white py-4 px-6 rounded-2xl mb-2 bg-black"
            >
              {toast.message}
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </ToastContext.Provider>
  );
};
