import axios from "axios";

const getBaseUrl = () => {
  const { protocol, hostname } = window.location;

  // Check if it's running on localhost (development environment)
  if (
    hostname === "localhost" ||
    hostname === "127.0.0.1" ||
    hostname.includes("192.168") ||
    hostname.includes("172.20")
  ) {
    return `${protocol}//${hostname}:8000/api`;
  } else {
    // For production, return the current protocol and host
    return `${protocol}//${hostname}/api`;
  }
};

export const API_URL = getBaseUrl();

// api client with cors enabled
const apiClient = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      originalRequest.url !== "/auth/refresh"
    ) {
      originalRequest._retry = true;

      try {
        // console.log("refreshing tokens");
        await apiClient.post("/auth/refresh");
        // console.log("tokens refreshed");

        return apiClient(originalRequest);
      } catch (refreshError) {
        // console.log("refresh error", refreshError);
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export const getUserGoods = async (
  userId,
  category = null,
  offset = null,
  include_collections = true,
  exclude_collection_id = null,
  not_in_collections = false
) => {
  if (category === 0) {
    category = null;
  } else {
    category = category - 1;
  }
  const response = await apiClient.get(`/users/${userId}/goods`, {
    params: {
      category,
      offset,
      include_collections,
      exclude_collection_id,
      not_in_collections,
    },
  });
  return response.data;
};

export const getUserCollectionsOffset = async (userId, offset = null) => {
  const response = await apiClient.get(`/users/${userId}/collections`, {
    params: {
      offset,
    },
  });
  return response.data;
};

export const getUserCollages = async (userId, offset = null) => {
  const response = await apiClient.get(`/users/${userId}/collages`, {
    params: {
      offset,
    },
  });
  return response.data;
}


export const getOwnedGoods = async (userId, category, offset = null) => {
  if (category === 0) {
    category = null;
  } else {
    category = category - 1;
  }
  const response = await apiClient.get(`/users/${userId}/owned`, {
    params: {
      offset,
      category,
    },
  });
  return response.data;
};

export const getArchivedGoods = async (offset = null) => {
  const response = await apiClient.get(`/archived`, {
    params: {
      offset,
    },
  });
  return response.data;
};

export const getUserExplore = async (
  userId,
  category = null,
  offset = null,
  type = null,
  removeMode = false
) => {
  if (category === 0) {
    category = null;
  } else {
    category = category - 1;
  }

  if (!userId) {
    userId = -1;
  }
  const response = await apiClient.get(
    `/users/${userId}/explore${type === "circle" ? "/circle" : ""}`,
    {
      params: {
        category,
        offset,
        selected: type === "picks" ? true : null,
        removeMode,
      },
    }
  );
  return response.data;
};

export const getBadGoods = async (userId, offset = null) => {
  const response = await apiClient.get(`/bad-goods`, {
    params: {
      offset,
    },
  });
  return response.data;
};

export const getCurrentUserInfo = async () => {
  const response = await apiClient.get(`/user`);
  // console.log("axios user info", response.data);
  return response.data;
};

export const getUserInfo = async (linkTag) => {
  const response = await apiClient.get(`/users/${linkTag}`);
  return response.data;
};

export const getUserNotifications = async () => {
  const response = await apiClient.get(`/notifications`);
  return response.data;
};

export const getUserFollowees = async (userId) => {
  const response = await apiClient.get(`/users/${userId}/followees`);
  return response.data;
};

export const followUser = async (userId) => {
  const response = await apiClient.post(`/users/${userId}/follow`);
  return response.data;
};

export const unfollowUser = async (userId) => {
  const response = await apiClient.delete(`/users/${userId}/follow`);
  return response.data;
};

export const readUserNotifications = async () => {
  const response = await apiClient.post(`/notifications/read`);
  return response.data;
};

export const getGoodDetails = async (goodId, ownerUserId) => {
  // console.log("getGoodDetails", goodId, ownerUserId);
  const response = await apiClient.get(`/goods/${goodId}`, {
    params: {
      owner_user_id: ownerUserId,
    },
  });
  return response.data;
};

export const addNoteToGood = async (goodId, data) => {
  const response = await apiClient.post(`/goods/${goodId}/note`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const updateGoodDetails = async (goodId, data) => {
  const response = await apiClient.put(`/goods/${goodId}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const archiveGood = async (goodId) => {
  const response = await apiClient.delete(`/users/goods/${goodId}`);
  return response.data;
};

export const restoreGood = async (goodId) => {
  const response = await apiClient.post(`/users/goods/${goodId}/restore`);
  return response.data;
};

export const collectGood = async (goodId) => {
  const response = await apiClient.post(`/users/goods/${goodId}/collect`);
  return response.data;
};

export const authTest = async (user_id) => {
  const response = await apiClient.post(`/auth/test/${user_id}`);
  return response.data;
};

export const authTelegram = async (telegramResponse) => {
  // console.log("authTelegram", telegramResponse);
  const response = await apiClient.post(`/auth/telegram`, telegramResponse);
  return response.data;
};

export const connectTelegram = async (telegramResponse) => {
  // console.log("connectTelegram", telegramResponse);
  const response = await apiClient.post(
    `/auth/telegram/connect`,
    telegramResponse
  );
  return response.data;
};

export const authApple = async (appleResponse) => {
  // console.log("authApple", appleResponse);
  const response = await apiClient.post(`/auth/apple`, appleResponse);
  return response.data;
};

export const signOut = async () => {
  const response = await apiClient.post(`/auth/signout`);
  return response.data;
};

export const getCollectionGoods = async (collectionId, offset = null) => {
  // console.log("getCollectionGoods", collectionId);
  const response = await apiClient.get(`/collections/${collectionId}/goods`, {
    params: {
      offset,
    },
  });
  // console.log("getCollectionGoods response", response.data);
  return response.data;
};

export const getCollectionDetails = async (collectionId) => {
  const response = await apiClient.get(`/collections/${collectionId}`);
  return response.data;
};

export const getAppVersion = async () => {
  const response = await apiClient.get(`/version`);
  return response.data;
};

export const deactivateAccount = async () => {
  const response = await apiClient.delete(`/user`);
  return response.data;
};

export const getSettings = async () => {
  // console.log("getSettings");
  const response = await apiClient.get(`/user/settings`);
  // console.log("getSettings response", response.data);
  return response.data;
};

export const fetchSearchResults = async (searchTerm) => {
  const response = await apiClient.get(`/search/${searchTerm}`);
  return response.data;
};

export const fetchImageSearchResults = async (
  image,
  offset,
  imageEmbedding
) => {
  const formData = new FormData();

  if (imageEmbedding) {
    formData.append("image_embedding", imageEmbedding);
  } else {
    formData.append("image", image);
  }

  const response = await apiClient.post(`/search/image`, formData, {
    params: {
      offset,
    },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};

export const fetchGoodsSearchResults = async (
  searchTerm,
  offset,
  textEmbedding
) => {
  const formData = new FormData();
  formData.append("query_embedding", textEmbedding);
  const response = await apiClient.post(
    `/search/goods/${searchTerm}`,
    formData,
    {
      params: {
        offset,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const fetchSimilarGoods = async (goodId, offset) => {
  const response = await apiClient.get(`/goods/${goodId}/similar`, {
    params: {
      offset,
    },
  });
  return response.data;
};

export const getTrendingPeople = async () => {
  const response = await apiClient.get(`/search/trending/users`);
  return response.data;
};

export const updateUserInfo = async (data) => {
  const response = await apiClient.put("/user/settings/info", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};
export const updateUserPrivacy = async (data) => {
  const response = await apiClient.post("/user/settings/privacy", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const addGood = async (formData) => {
  const response = await apiClient.put("/goods", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const createCollection = async (data) => {
  const response = await apiClient.put("/collections", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const addGoodsToCollection = async (collectionId, goodIds) => {
  const response = await apiClient.post(`/collections/${collectionId}/goods`, {
    good_ids: goodIds,
  });
  return response.data;
};

export const updateCollectionDetails = async (collectionId, data) => {
  const response = await apiClient.post(`/collections/${collectionId}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const deleteCollection = async (collectionId) => {
  const response = await apiClient.delete(`/collections/${collectionId}`);
  return response.data;
};

export const removeGoodFromCollection = async (collectionId, goodId) => {
  const response = await apiClient.delete(
    `/collections/${collectionId}/goods/${goodId}`
  );
  return response.data;
};

export const getReservedGoods = async (collectionId) => {
  const response = await apiClient.get(
    `/collections/${collectionId}/goods/reserved`
  );
  return response.data;
};

export const reserveGood = async (goodId, collectionId) => {
  const response = await apiClient.post(
    `/collections/${collectionId}/goods/${goodId}/reserve`
  );
  return response.data;
};

export const unreserveGood = async (goodId, collectionId) => {
  const response = await apiClient.delete(
    `/collections/${collectionId}/goods/${goodId}/reserve`
  );
  return response.data;
};

export const getUserCollections = async () => {
  const response = await apiClient.get(`/collections`);
  return response.data;
};

export const getCollectionPreview = async (collectionId) => {
  const response = await apiClient.get(`/collections/${collectionId}/preview`);
  return response.data;
};

export const markAsOwned = async (goodId) => {
  const response = await apiClient.post(`/goods/${goodId}/owned`);
  return response.data;
};

export const markAsUnowned = async (goodId) => {
  const response = await apiClient.delete(`/goods/${goodId}/owned`);
  return response.data;
};

export const getHiddenGoods = async () => {
  const response = await apiClient.get(`/goods/hidden`);
  return response.data;
};

export const unlockHiddenGoods = async () => {
  const response = await apiClient.post(`/goods/hidden/unlock`);
  return response.data;
};

export const removeFromExplore = async (goodId) => {
  const response = await apiClient.delete(`/goods/${goodId}/explore`);
  return response.data;
};

export const getPricing = async () => {
  const response = await apiClient.get(`/pricing`);
  return response.data;
};
export const removeImageBackground = async (image) => {
  const formData = new FormData();
  formData.append("file", image);
  const response = await apiClient.post(
    `/collages/remove-background`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const createOrUpdateCollage = async (formData) => {
  console.log(formData.get("title"));
  const response = await apiClient.post("/collages", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const getCollageDetails = async (collageId) => {
  const response = await apiClient.get(`/collages/${collageId}`);
  return response.data;
};

export const getCollageGoods = async (collageId, offset = null) => {
  const response = await apiClient.get(`/collages/${collageId}/goods`, {
    params: {
      offset,
    },
  });
  return response.data;
};

export const getUserCollectionNames = async () => {
  const response = await apiClient.get(`/collection-names`);
  return response.data;
};

export const deleteCollage = async (collageId) => {
  const response = await apiClient.delete(`/collages/${collageId}`);
  return response.data;
};
// export const getAllItems = async () => {
//   const response = await axios.get(`${API_URL}/items`);
//   return response.data;
// };
