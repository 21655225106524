import { useMutation, useQueryClient } from "@tanstack/react-query";
import PrimaryButton from "components/PrimaryButton";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { createOrUpdateCollage } from "services/api";
import { useToast } from "ToastContext";
import BottomModal from "../bottom_modal/BottomModal";

export const CollagePostModal = ({
  isOpen,
  onClose,
  onMainPopupClose,
  collageJSON,
  collagePreview,
  bgColor,
  collageDetails = null,
}) => {
  const isNew = useRef(false);
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const { addToast } = useToast();

  useEffect(() => {
    isNew.current = false;
    reset();
    console.log(collageJSON);
  }, [isOpen]);

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: createOrUpdateCollage,
    onSuccess: () => {
      addToast(collageDetails ? "Collage updated" : "Collage created");
      onClose();
      onMainPopupClose();
    },
    onSettled: () => {
      queryClient.invalidateQueries(["userCollections"]);
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    if (collageDetails && !isNew.current)
      formData.append("collage_id", collageDetails["id"]);
    formData.append("title", data.title);
    formData.append("collage", JSON.stringify(collageJSON));
    formData.append("collage_preview", collagePreview);
    formData.append("bg_color", bgColor);
    mutate(formData);
  };

  useEffect(() => {
    if (collageDetails) {
      setValue("title", collageDetails.title);
    }
  }, [collageDetails]);

  return (
    <BottomModal
      isOpen={isOpen}
      onClose={onClose}
      zIndex={21}
      containerClassName={"max-w-[550px] bg-white w-full"}
      noTopPadding={true}
      className={"max-h-[100vh]  h-full rounded-none md:rounded-t-3xl w-full"}
      isNested={true}
    >
      <div className="flex flex-col px-4 min-h-full pt-6">
        <h2 className="text-black text-xl font-bold mb-8">
          {collageDetails ? "Edit Collage" : "Create Collage"}
        </h2>
        <input
          id="title"
          type="title"
          className="bg-grey-4 text-lg p-4 rounded-full w-full focus:outline focus:outline-1 outline-black mb-6 "
          {...register("title", { required: true, minLength: 3 })}
          placeholder="Collage Name*"
        />
        {errors.title && (
          <p>Title is required and must be at least 3 characters long.</p>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          {collageDetails ? (
            <>
              <PrimaryButton
                className=" w-full bg-grey-4 text-black"
                onClick={() => (isNew.current = true)}
                type="submit"
                disabled={isPending && isNew.current}
              >
                {isPending && isNew.current ? <>Saving...</> : <>Save as New</>}
              </PrimaryButton>

              <PrimaryButton
                className="mt-4 mb-6 w-full bg-grey-4 text-black"
                type="submit"
                disabled={isPending && !isNew.current}
              >
                {isPending && !isNew.current ? (
                  <>Updating...</>
                ) : (
                  <>Update Current Collage</>
                )}
              </PrimaryButton>
            </>
          ) : (
            <>
              <PrimaryButton
                className="mt-4 w-full mb-6"
                type="submit"
                disabled={isPending}
              >
                {isPending ? "Creating..." : "Create Collage"}
              </PrimaryButton>
            </>
          )}
        </form>
      </div>
    </BottomModal>
  );
};
