import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

const ScrollToTopButton = ({ scrollRef, baseBottom = 100 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const targetRef = scrollRef?.current || window;

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = scrollRef?.current
        ? scrollRef.current.scrollTop
        : document.documentElement.scrollTop;

      if (scrolled > 800) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    targetRef.addEventListener("scroll", handleScroll);

    return () => {
      targetRef.removeEventListener("scroll", handleScroll);
    };
  }, [targetRef, scrollRef]);

  const scrollToTop = () => {
    if (scrollRef?.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }}
          className={`fixed left-1/2 -translate-x-1/2 z-[9] text-white py-1 p-3 bg-[#18181880] rounded-full`}
          style={{
            bottom: `calc(${baseBottom}px + env(safe-area-inset-bottom))`,
          }}
          onClick={scrollToTop}
        >
          ↑ Top
        </motion.button>
      )}
    </AnimatePresence>
  );
};
export default ScrollToTopButton;
